<div>
  <h5 class="offer-heading">Funds Transfer Method Details</h5>
</div>

<div class="d-flex flex-row mt-4">
  <div class="col gap-2 p-0">
    <div>
      <label for="Seller" class="form-label fw-bold">Type</label>
    </div>
    <div>
      <span class="text-muted listing-details-content">
        {{ transaction && transaction.type }}
      </span>
    </div>
  </div>

  <div class="col gap-2 p-0">
    <div>
      <label for="Project" class="form-label fw-bold">Account Holder</label>
    </div>
    <div>
      <span class="text-muted listing-details-content">
        {{
          transaction &&
            transaction.json_fields &&
            transaction.json_fields.Recipient
        }}
      </span>
    </div>
  </div>
  <ng-container *ngIf="transaction.type == 'Check'">
    <div class="col gap-2 p-0">
      <div>
        <label for="AuctionType" class="form-label fw-bold">Mail To </label>
      </div>
      <div *ngIf="transaction && transaction.json_fields">
        <span class="text-muted listing-details-content">
          {{ transaction.json_fields.mailto }},
          {{ transaction.json_fields.Street }},
          {{ transaction.json_fields.City }},
          {{ transaction.json_fields.State }}
          {{ transaction.json_fields.Zip }},
          {{ transaction.json_fields.country }}
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="transaction.type == 'Wire'">
    <div class="col gap-2 p-0">
      <div>
        <label for="AuctionType" class="form-label fw-bold"
          >Account Number</label
        >
      </div>
      <div>
        <span class="text-muted listing-details-content">
          {{
            transaction &&
              transaction.json_fields &&
              transaction.json_fields.Account_Number
          }}
        </span>
      </div>
    </div>
    <div class="col gap-2 p-0">
      <div>
        <label for="AuctionType" class="form-label fw-bold"
          >Recipient Address</label
        >
      </div>
      <div *ngIf="transaction && transaction.json_fields">
        <span class="text-muted listing-details-content">
          {{ transaction.json_fields.Recipient_Address }},
          {{ transaction.json_fields.City }},
          {{ transaction.json_fields.State }}, {{
            transaction.json_fields.country
          }}
        </span>
      </div>
    </div>
  </ng-container>
</div>
<div
  *ngIf="
    transaction.type == 'Wire' &&
    transaction?.json_fields?.Is_Further_Instruction === 'Yes'
  "
  class="mt-4"
>
  <app-further-credit-details
    [furtherCreditDetails]="transaction && transaction.json_fields && transaction.json_fields.further"
    [isFurtherCredit]="true"
  ></app-further-credit-details>
</div>
<div class="d-flex gap-3 mt-5">
  <button
    type="submit"
    class="btn btn-success mb-2 fw-bold"
    data-bs-toggle="modal"
    [attr.data-bs-target]="'#staticBackdrop' + index"
    (click)="handleAlertMessage(transaction)"
  >
    Verify Method
  </button>
  <button
    type="submit"
    class="btn btn-outline-dark cancel-button mb-2 fw-bold"
    data-toggle="collapse"
    [attr.data-target]="'#collapse' + index"
    aria-expanded="false"
    [attr.aria-controls]="'collapse' + index"
  >
    Close Panel
  </button>
</div>

<div
  class="modal fade text-wrap"
  id="staticBackdrop{{ index }}"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5
          class="modal-title text-dark"
          id="staticBackdropLabel"
          [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"
        ></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div
        class="modal-body text-dark"
        [innerHTML]="sanitizeHTML(offerDisclaimer.value2)"
      ></div>
      <div class="modal-footer">
        <div class="mr-auto">
          <button
            type="button"
            class="btn btn-warning text-white mr-2 fw-bold"
            data-bs-dismiss="modal"
            (click)="handleApproveFTM(currentFTM)"
          >
            YES
          </button>
          <button
            type="button"
            class="btn btn-danger ml-2 fw-bold"
            data-bs-dismiss="modal"
          >
            NO
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
