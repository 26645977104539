import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OfferConfirmMessages } from 'src/components/model/login';
import { CapitalAmmount } from 'src/components/model/my-listings';
import { CommonService } from 'src/components/services/common.service';
import { LoginService } from 'src/components/services/login.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-capital-amount',
  templateUrl: './capital-amount.component.html',
  styleUrls: ['./capital-amount.component.scss'],
})
export class CapitalAmountComponent implements OnInit {
  @Input() capitalAmount!: CapitalAmmount;
  isShow: boolean = false;
  offerConfirmMessages!: OfferConfirmMessages[];
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: '',
  };
  myOffersTotalAmount!: number;

  constructor(
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private myOffersService: MyOffersService,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      this.spinner.show();
      await Promise.all([
        this.handleMyOffersAmount(),
        this.handleOfferDealMessages(),
      ]);

      this.spinner.hide();
    } catch (error) {
      console.error(error);
      this.spinner.hide();
    }
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  handleAddCapitalAmount() {
    this.spinner.show();
    let body = {
      id: this.capitalAmount.id,
      availableCapital: !this.capitalAmount.dont_manage_capital
        ? this.capitalAmount.addFunds
        : 0,
      dont_manage_capital: this.capitalAmount.dont_manage_capital,
      addFund: this.capitalAmount.addFunds,
      signup_journey: false,
      capital_amount: true,
      recipient_id: this.loginService?.user?.id,
    };

    this.loginService.updateProfileDetails(body).subscribe(
      (response) => {
        this.capitalAmount.availableCapital = response?.availableCapital;
        this.capitalAmount.dont_manage_capital = response?.dont_manage_capital;
        this.isShow = false;
        this.spinner.hide();
        this.toastr.success(environment.FUNDS_ADD_SUCCESS_MESSAGE);
      },
      (error: any) => {
        this.isShow = false;
        this.spinner.hide();
        this.toastr.error(environment.ERROR_WARNING_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        });
        console.log('error', error);
      },
      () => console.log('Done add funds ammount')
    );
  }

  async handleOfferDealMessages() {
    this.offerConfirmMessages = await this.myOffersService
      .handleOfferDealMessages()
      .toPromise();
  }

  handleMessage() {
    if(this.myOffersTotalAmount > this.capitalAmount.addFunds && !this.capitalAmount.dont_manage_capital){
      this.toastr.info(`${environment.INSUFFICIANT_CAPITAL_AMOUNT_START}${this.myOffersTotalAmount.toLocaleString()}${environment.INSUFFICIANT_CAPITAL_AMOUNT_END}`);
    }
    this.isShow = true;
    let message = this.offerConfirmMessages?.filter(
      (item: any) => item.key == environment.ADD_FUNDS_DISCLAIMER
    );
    this.offerDisclaimer = message[0];
  }

  async handleMyOffersAmount() {
    const response: any = await this.myOffersService
      .handleMyOffersAmount(this.loginService?.user?.id)
      .toPromise();
    this.myOffersTotalAmount = response?.total_offers_amount;
  }
}
