<div class="container card mt-4 pt-4 h-100">
  <app-breadcrumb-container
    [containerClass]="'breadcrumb-container'"
    [breadcrumbItems]="[
      { label: 'Home', route: '/home' },
      { label: 'MarketPlace', route: '/market-place' },
      { label: 'Transactions', route: '' }
    ]"
  ></app-breadcrumb-container>

  <div class="d-flex flex-wrap gap-5 mt-4 mb-3 align-items-center">
    <div>
      <input
        type="search"
        placeholder="Search"
        id="searchParam"
        name="searchParam"
        class="form-control"
        [(ngModel)]="searchParam"
        (ngModelChange)="handleChange()"
      />
    </div>
    <div
      class="list-type-heading pe-auto"
      (click)="toggleTransactions('pending-transactions')"
    >
      <span
        [ngClass]="{
          'list-underline': transactionStatus == 'pending-transactions'
        }"
        >Pending PSA</span
      >
    </div>
    <div
      class="list-type-heading pe-auto"
      (click)="toggleTransactions('completed-transactions')"
    >
      <span
        [ngClass]="{
          'list-underline': transactionStatus == 'completed-transactions'
        }"
        >Funds Transfer Confirmed</span
      >
    </div>
    <div
      class="list-type-heading pe-auto"
      (click)="toggleTransactions('pending-asset')"
    >
      <span
        [ngClass]="{ 'list-underline': transactionStatus == 'pending-asset' }"
        >Asset Transfer Initiated</span
      >
    </div>
    <div class="list-type-heading pe-auto" (click)="toggleTransactions('All')">
      <span [ngClass]="{ 'list-underline': transactionStatus == 'All' }"
        >ALL</span
      >
    </div>
  </div>

  <div class="table-responsive text-nowrap">
    <table class="table" matSort (matSortChange)="onSortChange($event)">
      <thead>
        <tr>
          <ng-container *ngFor="let item of transactionsColumns; index as i">
            <th
              class="text-secondary"
              *ngIf="item !== 'Action(s)'"
              mat-sort-header="{{ item }}"
            >
              {{ item }}
            </th>
            <th class="text-secondary" *ngIf="item === 'Action(s)'">
              {{ item }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody class="my-listing-table-body text-secondary" id="accordion">
        <ng-container
          *ngFor="
            let transaction of transactionsData
              | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
            index as i
          "
        >
          <tr>
            <td id="heading{{ i }}">
              <span
                data-toggle="collapse"
                [attr.data-target]="'#collapse' + i"
                aria-expanded="false"
                [attr.aria-controls]="'collapse' + i"
                class="data-details collapsed pe-auto"
                title="Transactions details"
              >
                {{ transaction?.trnxId }}
              </span>
            </td>
            <td>
              {{ transaction?.listing?.account?.mpName | uppercase }}
            </td>
            <td>{{ transaction?.offer?.contact?.mpName | uppercase }}</td>
            <td>{{ transaction?.listing?.project?.projectId }}</td>
            <td>{{ transaction?.nma }}</td>
            <td>{{ transaction?.status?.statusLabel }}</td>
            <td>
              <ng-container
                *ngIf="transaction?.status?.status == 'PSA To Be Created'"
              >
                <span
                  class="transactions-actions pe-auto"
                  [attr.data-bs-target]="'#exampleModal'"
                  data-bs-toggle="modal"
                  (click)="handleAlertMessage(transaction, 'PSA To Be Created')"
                >
                  PSA Sent</span
                >
                |
                <span
                  class="transactions-actions pe-auto"
                  [attr.data-bs-target]="'#exampleModal'"
                  data-bs-toggle="modal"
                  (click)="handleAlertMessage(transaction, 'Pending PSA')"
                >
                  Confirm PSA Complete</span
                >
              </ng-container>

              <ng-container
                *ngIf="transaction?.status?.status == 'Pending PSA'"
              >
                <span
                  class="transactions-actions pe-auto"
                  [attr.data-bs-target]="'#exampleModal'"
                  data-bs-toggle="modal"
                  (click)="handleAlertMessage(transaction, 'Pending PSA')"
                >
                  Confirm PSA Complete</span
                >
              </ng-container>

              <ng-container
                *ngIf="transaction?.status?.status == 'Pending Asset Transfer'"
              >
                <span
                  class="transactions-actions pe-auto"
                  [attr.data-bs-target]="'#exampleModal'"
                  data-bs-toggle="modal"
                  (click)="
                    handleAlertMessage(transaction, 'Pending Asset Transfer')
                  "
                >
                  Complete Asset Transfer</span
                >
              </ng-container>

              <ng-container
                *ngIf="transaction?.status?.status == 'Fund Transfer Confirmed'"
              >
                <span
                  class="transactions-actions pe-auto"
                  [attr.data-bs-target]="'#exampleModal'"
                  data-bs-toggle="modal"
                  (click)="
                    handleAlertMessage(transaction, 'Fund Transfer Confirmed')
                  "
                >
                  Initiate Asset Transfer</span
                >
                |
                <span
                  class="transactions-actions pe-auto"
                  [attr.data-bs-target]="'#exampleModal'"
                  data-bs-toggle="modal"
                  (click)="
                    handleAlertMessage(transaction, 'Pending Asset Transfer')
                  "
                  >Complete Asset Transfer</span
                >
              </ng-container>
              <ng-container
                *ngIf="
                  transaction?.status?.status == 'PSA Executed' ||
                  transaction?.status?.status == 'Assets Transferred'
                "
              >
                N/A
              </ng-container>
            </td>
          </tr>
          <tr
            id="collapse{{ i }}"
            class="collapse fade"
            [attr.aria-labelledby]="'heading' + i"
            data-parent="#accordion"
          >
            <td colspan="7">
              <div
                class="card-body border shadow-lg p-4 mb-5 bg-white rounded text-dark"
              >
                <div class="mt-4">
                  <app-listing-details
                    [listDetails]="transaction?.listing"
                    [offer]="transaction?.listing"
                    [isEckardTeam]="true"
                  >
                  </app-listing-details>
                </div>
                <hr />
                <div class="mt-4">
                  <h5 class="offer-heading" style="color: navy">
                    Transfer Method Details
                  </h5>
                </div>

                <div
                  *ngIf="
                    transaction?.fund_transfer_method?.length == 0;
                    else eckardTransactionBlock
                  "
                >
                  <div style="padding-top: 10px; font-size: medium">N/A</div>
                </div>
                <ng-template #eckardTransactionBlock>
                  <div
                    *ngFor="
                      let item of transaction?.fund_transfer_method;
                      let i = index
                    "
                    class="d-flex gap-5"
                  >
                    <div class="pt-2 fw-bold d-flex gap-2" *ngIf="item">
                      <div>{{ item?.type }}</div>
                      <div *ngIf="item?.status?.status == 'Accepted'">
                        <img
                          src="assets/eckard-approve.svg"
                          class="mx-1 pb-1 eckard-ftm-approve"
                          alt="ftm-approve"
                        />
                      </div>
                    </div>
                    <div class="p-0 w-100">
                      <table class="table table-borderless">
                        <ng-container>
                          <thead *ngIf="i == 0">
                            <tr>
                              <th
                                class="text-secondary"
                                *ngFor="
                                  let key of objectKeys(item?.json_fields)
                                "
                              >
                                {{ key }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                class="text-secondary"
                                *ngFor="
                                  let key of objectKeys(item?.json_fields)
                                "
                              >
                              {{ item && item.json_fields && item.json_fields[key] ? item.json_fields[key] : '' }}
                            </td>
                            </tr>
                          </tbody>
                        </ng-container>
                      </table>
                    </div>
                  </div>
                </ng-template>
                <hr />
                <app-my-offers
                  [constraintOptions]="[]"
                  [newOffer]="transaction?.offer"
                  [offer]="transaction?.listing"
                  [listDetails]="transaction?.listing"
                  [statusOptions]="[]"
                  [index]="i"
                  [isEckardTeam]="true"
                >
                </app-my-offers>

                <div class="d-flex flex-row">
                  <div class="col gap-2 p-0">
                    <label for="Seller" class="form-label fw-bold"
                      >Buyer:
                    </label>
                    <span class="px-2 text-muted listing-details-content">
                      <ng-container
                        *ngIf="
                          transaction?.offer?.account;
                          else targetAccountBlockElseBlock
                        "
                      >
                        {{ transaction?.offer?.account?.mpName ?? " " }}
                        ({{ transaction?.offer?.account?.accountId ?? "" }})
                      </ng-container>
                      <ng-template #targetAccountBlockElseBlock>
                        N/A
                      </ng-template>
                    </span>
                  </div>
                </div>
                <div
                  class="d-flex flex-row mt-4"
                  *ngIf="transaction?.offer?.account"
                >
                  <div class="col gap-2 p-0">
                    <label for="Seller" class="form-label fw-bold"
                      >Summary:
                    </label>
                    <span class="px-2 text-muted listing-details-content">
                      {{ transaction?.listing?.project?.projectId }}
                      {{ transaction?.listing?.nma }} NMA form
                      {{ transaction?.listing?.account?.accountId }} to
                      {{ transaction?.offer?.account?.accountId }}
                    </span>
                  </div>
                </div>
                <div class="mt-4 d-flex gap-3">
                  <div class="pt-2" style="color: navy">
                    <ng-container
                      *ngIf="transaction?.status?.status == 'PSA To Be Created'"
                    >
                      <span
                        class="transactions-actions pe-auto"
                        [attr.data-bs-target]="'#exampleModal'"
                        data-bs-toggle="modal"
                        (click)="
                          handleAlertMessage(transaction, 'PSA To Be Created')
                        "
                      >
                        PSA Sent</span
                      >
                      |
                      <span
                        class="transactions-actions pe-auto"
                        [attr.data-bs-target]="'#exampleModal'"
                        data-bs-toggle="modal"
                        (click)="handleAlertMessage(transaction, 'Pending PSA')"
                      >
                        Confirm PSA Complete</span
                      >
                    </ng-container>

                    <ng-container
                      *ngIf="transaction?.status?.status == 'Pending PSA'"
                    >
                      <span
                        class="transactions-actions pe-auto"
                        [attr.data-bs-target]="'#exampleModal'"
                        data-bs-toggle="modal"
                        (click)="handleAlertMessage(transaction, 'Pending PSA')"
                      >
                        Confirm PSA Complete</span
                      >
                    </ng-container>

                    <ng-container
                      *ngIf="
                        transaction?.status?.status == 'Pending Asset Transfer'
                      "
                    >
                      <span
                        class="transactions-actions pe-auto"
                        [attr.data-bs-target]="'#exampleModal'"
                        data-bs-toggle="modal"
                        (click)="
                          handleAlertMessage(
                            transaction,
                            'Pending Asset Transfer'
                          )
                        "
                      >
                        Complete Asset Transfer</span
                      >
                    </ng-container>

                    <ng-container
                      *ngIf="
                        transaction?.status?.status == 'Fund Transfer Confirmed'
                      "
                    >
                      <span
                        class="transactions-actions pe-auto"
                        [attr.data-bs-target]="'#exampleModal'"
                        data-bs-toggle="modal"
                        (click)="
                          handleAlertMessage(
                            transaction,
                            'Fund Transfer Confirmed'
                          )
                        "
                      >
                        Initiate Asset Transfer</span
                      >
                      |
                      <span
                        class="transactions-actions pe-auto"
                        [attr.data-bs-target]="'#exampleModal'"
                        data-bs-toggle="modal"
                        (click)="
                          handleAlertMessage(
                            transaction,
                            'Pending Asset Transfer'
                          )
                        "
                        >Complete Asset Transfer</span
                      >
                    </ng-container>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-outline-dark cancel-button mb-2 fw-bold"
                    data-toggle="collapse"
                    [attr.data-target]="'#collapse' + i"
                    aria-expanded="false"
                    [attr.aria-controls]="'collapse' + i"
                  >
                    Close Panel
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    <pagination-controls
      previousLabel="Prev"
      nextLabel="Next"
      (pageChange)="onTableDataChange($event)"
      *ngIf="handleLength(transactionsData)"
    >
    </pagination-controls>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>

<ng-container>
  <div
    class="modal fade text-wrap"
    id="exampleModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"
          ></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="modal-body"
          [innerHTML]="sanitizeHTML(offerDisclaimer.value2)"
        ></div>
        <div class="modal-footer">
          <div class="mr-auto">
            <button
              type="button"
              class="btn btn-warning text-white mr-2 fw-bold"
              data-bs-dismiss="modal"
              (click)="
                handleUpdateEckardTransactions(
                  transactionCurrentList,
                  transactionCurrentStatus
                )
              "
            >
              YES
            </button>
            <button
              type="button"
              class="btn btn-danger ml-2 fw-bold"
              data-bs-dismiss="modal"
            >
              NO
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
