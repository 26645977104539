import {  HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {  MyOffersPagination } from '../model/my-offer';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MyOffersService {
  constructor(
    private apiService: ApiService
  ) {}

  getAllListings(
    id: number,
    limit = 1,
    offset = 0,
    selectedItems = [],
    auctionFiltersSelected = [],
    is_direct_sale = false
  ): Observable<MyOffersPagination[]> {
    let selected_Items = selectedItems.map(({ item_id }) => item_id);
    let auction_filters = auctionFiltersSelected.map(({ item_id }) => item_id);
    let params = new HttpParams()
      .set('limit', limit)
      .set('offset', offset)
      .set('is_direct_sale', is_direct_sale);
    if (selected_Items.length > 0) {
      params = params.set('project_filters', selected_Items.join(', '));
    }
    if (auction_filters.length > 0) {
      params = params.set('auction_filters', auction_filters.join(', '));
    }
    const res = this.apiService
      .request(
        `${environment.API_BASE_URL}/offer/active_listing/contact/${id}`,
        'GET',
        null,
        params
      )
      .pipe(tap((response: any) => response.body));

    return res;
  }

  getAllClosedListings(
    id: number,
    limit = 1,
    offset = 0,
    selectedItems = [],
    auctionFiltersSelected = []
  ): Observable<MyOffersPagination[]> {
    let selected_Items = selectedItems.map(({ item_id }) => item_id);
    let auction_filters = auctionFiltersSelected.map(({ item_id }) => item_id);
    let params = new HttpParams().set('limit', limit).set('offset', offset);
    if (selected_Items.length > 0) {
      params = params.set('project_filters', selected_Items.join(', '));
    }
    if (auction_filters.length > 0) {
      params = params.set('auction_filters', auction_filters.join(', '));
    }
    const res = this.apiService
      .request(
        `${environment.API_BASE_URL}/offer/closed_listing/contact/${id}`,
        'GET',
        null,
        params
      )
      .pipe(tap((result: any) => result.body));
    return res;
  }

  getAllCancelledListings(
    id: number,
    limit = 1,
    offset = 0,
    selectedItems = [],
    auctionFiltersSelected = []
  ): Observable<MyOffersPagination[]> {
    let selected_Items = selectedItems.map(({ item_id }) => item_id);
    let auction_filters = auctionFiltersSelected.map(({ item_id }) => item_id);
    let params = new HttpParams().set('limit', limit).set('offset', offset);
    if (selected_Items.length > 0) {
      params = params.set('project_filters', selected_Items.join(', '));
    }
    if (auction_filters.length > 0) {
      params = params.set('auction_filters', auction_filters.join(', '));
    }
    const res = this.apiService
      .request(
        `${environment.API_BASE_URL}/offer/cancelled_listing/contact/${id}`,
        'GET',
        null,
        params
      )
      .pipe(tap((result: any) => result.body));
    return res;
  }

  getAllMyOffers(
    id: number,
    limit = 1,
    offset = 0
  ): Observable<MyOffersPagination[]> {
    const params = new HttpParams().set('limit', limit).set('offset', offset);

    const res = this.apiService
      .request(
        `${environment.API_BASE_URL}/offer/myoffers/contact/${id}`,
        'GET',
        null,
        params
      )
      .pipe(tap((result: any) => result.body));

    return res;
  }

  getofferDetails(id: number) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/offer/${id}`,
      'GET'
    );
    return res;
  }

  handleCreateNewOffer(body: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/offer/`,
      'POST',
      body    );
    return res;
  }

  handleUpdateOffer(id: any, body: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/offer/${id}/`,
      'PATCH',
      body
    );
    return res;
  }

  handleCancelOffer(id: any, body: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/offer/${id}/`,
      'PATCH',
      body
    );
    return res;
  }

  handleCheckListStatus(id: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/offer/list/${id}/`,
      'GET'
    );
    return res;
  }

  handleOfferDealMessages(): Observable<any> {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/key_value/`,
      'GET'
    );
    return res;
  }

  handlePendingOfferTrancastions(id: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/offer/contact/${id}/pending_offer`,
      'GET'
    );
    return res;
  }

  handleMyOffersAmount(id: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/offer/myoffers/totalAmount/contact/${id}`,
      'GET'
    );
    return res;
  }
}
