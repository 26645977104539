import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/components/services/encryption.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  accessToken!: string;
  constructor(private http: HttpClient, private router: Router, private encryptionService: EncryptionService) {}
  public request<T>(
    url: string,
    method: string,
    body?: any,
    params?: HttpParams
  ): Observable<HttpResponse<T>> {
    let headers = new HttpHeaders();
    let isAccessToken = this.encryptionService.decryptData(localStorage.getItem('accessToken'));
    let isToken = isAccessToken && JSON.parse(isAccessToken);
    if (isToken) {
      headers = headers.set('Authorization', `Bearer ${isToken}`);
    }
    let options = { body, headers, params };

    const res = this.http.request<any>(method, url, options).pipe(
      catchError((error) => {
        if (error.status === 401) {
          // Perform refresh token logic here, e.g., call refreshTokenEndpoint()
          return this.refreshTokenEndpoint().pipe(
            switchMap(() => {
              // Retry the original request
              options.headers = headers.set(
                'Authorization',
                `Bearer ${this.accessToken}`
              );
              return this.http.request<T>(method, url, options);
            }),
            catchError(() => {
              localStorage.clear();
              this.router.navigate(['']);
              // You can redirect to a login page or perform any other action
              return of(/* handle failure */);
            })
          );
        } else {
          // Handle other error statuses
          return error;
        }
      })
    );
    return res;
  }

  private refreshTokenEndpoint(): Observable<any> {
    let refresedToken = this.encryptionService.decryptData(localStorage.getItem('refreshToken'))|| null;
    let body = {
      refresh: refresedToken && JSON.parse(refresedToken),
    };
    return this.http.post(`${environment.API_BASE_URL}/refresh/`, body).pipe(
      tap((response: any) => response.body),
      tap((data: any) => {
        localStorage.setItem('accessToken', this.encryptionService.encryptData(JSON.stringify(data.access)));
        localStorage.setItem('refreshToken', this.encryptionService.encryptData(JSON.stringify(data.refresh)));
        this.accessToken = data.access;
      })
    );
  }
}
