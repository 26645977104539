import { LoginService } from 'src/components/services/login.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  Account,
  CapitalAmmount,
  FundsMethods,
  FurtherCreditDetails,
  Profile,
  Status,
} from 'src/components/model/my-listings';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import {
  OfferConfirmMessages,
  ResetPassword,
} from 'src/components/model/login';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/components/services/common.service';
import { environment } from 'src/environments/environment';
import { AddNewListingService } from 'src/components/services/add-new-listing.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  isPassword: boolean = false;
  isShow: boolean = false;
  isConfirmPassword: boolean = false;
  selectedType!: string;
  addAccount = false;
  showCropper = false;
  showExisting = false;
  accountsOptions!: Account[];
  showData!: boolean[];
  showData1!: boolean[];
  offerConfirmMessages!: OfferConfirmMessages[];
  accountsTypes: string[] = ['Check', 'Wire'];
  selectAccount!: string;
  profile: Profile = {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    notification: false,
    mpStatus: '',
    mpName: '',
  };
  accountsMethods: any = [];
  methods!: any;
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: '',
  };
  deleteFTM: boolean = false;
  deleteFTMethod!: FundsMethods | null;
  activeFTM: boolean = false;

  countryOptions = [
    'BHS',
    'BRB',
    'BLZ',
    'CAN',
    'CHE',
    'CYM',
    'CUW',
    'DEU',
    'FRA',
    'HTI',
    'HKG',
    'ISL',
    'ISR',
    'JAM',
    'JPN',
    'KOR',
    'MAF',
    'MEX',
    'PAN',
    'PRI',
    'SGP',
    'TWN',
    'USA',
  ];
  resetPassword: ResetPassword = {
    id: null,
    regular_user: null,
    email: '',
    newPassword: '',
    confirmNewPassword: '',
    isVerifyPassword: true,
    currentPassword: '',
  };
  capitalAmount: CapitalAmmount = {
    id: null,
    availableCapital: 0,
    addFunds: 0,
    dont_manage_capital: null,
  };

  further: FurtherCreditDetails = {
    Account_Number: '',
    Recipient: '',
    Re_Enter_AccountNumber: '',
    Recipient_Address: '',
    City: '',
    State: '',
    country: 'USA',
  };
  furtherCreditDetails = {};
  isEckard: boolean = false;
  statusOptions!: Status[];

  constructor(
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private myListingsService: MyListingsService,
    private myOffersService: MyOffersService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private addNewListingService: AddNewListingService
  ) {
    this.showData = [];
    this.showData1 = [];
  }

  ngOnInit(): void {
    this.isEckard =
      this.loginService?.user?.role?.name === 'Eckard' ? true : false;
    this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      this.spinner.show();
      await Promise.all([
        this.handleOfferDealMessages(),
        this.profileDetails(),
        this.handleGetStatus(),
        !this.isEckard && this.handleGetUserAccounts(),
        !this.isEckard && this.getAccountMethods(),
      ]);
      this.spinner.hide();
    } catch (e) {
      this.spinner.hide();
      console.log('Error', e);
    }
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  clickShowExisting() {
    this.showExisting = !this.showExisting;
  }

  clickAddAccount() {
    this.addAccount = !this.addAccount;
    this.methods = {};
    this.selectAccount = '';
    this.selectedType = '';
  }

  handleCountryChange(item: any, flag: boolean = false) {
    if (flag) {
      this.methods.further.country = item;
      return;
    }
    this.methods.country = item;
  }

  groupBy(xs: any, key: any) {
    const response = xs.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});

    for (const key in response) {
      response[key].sort(this.customSortByStatus);
    }
    return response;
  };

  customSortByStatus(a: any, b: any): number {

    const aStatus = a?.method_state?.status;
    const bStatus = b?.method_state?.status;
    if (aStatus === 'Deactivate' && bStatus !== 'Deactivate') {
      return 1;
    }
    if (aStatus !== 'Deactivate' && bStatus === 'Deactivate') {
      return -1;
    }
    return 0;
  }

  async getAccountMethods(): Promise<void> {
    this.spinner.show();
    const transferMethods: any = [];
    await this.loginService
      .getAccountMethods(this.loginService.user!.id)
      .subscribe((response: any) => {
        response.map((res: any) => {
          let methodsInfo: any = {};
          methodsInfo['Eckard Account'] = res.account.accountName;
          methodsInfo['Account Holder'] = res.json_fields['Recipient'];
          if (res.type == 'Check') {
            methodsInfo['Mail To'] = this.loginService.handleCreateAddress(res);
          } else {
            methodsInfo['Account Number'] = res.json_fields['Account_Number'];
            methodsInfo['Further Credit'] = {
              Is_Further_Instruction: res.json_fields['Is_Further_Instruction'],
              ...res.json_fields['further'],
            };
          }

          transferMethods.push({
            ...res,
            json_fields: methodsInfo,
          });
        });
        this.spinner.hide();
        const groupByType = this.groupBy(transferMethods, 'type');
        this.accountsMethods = groupByType;
      });
  }

  handleSubmit() {
    this.updateProfileDetails(this.profile);
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }

  handleAccountSubmit() {
    this.spinner.show();

    const body = {
      type: this.selectedType,
      account: parseInt(this.selectAccount),
      json_fields: this.methods,
    };

    this.loginService.addTransactionMethod(body).subscribe((response) => {
      this.methods = {};
      this.selectAccount = '';
      this.selectedType = '';
      this.spinner.hide();
      this.getAccountMethods();
      this.toastr.success(environment.ACCOUNT_METHOD_ADD_SUCCESS_MESSAGE);
      this.addAccount = !this.addAccount;
    });
  }

  async handleGetUserAccounts(): Promise<void> {
    const response: any = await this.myListingsService
      .handleGetUserAccounts(this.loginService.user!.id)
      .toPromise();
    if (response) {
      this.accountsOptions = response;
      if (this.accountsOptions.length == 1) {
        this.selectAccount = this.accountsOptions[0].id.toString();
        // this.getAccountMethods();
      }
    }
  }

  handleChange(value: string) {
    this.methods = {};
    switch (value) {
      case 'account':
      case 'Wire':
        this.methods.account_id = this.selectAccount;
        this.methods.Recipient = '';
        this.methods.Account_Number = '';
        this.methods.Re_Enter_AccountNumber = '';
        this.methods.Recipient_Address = '';
        this.methods.City = '';
        this.methods.State = '';
        this.methods.country = 'USA';
        this.methods.Is_Further_Instruction = 'No';
        this.methods.further = {};

        // this.getAccountMethods()

        break;
      case 'Check':
        this.methods.type = this.selectedType;
        this.methods.Recipient = '';
        this.methods.mailto = '';
        this.methods.Street = '';
        this.methods.City = '';
        this.methods.State = '';
        this.methods.country = 'USA';
        this.methods.Zip = '';
        break;
      default:
        return;
    }
  }

  handleFieldsAvailable() {
    if (this.selectedType === 'Wire') {
      return (
        this.methods.Recipient &&
        this.methods.Recipient_Address &&
        this.methods.Account_Number &&
        this.methods.City &&
        this.methods.State &&
        this.methods.country &&
        this.methods.Re_Enter_AccountNumber == this.methods.Account_Number &&
        this.handleIsFurtherCredit()
      );
    } else if (this.selectedType === 'Check') {
      return (
        this.methods.Recipient &&
        this.methods.type &&
        this.methods.mailto &&
        this.methods.Street &&
        this.methods.City &&
        this.methods.State &&
        this.methods.country &&
        this.methods.Zip
      );
    }
    return false;
  }

  handleIsFurtherCredit() {
    if (this.methods.Is_Further_Instruction !== 'Yes') {
      return true;
    }

    return (
      this.methods.further?.Account_Number &&
      this.methods.further?.Recipient &&
      this.methods.further?.City &&
      this.methods.further?.State &&
      this.methods.further?.Recipient_Address &&
      this.methods.further.country &&
      this.methods.further.Re_Enter_AccountNumber ==
        this.methods.further.Account_Number
    );
  }

  async profileDetails(): Promise<void> {
    this.spinner.show();
    await this.loginService
      .profileDetails(this.loginService?.user?.id)
      .subscribe(
        (response: any) => {
          if (response) {
            this.profile.email = response?.email;
            this.profile.id = response?.id;
            this.profile.firstName = response?.firstName;
            this.profile.lastName = response?.lastName;
            this.profile.mpStatus = response?.mpStatus;
            this.profile.notification = response.notification;
            this.profile.mpName = response.mpName;
            this.resetPassword.id = response?.id;
            this.capitalAmount.id = response?.id;
            this.capitalAmount.dont_manage_capital =
              response?.dont_manage_capital;
            this.capitalAmount.availableCapital = parseInt(
              response?.availableCapital
            );
          }
          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          this.toastr.error(environment.ERROR_WARNING_MESSAGE, '', {
            progressBar: false,
            tapToDismiss: false,
            disableTimeOut: true,
          });
          console.log('error', error);
        }
      );
    // }
  }

  updateProfileDetails(body: any) {
    this.spinner.show();
    body.signup_journey = false;
    body.capital_amount = false;
    this.loginService.updateProfileDetails(body).subscribe(
      (response) => {
        this.spinner.hide();
        this.toastr.success(environment.PROFILE_UPDATE_MESSAGE);
        if (response) {
          this.profile.email = response?.email;
          this.profile.id = response?.id;
          this.profile.firstName = response?.firstName;
          this.profile.lastName = response?.lastName;
          this.profile.mpStatus = response?.mpStatus;
          this.profile.notification = response.notification;
          this.profile.mpName = response.mpName;
          this.resetPassword.id = response?.id;
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.toastr.error(environment.ERROR_WARNING_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        });
        console.log('error', error);
      },
      () => console.log('Done getting user')
    );
    // }
  }

  toggleData(index: number) {
    // Toggle the value of the showData[index] variable
    this.showData[index] = !this.showData[index];
  }

  toggleData1(index: number) {
    // Toggle the value of the showData[index] variable
    this.showData1[index] = !this.showData1[index];
  }

  async handleOfferDealMessages(): Promise<void> {
    const response: any = await this.myOffersService
      .handleOfferDealMessages()
      .toPromise();
    if (response) {
      this.offerConfirmMessages = response;
    }
  }

  handleAlertMessage(type: any, obj = null) {
    this.deleteFTM = false;
    this.activeFTM = false;
    if (type === 'Add') {
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.ADD_FTM_KEY
      );
      this.offerDisclaimer = message[0];
    }
    if (type === 'Delete') {
      this.deleteFTM = true;
      this.deleteFTMethod = obj;
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.DELETE_FTM_KEY
      );
      this.offerDisclaimer = message[0];
    }

    if (type === 'Activate') {
      this.activeFTM = true;
      this.deleteFTMethod = obj;
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.ACTIVATE_FTM_KEY
      );
      this.offerDisclaimer = message[0];
    }
  }

  handleDeleteFTM() {
    this.spinner.show();
    this.myListingsService.handleDeleteFTM(this.deleteFTMethod?.id).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.error) {
          let message = this.offerConfirmMessages?.filter(
            (item: any) => item.key == environment.DEACTIVATE_FTM_KEY
          );
          this.offerDisclaimer = message[0];
          this.toastr.warning(response.error);
          this.isShow = true;
        } else {
          this.getAccountMethods();
          this.isShow = false;
          this.toastr.success(environment.FUND_TRANSFER_METHODE_DELETE_MESSAGE);
        }
      },
      (error: any) => {
        this.spinner.hide();
        console.error('Error getting accounts: ', error);
      },
      () => console.log('Done getting accounts.')
    );
  }

  handleFTMAccepted(list: any) {
    if (list?.status?.status == 'Accepted') {
      return true;
    }
    return false;
  }

  handleChangePasswor() {
    this.resetPassword.newPassword = '';
    this.resetPassword.confirmNewPassword = '';
    this.resetPassword.isVerifyPassword = true;
    this.resetPassword.currentPassword = '';
  }

  handleIsEckardUser() {
    if (this.loginService?.user?.role?.name == 'Eckard') {
      return false;
    }
    return true;
  }

  handleUserHandles() {
    if (this.accountsOptions && this.accountsOptions.length > 0) {
      return true;
    }
    return false;
  }

  onOptionChanged(value: string) {
    this.methods.Is_Further_Instruction = value;
    this.methods.further = value == 'Yes' ? { ...this.further } : {};
  }

  handleInputChanged(fieldName: string, value: string) {
    this.methods[fieldName] = value;
  }

  handleFurtherInputChanged(fieldName: string, value: string) {
    this.methods.further[fieldName] = value;
  }

  isAccountNumberMismatch(): boolean {
    return (
      this.methods.Re_Enter_AccountNumber !== this.methods.Account_Number &&
      this.methods.Re_Enter_AccountNumber !== ''
    );
  }

  isFurtherAccountNumberMismatch(): boolean {
    return (
      this.methods.further.Re_Enter_AccountNumber !==
        this.methods.further.Account_Number &&
      this.methods.further.Re_Enter_AccountNumber !== ''
    );
  }

  handleFurtherCredit(obj: any) {
    this.furtherCreditDetails = { ...obj };
  }

  handleFTMState(method: any, type: string) {
    this.spinner.show();

    let status = this.statusOptions?.find((item: any) => item.status === type);
    method.method_state = status?.id;
    let body = {
      id: method.id,
      method_state: method.method_state,
    };
    this.loginService.updateTransactionMethod(body).subscribe(
      (response) => {
        this.spinner.hide();
        this.isShow = false;
        this.toastr.success(
          type === 'Activate'
            ? environment.ACTIVATE_FTM_KEY_SUCCESS_MESSAGE
            : environment.DEACTIVATE_FTM_KEY_SUCCESS_MESSAGE
        );
        this.getAccountMethods();
      },
      (error: any) => {
        this.spinner.hide();
        console.log('Error getting  Approve Asset Transfer', error);
      },
      () => console.log('Done getting  Approve Asset Transfer ')
    );
  }

  handleGetStatus() {
    this.addNewListingService.handleGetStatus().subscribe(
      (response: Status[]) => {
        this.statusOptions = response;
      },
      (error: any) => {
        console.log('Error getting status', error);
      },
      () => console.log('Done getting status ')
    );
  }
}
