export const environment = {
  production: false,
  LOGO: 'assets/logo.svg',
  API_BASE_URL: 'https://p2p.eckardenterprises.com/eckardapi',
  SECURITY_KEY: 'f@j8G#5o$9l&2k@3',
  RESET_ROUTE_PATH: 'reset-password',
  EMAILE_ALREADY_EXIST_WARNING: 'Account with this email already exist.',
  INVALID_EMAIL_MESSAGE: 'Invalid Request try with valid email.',
  VERIFICATION_DISCLAIMER: 'Verification Code Disclaimer',
  VERIFICATION_CODE_MESSAGE: 'Please Enter Verification Code.',
  VERIFICATION_CODE_EXPIRE_MESSAGE:
    'The verification code has expired. Please request a new code to continue.',
  INVALID_VERIFICATION_CODE_MESSAGE:
    'Invalid verification code. Please try again with a valid code.',
  ERROR_WARNING_MESSAGE: 'Something went wrong please Try again!',
  SIGNUP_DISCLAIMER: 'Sign Up Disclaimer',
  SIGNUP_SUCCESS_MESSAGE:
    'Congratulations! You have successfully signed up to our marketplace.',
  RESET_PASSWORD_DISCLAIMER: 'Reset Password Disclaimer',
  SET_PASSWORD_MESSAGE: 'Please set your password.',
  PASSWORD_UPDATE_MESSAGE: 'Password Update Sucessfully.',
  INVALID_PASSWORD_MESSAGE: 'Incorrect password. Please try again.',
  PASSWORD_WARNING_MESSAGE:
    'Confirm password does not match with new password.',
  AVAILABLE_CAPITAL_WARNING_MESSAGE:
    "Sorry, you don't have enough funds to make this offer. To proceed, please add more capital.",
  INVALID_LOGIN: 'Invalid login credentials',
  AUCTION_TYPE_BUY_OR_OFFER: 'Buy Now or Make an Offer',
  OFFER_AMOUNT_WANRNING_MESSAGE: 'Offer Amount reduced to "Buy Now" Price!',
  AUCTION_TYPE_FIX: 'Fix Price',
  AUCTION_TYPE_DIRECT: 'Direct Sale',
  AUCTION_TYPE_SILENT_MINIMUM_ASK: 'Silent- Minimum Ask',
  BUYING_DISCLAIMER: 'Buying Disclaimer',
  BUUYING_OFFER_DISCLAIMER: 'Buying Offer Disclaimer',
  OFFER_ALREADY_CLOSED:
    'This list has already been closed, submitting or buying an offer is not possible.',
  OFFER_ALREADY_ACCEPTED: 'Offer Already Accepted.',
  OFFER_SUBMI_MESSAGE: 'Offer Submit Successfully.',
  OFFER_CREATION_ERROR_MESSAGE: 'Offer Not Create.',
  OFFER_UPDATE_MESSAGE: 'Offer Update Successfully.',
  OFFER_NOT_FOUND_MESSAGE: 'Offer Not Found.',
  OFFER_CANCEL_DISCLAIMER: 'Cancel Offer Disclaimer',
  OFFER_CANCEL_LIST_ACCEPTED_MESSAGE:
    'Unable to cancel offer as the list is already closed.',
  OFFER_CANCEL_MESSAGE: 'Offer Cancelled Successfully.',
  LIST_NOT_FOUND: 'List not Found.',
  ACCOUNT_ASSOCIATE_MESSAGE: 'Account Associate Successfully.',
  CASHFLOW_DEFAULT_VALUE_WARNING: 'CashFlow Defaults Value does not exist.',
  CASHFLOW_ADD_DEFAULT_VALUE_SUCCESS_MESSAGE:
    'CashFlow default values add Successfully.',
  CASHFLOW_UPDATE_DEFAULT_VALUE_SUCCESS_MESSAGE:
    'CashFlow default values update successfully.',
  ACCOUNT_METHODE_APPROVE_SUCCESS_MESSAGE:
    'Account Method Approve Successfully.',
  DEACTIVATE_FTM_KEY_SUCCESS_MESSAGE:
    'FTM Method Deactivate Successfully.',
  ACTIVATE_FTM_KEY_SUCCESS_MESSAGE:
    'FTM Method Activate Successfully.',
  ACCOUNT_METHOD_ADD_SUCCESS_MESSAGE: 'Account Method Added Successfully.',
  TRANSACTION_UPDATE_STATUS_MESSAGE: 'Transaction status update successfully.',
  PROFILE_UPDATE_MESSAGE: 'Profile update successfully.',
  FUND_TRANSFER_METHODE_DELETE_MESSAGE:
    'Fund Transfer Method Delete Successfully.',
  SELECT_FTM_KEY: 'Select FTM',
  ADD_FTM_KEY: 'Add FTM',
  DELETE_FTM_KEY: 'Delete FTM',
  ADD_FUNDS_DISCLAIMER: 'Add Funds Disclaimer',
  DEACTIVATE_FTM_KEY: 'Deactivate FTM',
  ACTIVATE_FTM_KEY:'Activate FTM',
  FUNDS_ADD_SUCCESS_MESSAGE: 'Funds successfully saved.',
  DEFAULT_AUCTION_DURATION_KEY: 'Default Auction Duration',
  INPROGRESS_MESSAGE: 'Still work in progress.',
  DRAFT_DISCLAIMER: 'Draft Disclaimer',
  LISTING_DISCLAIMER: 'Listing Disclaimer',
  LISTING_CANCEL_DISCLAIMER: 'Listing Cancel Disclaimer',
  UNABLE_TO_CANCEL_LIST_WARNING:
    'Unable to cancel offer linked to this list. Please revise.',
  LIST_CREATE_SUCCESS_MESSAGE: 'New List create successfully.',
  LIST_CANCEL_SUCCESS_MESSAGE: 'List cancelled successfully.',
  LIST_UPDATE_SUCCESS_MESSAGE: 'List update successfully.',
  MAXIMUM_AUCTION_DURATION: 'Maximum Auction Duration',
  MINIMUM_AUCTION_DURATION: 'Minimum Auction Duration',
  LISTING_LONGER_DURATION_WARNING:
    'The duration of your listing is longer than the',
  LISTING_SHORTER_DURATION_WARNING:
    'The duration of your listing is shorter than the',
  LISTING_DURATION_ADJUST_HOURS_MESSAGE: 'hours. Please adjust accordingly.',
  PROJECT_ASSOCIATION_WITH_ACCOUNT_WARNING:
    'No projects associated with this account.',
  NMA_BELOW_MINIMUM_ERROR_MESSAGE: 'Listed NMA below Minimum NMA',
  NMA_GREATER_THAN_AVAILABLE_ERROR_MESSAGE:
    'Listed NMA greater than Available NMA',
  REMANING_NMA_BELOW_MINIMUM_ERROR_MESSAGE:
    'Remaining Position below Minimum NMA',
  SELLING_DISCLAIMER: 'Selling Disclaimer',
  OFFER_ACCEPTED_SUCCESS_MESSAGE: 'Offer Accepted.',
  TRANSACTION_PSA_FULLY_EXECUTED: 'PSA Fully Executed',
  FUND_TRANSFER_INITIATED_DISCLAIMER: 'Fund Transfer Initiated',
  TRANSACTION_FUND_TRANSFER_CONFIRMED: 'Fund Transfer Confirmed',
  CONFIRM_FUNDS_RECEIVED_DISCLAIMER: 'Confirm Funds Received',
  BUYER_NOT_ASSOCIATED_ACCOUNT_WARNING:
    'The buyer has not associated their account with the offer. Please wait for them to complete this step before proceeding with the transaction.',
  ASSOCIATE_ACCOUNT_WITH_OFFER_WARNING:
    'Please associate your account with the offer.',
  ASSOCIATE_FTM_WITH_LIST_WARNING:
    'Please associate a transfer method before confirming funds received.',
  SELLER_ALREADY_CONFIRM_FUNDS_RECEIVED_MESSAGE:
    'Seller Already Confirm Funds Received.',
  NOTIFICATION_DISCLAIMER: 'Notification Disclaimer',
  TRANSACTION_STATUS_PENDING_PSA: 'Pending PSA',
  TRANSACTION_STATUS_PENDING_ASSET_TRANSFER: 'Pending Asset Transfer',
  TRANSACTION_TYPE_PSA_TO_BE_CREATED: 'PSA To Be Created',
  PSA_CREATED_DISCLAIMER: 'PSA Created',
  ASSET_TRANSFER_PENDING_DISCLAIMER: 'Set Asset Transfer Pending',
  CONFIRM_ASSET_TRANSFER_DISCLAIMER: 'Confirm Asset Transfer',
  TRANSACTION_STATUS_PSA_EXECUTED: 'PSA Executed',
  TRANSACTION_STATUS_ASSETS_TRANSFER: 'Assets Transferred',
  TRAGET_ACCOUNT_DISCLAIMER: 'Target Account',
  CASHFLOW_TYPE_CONSERVATIVE: 'Conservative',
  CASHFLOW_TYPE_STANDARD: 'Standard',
  CASHFLOW_TYPE_ASSERTIVE: 'Assertive',
  CASHFLOW_TYPE_DEFAULTS: 'Defaults',
  APPROVE_FTM_DISCLAIMER: 'Approve FTM',
  INSUFFICIANT_CAPITAL_AMOUNT_START:
    'Insufficient capital to optimize your offers. Please increase your capital to a minimum of ',
  INSUFFICIANT_CAPITAL_AMOUNT_END: ' for maximizing your offers.',
};
