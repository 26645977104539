import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb-container.module';
import { EckardStaffPlaceModule } from '../pages/eckard-staff-place/eckard-staff-place.module';
import { MarketPlaceComponent } from './market-place.component';
import {  RouterModule } from '@angular/router';
import { ChildRoutes } from 'src/common/child-routes';



@NgModule({
    declarations: [
        MarketPlaceComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        NgxSpinnerModule,
        EckardStaffPlaceModule,
        BreadcrumbModule,
        RouterModule.forChild(ChildRoutes)

    ],
    providers: [],
    exports: [MarketPlaceComponent]
})
export class MarketPlaceModule { }
