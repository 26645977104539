import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Account, Constraint, Status } from 'src/components/model/my-listings';
import { LoginService } from 'src/components/services/login.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CurrencyPipe } from '@angular/common';
import { OfferConfirmMessages } from 'src/components/model/login';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/components/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-offers',
  templateUrl: './my-offers.component.html',
  styleUrls: ['./my-offers.component.scss'],
})
export class MyOffersComponent implements OnInit {
  @Input() constraintOptions!: Constraint[];
  @Input() newOffer!: any;
  @Input() offer: any;
  @Input() listDetails!: any;
  @Output() updateOffers = new EventEmitter();
  @Input() statusOptions!: Status[];
  @Input() index!: number;
  @Input() isTransaction = false;
  @Input() isEckardTeam = false;
  @Input() transactionStatus = '';
  isShow: boolean = false;
  accountsOptions!: Account[];
  selectedAccount!: Account | null;
  blockOffer = false;
  offerId!: number | null;
  activeItem!: Status | null;
  isacceptedOffer = false;
  offerConfirmMessages: OfferConfirmMessages[] = [];
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: '',
  };

  currentTargetList!: any;
  constraintsViolationMessages: Constraint[] = [];
  listConstraintsOfferWarning = false;
  availableCapital!: string;
  manageMyCapital!: boolean;

  constructor(
    private myListingsService: MyListingsService,
    private myOffersService: MyOffersService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private currencyPipe: CurrencyPipe,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
  this.loadData()
  }

  async loadData():Promise<void>{
    try{
      this.spinner.show()
      await Promise.all([this.handleProfileDetails(),this.handleOfferDealMessages(),this.handleGetUserAccounts(), this.isTransaction && this.handleConstrainsOptions()])
      this.spinner.hide()

    }catch(e){
      this.spinner.hide()
      console.log('Error',e)
    }
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  changeSelection() {
    this.fetchSelectedItems();
  }

  fetchSelectedItems() {
    this.newOffer.constraints = this.constraintOptions
      .filter((value) => value.isChecked)
      .map((value) => value);
  }

  handleChange(offer: any) {
    if (
      this.newOffer.offerAmount > this.availableCapital &&
      !this.manageMyCapital
    ) {
      this.toastr.info(environment.AVAILABLE_CAPITAL_WARNING_MESSAGE, '', {
        positionClass: 'toast-center-center',
      });
      return;
    }
    let buyNowPrice = parseFloat(this.listDetails.buyNowPrice);
    const myInput = document.getElementById('offerAmount') as HTMLInputElement;
    if (offer.auctionType.endsWith(environment.AUCTION_TYPE_BUY_OR_OFFER)) {
      if (this.newOffer.offerAmount > buyNowPrice) {
        this.toastr.info(environment.OFFER_AMOUNT_WANRNING_MESSAGE, '', {
          positionClass: 'toast-center-center',
        });
        const buyNowPriceFormatted = this.currencyPipe.transform(
          buyNowPrice,
          'USD',
          'symbol',
          '1.0-0'
        );
        if (buyNowPriceFormatted != null) {
          myInput.value = buyNowPriceFormatted;
        }
        this.newOffer.offerAmount = buyNowPrice;
        myInput.blur();
        return;
      }
    }
  }

  handleMessage(offer: any) {
    let buyNowPrice = parseFloat(this.listDetails.buyNowPrice);
    this.offerId = null;
    this.activeItem = null;
    this.isacceptedOffer = false;
    this.blockOffer = false;
    if (
      offer.auctionType == environment.AUCTION_TYPE_FIX ||
      offer.auctionType == environment.AUCTION_TYPE_DIRECT
    ) {
      this.activeItem =
        this.statusOptions?.find((item) => item.status == 'Accepted') || null;
      this.offerId = this.activeItem ? this.activeItem.id : null;
      this.isacceptedOffer = true;
    } else if (
      offer.auctionType.endsWith(environment.AUCTION_TYPE_BUY_OR_OFFER)
    ) {
      if (this.newOffer.offerAmount >= buyNowPrice) {
        this.activeItem =
          this.statusOptions?.find((item) => item.status === 'Accepted') ||
          null;
        this.isacceptedOffer = true;
      }
    }

    if (this.isacceptedOffer) {
      this.checkOfferBlocked(true);
      this.activeItem =
        this.statusOptions?.find((item) => item.status === 'Accepted') || null;
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.BUYING_DISCLAIMER
      );
      this.offerDisclaimer = message[0];
    } else {
      this.checkOfferBlocked(false);
      this.activeItem =
        this.statusOptions?.find((item) => item.status === 'Active') || null;
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.BUUYING_OFFER_DISCLAIMER
      );
      this.offerDisclaimer = message && message[0];
    }
    this.offerId = this.activeItem ? this.activeItem.id : null;
  }

  handleSubmitOffer(obj: any, offer: any) {
    this.spinner.show();

    this.myListingsService.getMyList(this.listDetails.id).subscribe(
      (response: any) => {
        let listActive =
          response.status.statusLabel === 'Cancelled' ||
          response.status.statusLabel === 'Accepted';
        if (listActive) {
          this.spinner.hide();
          this.toastr.info(environment.OFFER_ALREADY_CLOSED);
          this.updateOffers.emit();
        } else {
          this.myOffersService
            .handleCheckListStatus(this.listDetails.id)
            .subscribe(
              (response: any) => {
                if (response.length == 0) {
                  let request = {
                    offer: {
                      offerAmount: obj.offerAmount,
                      status: this.offerId,
                      constraints: obj.constraints?.map((item: any) => item.id),
                      comments: obj.comments,
                      contact: obj.id
                        ? obj.contact.id
                        : this.loginService.user!.id,
                    },
                    listing_id: this.listDetails.id,
                    acceptedOffer: this.isacceptedOffer,
                  };
                  obj.id
                    ? this.handleUpdateOffer(obj.id, request)
                    : this.handleCreateNewOffer(request);
                } else {
                  this.spinner.hide();
                  this.updateOffers.emit();
                  this.toastr.info(environment.OFFER_ALREADY_ACCEPTED);
                }
              },
              (error: any) => {
                console.log('Error getting list current status ', error);
              },
              () => console.log('Done getting list current status ')
            );
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  checkOfferBlocked(flag: any) {
    this.constraintsViolationMessages = [];
    this.blockOffer = false;
    this.listConstraintsOfferWarning = false;
    this.listDetails.constraints.map((list: any) => {
      if (list.constraintType == 'block') {
        const offerContraint = this.newOffer.constraints.find(
          (offerContraint: any) => offerContraint.id == list.id
        );
        if (offerContraint != undefined) {
          flag
            ? (this.blockOffer = true)
            : (this.listConstraintsOfferWarning = true);
          const violation = { ...list };
          this.constraintsViolationMessages.push(violation);
        }
      }
      if (list.constraintType == 'required') {
        const offerContraint = this.newOffer.constraints.find(
          (offerContraint: any) => offerContraint.id == list.id
        );
        if (offerContraint == undefined) {
          flag
            ? (this.blockOffer = true)
            : (this.listConstraintsOfferWarning = true);
          const violation = { ...list };
          this.constraintsViolationMessages.push(violation);
        }
      }
    });
  }

  handleCreateNewOffer(body: any) {
    this.myOffersService.handleCreateNewOffer(body).subscribe(
      (response) => {
        this.spinner.hide();

        if (response) {
          this.updateOffers.emit();
          this.toastr.success(environment.OFFER_SUBMI_MESSAGE);
        }
      },
      (error: any) => {
        this.spinner.hide();

        this.toastr.error(environment.OFFER_CREATION_ERROR_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        });

        console.error('Error getting make an Offer : ', error);
      },
      () => console.log('Done getting make an Offer .')
    );
  }

  handleUpdateOffer(id: any, body: any) {
    this.myOffersService.handleUpdateOffer(id, body).subscribe(
      (response) => {
        this.spinner.hide();

        if (response) {
          this.updateOffers.emit();
          this.toastr.success(environment.OFFER_UPDATE_MESSAGE);
        }
      },
      (error: any) => {
        this.spinner.hide();

        this.toastr.error(environment.OFFER_NOT_FOUND_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        });
        console.error('Error getting update Offer : ', error);
      },
      () => console.log('Done getting update Offer .')
    );
  }
  handleCancelMessage() {
    let message = this.offerConfirmMessages?.filter(
      (item: any) => item.key == environment.OFFER_CANCEL_DISCLAIMER
    );
    this.offerDisclaimer = message[0];
  }

  handleCancelOffer(obj: any) {
    const canceledItem = this.statusOptions?.find(
      (item) => item.status == 'Cancelled'
    );
    const cancelId = canceledItem ? canceledItem.id : null;
    this.spinner.show();
    this.myListingsService.getMyList(this.listDetails.id).subscribe(
      (response: any) => {
        let listActive =
          response.status.statusLabel === 'Cancelled' ||
          response.status.statusLabel === 'Accepted';
        if (listActive) {
          this.spinner.hide();
          this.toastr.info(environment.OFFER_CANCEL_LIST_ACCEPTED_MESSAGE);
          this.updateOffers.emit();
          return;
        } else {
          this.myOffersService
            .handleCheckListStatus(this.listDetails.id)
            .subscribe(
              (response: any) => {
                if (response.length == 0) {
                  const request = {
                    offer: {
                      offerAmount: obj.offerAmount,
                      status: cancelId,
                      constraints: obj.constraints?.map((item: any) => item.id),
                      comments: obj.comments,
                      contact: obj.id
                        ? obj.contact.id
                        : this.loginService.user!.id,
                    },

                    listing_id: this.listDetails.id,
                    acceptedOffer: false,
                  };
                  this.myOffersService
                    .handleCancelOffer(obj.id, request)
                    .subscribe(
                      (response) => {
                        this.spinner.hide();
                        if (response) {
                          this.updateOffers.emit();
                          this.toastr.success(environment.OFFER_CANCEL_MESSAGE);
                        }
                      },
                      (error: any) => {
                        this.spinner.hide();
                        this.toastr.error(
                          environment.OFFER_NOT_FOUND_MESSAGE,
                          '',
                          {
                            progressBar: false,
                            tapToDismiss: false,
                            disableTimeOut: true,
                          }
                        );
                        console.error('Error getting Offer cancel : ', error);
                      },
                      () => console.log('Done getting Offer cancel .')
                    );
                } else {
                  this.spinner.hide();
                  this.updateOffers.emit();
                  this.toastr.info(
                    environment.OFFER_CANCEL_LIST_ACCEPTED_MESSAGE
                  );
                }
              },
              (error: any) => {
                this.spinner.hide();

                this.toastr.error(environment.LIST_NOT_FOUND, '', {
                  progressBar: false,
                  tapToDismiss: false,
                  disableTimeOut: true,
                });

                console.error(
                  'Error getting check list current status : ',
                  error
                );
              },
              () => console.log('Done getting check list current status .')
            );
        }
      },
      (error) => {
        this.spinner.hide();

        console.log(error);
      }
    );
  }

  async handleOfferDealMessages(): Promise<void> {
    const response: any = await this.myOffersService.handleOfferDealMessages().toPromise()
      if(response) {
        this.offerConfirmMessages = response
      }
  }

  handleConstrainsOptions() {
    this.constraintOptions = this.constraintOptions?.map((obj: any) => {
      return {
        id: obj.id,
        constraint: obj.constraint,
        buyLabel: obj.buyLabel,
        info: obj.info,
        violation_message: obj.violation_message,
        isChecked: this.newOffer.constraints?.some(
          (item: any) => item.id === obj.id
        ),
      };
    });
  }


  async handleGetUserAccounts(): Promise<void> {
    const response: Account[] | undefined = await this.myListingsService
      .handleGetUserAccounts(this.loginService.user!.id)
      .toPromise()
        if (response) {
          this.accountsOptions = response
        }
  }

  auctionTypeComparator(a: any, b: any) {
    return a && b ? a.id === b.id : false;
  }

  handleAccountTargetMessage(obj: any) {
    this.isShow = true;
    this.currentTargetList = obj;
    let message = this.offerConfirmMessages?.filter(
      (item: any) => item.key == environment.TRAGET_ACCOUNT_DISCLAIMER
    );
    this.offerDisclaimer = message[0];
  }

  clearSelectedAccount() {
    this.isShow = false;
    this.selectedAccount = null;
    // close pop modal
  }

  handleAssociateAccount() {
    this.spinner.show();
    this.isShow = false;
    let id = this.currentTargetList?.offer[0]?.id;
    let body = {
      offer: {
        account: this.selectedAccount?.id,
      },
      notify: {
        buyer_mpName: this.currentTargetList?.offer[0].contact.mpName,
        buyer_contact_id:this.currentTargetList?.offer[0].contact.id,
        listing_project_id: this.currentTargetList.project.projectId,
        mp_notification: this.currentTargetList?.offer[0].contact.notification || false,
        email: this.currentTargetList?.offer[0].contact.email,
        listing_contact_id: this.currentTargetList.account.contact.id,
      },
      listing_id: this.currentTargetList.id,
      acceptedOffer: false,
    };

    this.myOffersService.handleUpdateOffer(id, body).subscribe(
      (response) => {
        this.spinner.hide();
        this.updateOffers.emit();
        if (response) {
          this.newOffer.account = this.selectedAccount;
          this.toastr.success(environment.ACCOUNT_ASSOCIATE_MESSAGE);
        }
      },
      (error: any) => {
        this.spinner.hide();

        this.toastr.error(environment.OFFER_NOT_FOUND_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        });
        console.error('Error getting update Offer : ', error);
      },
      () => console.log('Done getting update Offer .')
    );
  }

   async handleProfileDetails(): Promise<void> {
    const response:any = await this.loginService.profileDetails(this.loginService?.user?.id).toPromise()

      if (response)  {
          this.availableCapital = response?.availableCapital
          this.manageMyCapital = response?.dont_manage_capital
      }
      else {
        this.toastr.error(environment.ERROR_WARNING_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        });
      }
  }

  handleAvailableCapital() {
    if (this.newOffer.offerAmount < this.offer.minimumAsk) {
      return true;
    }
    if (
      parseInt(this.newOffer.offerAmount) > parseInt(this.availableCapital) &&
      !this.manageMyCapital
    ) {
      return true;
    }
    return false;
  }

  handleMinimumAsking() {
    if (parseInt(this.newOffer.offerAmount) < parseInt(this.offer.minimumAsk)) {
      return true;
    }
    return false;
  }
}
