import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TransactionsModule } from '../../transactions/transaction.module';
import { SellerTransactionsComponent } from './seller-transactions.component';
import { RouterModule } from '@angular/router';
import { ChildRoutes } from 'src/common/child-routes';



@NgModule({
    declarations: [
        SellerTransactionsComponent
    ],
    imports: [
        RouterModule.forChild(ChildRoutes),
        FormsModule,
        TransactionsModule
    ],
    providers: [],
    exports: [SellerTransactionsComponent]
})
export class SellerTransactionsModule { }
