<div class="container card mt-4 pt-4 h-100">
  
  <app-breadcrumb-container
    [containerClass]="'breadcrumb-container'"
    [breadcrumbItems]="[
      { label: 'Home', route: '/home' },
      { label: 'MarketPlace', route: '/market-place' },
      { label: isShowOffers ? 'Offer Review' : (isEckardStaff ? 'Pendings Listings': 'My Listings'), route: isEckardStaff ? '/eckard-pending-listings': '/my-listing' }
    ]"
    (handleResetClick)="handleResetNewList()"
  ></app-breadcrumb-container>

  <ng-container *ngIf="showAllListing == false ; else elseBlock">
    <div class="d-flex justify-content-between mt-4 mb-3" *ngIf="!isEckardStaff">
      <div>
        <select
          class="form-select fw-bold"
          [(ngModel)]="listStatus"
          (ngModelChange)="handleFilterList()"
        >
          <option
            *ngFor="let list of listingFilterOptions"
            [value]="list.value"
          >
            {{ list.label }}
          </option>
        </select>
      </div>

      <button class="btn btn-warning text-white" (click)="handleNewList()" >
        NEW LISTING
      </button>
    </div>

    <div class="table-responsive text-nowrap" [ngClass]="{'mt-5': isEckardStaff}">
      <table class="table" matSort (matSortChange)="onSortChange($event)">
        <thead>
          <tr>
            <th
              *ngFor="let item of listingsColumns; index as i"
              class="text-secondary"
              [ngClass]="{ 'text-end': i > 2 }"
              mat-sort-header="{{ item }}"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody class="my-listing-table-body">
          <ng-container *ngIf="myListings">
            <tr
              *ngFor="
                let list of myListings
                  | paginate
                    : {
                        itemsPerPage: tableSize,
                        currentPage: page,
                        totalItems: count
                      };
                let i = index
              "
            >
              <td>
                <span
                class="data-details pe-auto"
                (click)="handleEdit(list)"
                title="Edit List"
                  >{{ list.listingName }}</span
                >
              </td>
              <td>{{ list.auctionEnd | date : "MMM d, y" }}</td>
              <td>
                <span>{{ list["Account/Project"] }}</span>
              </td>
              <td class="text-end">
                <span class="mr-3">{{ list.listedNMA }}</span>
              </td>
              <td class="text-end">
                <span class="mr-3">{{
                  list.minimumAsk | currency : "CAD" : "symbol-narrow" : ".0-0"
                }}</span>
              </td>
              <td class="text-end">
                <span class="mr-3">{{
                  list.highestBid | currency : "CAD" : "symbol-narrow" : ".0-0"
                }}</span>
              </td>
              <td class="text-end">
                <span
                  class="data-details pe-auto mr-3"
                  (click)="handleOffers(list)"
                >
                  {{ list["# Bids"] }}</span
                >
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div
      class="d-flex justify-content-center"
      *ngIf="handleListingLength() > 0"
    >
      <pagination-controls
        previousLabel="Prev"
        nextLabel="Next"
        (pageChange)="onTableDataChange($event)"
      >
      </pagination-controls>
    </div>
  </ng-container>

  <ng-template #elseBlock>
    <app-add-new-listing (onGoBack)="handleToggel()"></app-add-new-listing>
  </ng-template>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
