<div class="p-0">
  <label [for]="fieldId" class="labels">{{ label }}</label>
  <input
    type="text"
    class="form-control"
    [id]="fieldId"
    [name]="fieldId"
    [(ngModel)]="fieldName"
    (ngModelChange)="onInputChanged($event)"
    #ngModelRef="ngModel"
    required="true"
    [ngClass]="{
      'border border-danger':
        (ngModelRef.invalid && (ngModelRef.dirty || ngModelRef.touched)) }"
  />
  <div
    *ngIf="ngModelRef.invalid && (ngModelRef.dirty || ngModelRef.touched)"
    class="text-danger rounded px-4 error-message"
  >
    {{ errorMessage }}
  </div>
</div>
