import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProfileComponent } from './profile.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CapitalAmountComponent } from './capital-amount/capital-amount.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BreadcrumbModule } from 'src/components/breadcrumb/breadcrumb-container.module';
import { RouterModule } from '@angular/router';
import { ResetPasswordModule } from 'src/components/auth/reset-password/reset-password.module';
import { ChildRoutes } from 'src/common/child-routes';
import { RadioButtonsModule } from '../radio-buttons/radio-buttons.module';
import { InputFieldsModule } from '../input-fiields/input-fields.module';
import { FurtherCreditDetailsModule } from '../further-credit-details/further-credit-details.module';


@NgModule({
  declarations: [ProfileComponent, CapitalAmountComponent],
  imports: [
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    ResetPasswordModule,
    CurrencyMaskModule,
    BreadcrumbModule,
    RadioButtonsModule,
    InputFieldsModule,
    FurtherCreditDetailsModule
  ],
  providers: [],
  exports: [ProfileComponent],
})
export class ProfileModule {}
