import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TransactionsModule } from '../../transactions/transaction.module';
import { BuyerTransactionsComponent } from './buyer-transactions.component';
import { RouterModule } from '@angular/router';
import { ChildRoutes } from 'src/common/child-routes';



@NgModule({
    declarations: [
        BuyerTransactionsComponent
    ],
    imports: [
        RouterModule.forChild(ChildRoutes),
        FormsModule,
        TransactionsModule
    ],
    providers: [],
    exports: [BuyerTransactionsComponent]
})
export class BuyerTransactionsModule { }
