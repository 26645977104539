import { Component, Input } from '@angular/core';
import { FurtherCreditDetails } from 'src/components/model/my-listings';

@Component({
  selector: 'app-further-credit-details',
  templateUrl: './further-credit-details.component.html',
  styleUrls: ['./further-credit-details.scss'],
})
export class FurtherCreditDetailsComponent {
  @Input() furtherCreditDetails!: FurtherCreditDetails | undefined;
  @Input() isFurtherCredit: boolean = false
  showData: boolean = false;

  toggleData() {
    this.showData = !this.showData;
  }
}
