import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OfferConfirmMessages, UserDetails } from 'src/components/model/login';
import {
  MyListings,
  EmailNotifications,
  EmailMessages,
} from 'src/components/model/my-listings';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { environment } from 'src/environments/environment';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/components/services/common.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { LoginService } from 'src/components/services/login.service';

@Component({
  selector: 'app-email-notification-message',
  templateUrl: './email-notification.component.html',
})
export class EmailNotificationComponent implements OnInit {
  @Input() listDetails!: MyListings;
  @Input() index!: number;
  @Input() status!: string;
  @Input() listingId!: number;
  @Input() offer!: any;
  @Input() userName!: string;
  @Input() emailMessages:EmailMessages[] = []
  @Output() messageSent: EventEmitter<any> = new EventEmitter<any>();
  @Input() notification: boolean = true

  offerConfirmMessages!: OfferConfirmMessages[];
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: '',
  };

  emailNotifications: EmailNotifications = {
    trxnId: '',
    subject: '',
    body: '',
    from: '',
    to: '',
    recipient_id: '',
    listing_contact_id: '',
    list_id: '',
    offer_contact_id: '',
    type_of_recipient: '',
    senderMpName: '',
    receiverMPName: '',
    litsingName: '',
    receiver_id: '',
    sender_id: '',
    offer_id:'',
  };
  isShow:boolean = false
  currentUser: UserDetails | null;

  constructor(
    private myOffersService: MyOffersService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private myListingsService: MyListingsService,
    private loginService: LoginService
  ) {
    this.currentUser = this.loginService.user;
  }

  ngOnInit() {
    this.loadData();
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  async loadData(): Promise<void> {
    try {
      this.spinner.show();
      Promise.all([this.handleOfferDealMessages()]);
      this.spinner.hide();
    } catch (e) {
      this.spinner.hide();
      console.log('Error', e);
    }
  }

  async handleOfferDealMessages(): Promise<void> {
    const response: any = await this.myOffersService
      .handleOfferDealMessages()
      .toPromise();
    if (response) {
      this.offerConfirmMessages = response;

      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.NOTIFICATION_DISCLAIMER
      );
      this.offerDisclaimer = message[0];
    }
  }

  handleSendEmailNotifications(obj: any) {
    this.spinner.show();
    this.emailNotifications.trxnId = 'Listing ' + this.listDetails.id;

    this.emailNotifications.from =
      this.status == 'Sell'
        ? obj.account.contact.email
        : this.offer?.contact?.email
        ? this.offer?.contact?.email
        : this.loginService.user!.email;

    this.emailNotifications.to =
      this.status == 'Sell'
        ? this.offer?.contact?.email
          ? this.offer?.contact?.email
          : this.loginService.user!.email
        : obj.account.contact.email;

    this.emailNotifications.recipient_id =
      this.status != 'Sell'
        ? obj?.account?.contact?.id
        : this.offer?.contact?.id
        ? this.offer?.contact?.id
        : this.loginService.user!.email;

    this.emailNotifications.type_of_recipient =
      this.status == 'Sell' ? 'buyer' : 'seller';

    this.emailNotifications.senderMpName =
      this.status == 'Sell'
        ? obj?.account?.contact?.mpName
        : this.offer?.contact?.mpName
        ? this.offer?.contact?.mpName
        : this.loginService.user!.mpName;

    this.emailNotifications.receiverMPName =
      this.status == 'Sell'
        ? this.offer?.contact?.mpName
          ? this.offer.contact.mpName
          : this.loginService.user!.mpName
        : obj.account.contact.mpName;

    this.emailNotifications.sender_id =
      this.status == 'Sell'
        ? obj?.account?.contact?.id
        : this.offer?.contact?.id
        ? this.offer?.contact?.id
        : this.loginService.user!.id;

    this.emailNotifications.receiver_id =
      this.status == 'Sell'
        ? this.offer?.contact?.id
          ? this.offer.contact.id
          : this.loginService.user!.id
        : obj.account.contact.id;

    this.emailNotifications.litsingName = obj.project.projectId;
    this.emailNotifications.listing_contact_id = obj.account.contact.id;
    this.emailNotifications.offer_contact_id = this.offer?.contact?.id
      ? this.offer?.contact?.id
      : this.loginService.user!.id;
    this.emailNotifications.list_id = obj.id;
    this.emailNotifications.offer_id = this.offer?.id;

    this.myListingsService
      .handleTransactionsNotifications(this.emailNotifications)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          this.toastr.success(
            `Notifications has been sent to ${this.emailNotifications.receiverMPName}.`
          );
          this.handleClearNotifications()
          this.messageSent.emit(obj.id);

        },
        (error: any) => {
          this.spinner.hide();
          this.isShow = false
          this.toastr.error(
            `Notifications not sent to ${this.emailNotifications.receiverMPName}.`,
            '',
            {
              progressBar: false,
              tapToDismiss: false,
              disableTimeOut: true,
            }
          );
          console.log('Error  create Notifications Transactions', error);
        },
        () => console.log('Done  create Notifications Transactions ')
      );
  }

  handleClearNotifications() {
    this.isShow = false
    this.emailNotifications.trxnId = '';
    this.emailNotifications.subject = '';
    this.emailNotifications.body = '';
    this.emailNotifications.from = '';
    this.emailNotifications.to = '';
    this.emailNotifications.recipient_id = '';
    this.emailNotifications.type_of_recipient = '';
    this.emailNotifications.listing_contact_id = '';
    this.emailNotifications.offer_contact_id = '';
    this.emailNotifications.senderMpName = '',
    this.emailNotifications.receiverMPName = '';
    this.emailNotifications.litsingName = '';
    this.emailNotifications.listing_contact_id = '';
    this.emailNotifications.offer_contact_id = '';
    this.emailNotifications.list_id = '';
    this.emailNotifications.sender_id = '';
    this.emailNotifications.receiver_id = '';
    this.emailNotifications.offer_id = '';
  }
}
