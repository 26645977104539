import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ResetPassword } from 'src/components/model/login';
import { LoginService } from 'src/components/services/login.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent {

  @Input() resetPassword!: ResetPassword
  @Input() isChangePassword = false

  isShow = false
  isPassword: boolean = false;
  isconfirmPassword: boolean = false;
  isCurrentPassword: boolean = false


  constructor(private loginService: LoginService, private spinner: NgxSpinnerService,
    private toastr: ToastrService, private router: Router) {
  }

  handleResetPassword() {
    this.spinner.show()
    if (this.resetPassword.newPassword === this.resetPassword.confirmNewPassword) {
      // Call password reset service with new password
      if (this.isChangePassword) {
        this.isShow = false
        let body = {
          id: this.resetPassword.id,
          password: this.resetPassword.newPassword,
          signup_journey: false,
          capital_amount:false,
        }
      
          this.loginService.updateProfileDetails(body).subscribe(
            (response) => {
              this.spinner.hide()
              this.toastr.success(environment.PASSWORD_UPDATE_MESSAGE)
              this.router.navigate(['/profile']);
  
            },
            (error: any) => {
              this.spinner.hide()
              this.toastr.error(environment.ERROR_WARNING_MESSAGE, '', {
                progressBar: false,
                tapToDismiss: false,
                disableTimeOut: true,
              });
              console.log("error", error)
            },
            () => console.log("Done getting user"))
        
      }
      else {

        let body = {
          id: this.resetPassword.id,
          mpStatus: 'active',
          email: this.resetPassword.email,
          password: this.resetPassword.newPassword,
          regular_user: this.resetPassword.regular_user,
          signup_journey: true,
          isVerified: true,
          capital_amount:false,
        }
        this.loginService.updateSignUpDetails(body).subscribe(
          (response) => {
            this.spinner.hide()
            this.toastr.success(environment.SIGNUP_SUCCESS_MESSAGE)
            this.router.navigate(['']);

          },
          (error: any) => {
            this.spinner.hide()
            this.toastr.error(environment.ERROR_WARNING_MESSAGE, '', {
              progressBar: false,
              tapToDismiss: false,
              disableTimeOut: true,
            });
            console.log("error", error)
          },
          () => console.log("Done getting user"))
      }
    } else {
      this.spinner.hide()
      this.toastr.error(environment.PASSWORD_WARNING_MESSAGE, '', {
        progressBar: false,
        tapToDismiss: false,
        disableTimeOut: true,
      });
      // Show error message that passwords don't match
    }
  }

  handleCheckPWD() {
    if (this.resetPassword.newPassword != '' && this.resetPassword.confirmNewPassword != '' && this.resetPassword.newPassword === this.resetPassword.confirmNewPassword) {
      return false
    }
    return true
  }

  handleConfirmPWD() {
    if(this.resetPassword.currentPassword != '') {
      return false
    }
    return true
  }

  handleVerifyIdentity() {
    this.spinner.show()
    let formData = {
      email:this.loginService?.user?.email,
      password: this.resetPassword?.currentPassword
    }
    this.loginService.VerifyPassword(formData).subscribe(
      (response) => {
        this.spinner.hide()
        if(response.data.valid){
          this.resetPassword.isVerifyPassword = false
        }
        else{
          this.toastr.error(environment.INVALID_PASSWORD_MESSAGE, '', {
            progressBar: false,
            tapToDismiss: false,
            disableTimeOut: true,
          });
        }
      },
      (error: any) => {
        this.spinner.hide()
        this.toastr.error(environment.ERROR_WARNING_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        });
        console.log('error', error);
      });
  }

}
