import { Transaction } from './../../../model/my-listings';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/components/services/login.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { AddNewListingService } from '../../../services/add-new-listing.service';
import { MatSort } from '@angular/material/sort';
import { OfferConfirmMessages } from 'src/components/model/login';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/components/services/common.service';
import { Status } from 'src/components/model/my-listings';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-eckard-transactions',
  templateUrl: './eckard-transactions.component.html',
})
export class EckardTransactionsComponent implements OnInit {
  transactionStatus = '';
  transactionsData!: Transaction[];
  copyTransactionsData!: Transaction[];
  statusOptions!: Status[];

  searchParam = '';
  transactionsColumns = [
    'Trxn ID',
    'Seller',
    'Buyer',
    'Project',
    'NMA',
    'Status',
    'Action(s)',
  ];
  page: number = 1;
  count: number = 0;
  tableSize: number = 50;
  tableSizes: number[] = [3, 6, 9, 12];
  offerConfirmMessages!: OfferConfirmMessages[];
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: '',
  };
  transactionCurrentStatus!: string;
  transactionCurrentList!: Transaction;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private spinner: NgxSpinnerService,
    private loginService: LoginService,
    private router: Router,
    private toastr: ToastrService,
    private myListingsService: MyListingsService,
    private addNewListingService: AddNewListingService,
    private myOffersService: MyOffersService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (
      this.loginService?.user?.status != 'active' ||
      this.loginService?.user?.role?.name != 'Eckard'
    ) {
      this.router.navigate(['/market-place']);
      return;
    }
    this.spinner.show();
    this.handleGetStatus();
    this.handleOfferDealMessages();
    let routePath = this.router.url.split('/')[1];
    switch (routePath) {
      case 'eckard-pending-transactions':
        this.transactionStatus = 'pending-transactions';
        this.handleGetEckardTransactions(
          environment.TRANSACTION_STATUS_PENDING_PSA
        );
        break;
      case 'eckard-completed-transactions':
        this.transactionStatus = 'completed-transactions';
        this.handleGetEckardTransactions(
          environment.TRANSACTION_FUND_TRANSFER_CONFIRMED
        );
        break;
      case 'eckard-pending-asset':
        this.transactionStatus = 'pending-asset';
        this.handleGetEckardTransactions(
          environment.TRANSACTION_STATUS_PENDING_ASSET_TRANSFER
        );
        break;

      default:
        return;
    }
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  toggleTransactions(type: any) {
    this.spinner.show();

    this.transactionStatus = type;
    switch (this.transactionStatus) {
      case 'pending-transactions':
        this.handleGetEckardTransactions(
          environment.TRANSACTION_STATUS_PENDING_PSA
        );
        break;
      case 'completed-transactions':
        this.handleGetEckardTransactions(
          environment.TRANSACTION_FUND_TRANSFER_CONFIRMED
        );

        break;
      case 'pending-asset':
        this.handleGetEckardTransactions(
          environment.TRANSACTION_STATUS_PENDING_ASSET_TRANSFER
        );

        break;
      case 'All':
        this.handleGetEckardTransactions('All');

        break;

      default:
        return;
    }
  }

  async handleGetEckardTransactions(status: string) {
   await this.myListingsService.handleGetEckardTransactions(status).subscribe(
      (response: any) => {
        this.spinner.hide();
        let eckardTransaction = [];
        eckardTransaction = response;
        eckardTransaction?.map((ress: any) => {
          ress.fund_transfer_method.map((res: any) => {
            let methodsInfo: any = {};
            methodsInfo['Account Holder'] = res.json_fields['Recipient'];
            if (res.type == 'Check') {
              methodsInfo['Mail To'] = this.loginService.handleCreateAddress(res);;
            } else {
              methodsInfo['Account Number'] = res.json_fields['Account_Number'];
              methodsInfo['Address'] = this.loginService.handleCreateAddress(res);;
            }

            res.json_fields = methodsInfo;
          });
        });

        this.transactionsData = eckardTransaction;
        this.copyTransactionsData = eckardTransaction;
        this.handleChange();
      },
      (error: any) => {
        this.spinner.hide();
        console.log('Error getting  status', error);
      },
      () => console.log('Done getting  status ')
    );
  }

  handleAlertMessage(obj: any, type: any) {
    this.transactionCurrentStatus = type;
    this.transactionCurrentList = obj;
    if (type == environment.TRANSACTION_TYPE_PSA_TO_BE_CREATED) {
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.PSA_CREATED_DISCLAIMER
      );
      this.offerDisclaimer = message[0];
    } else if (type == environment.TRANSACTION_STATUS_PENDING_PSA) {
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.TRANSACTION_PSA_FULLY_EXECUTED
      );
      this.offerDisclaimer = message[0];
    } else if (type == environment.TRANSACTION_FUND_TRANSFER_CONFIRMED) {
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.ASSET_TRANSFER_PENDING_DISCLAIMER
      );
      this.offerDisclaimer = message[0];
    } else if (type == environment.TRANSACTION_STATUS_PENDING_ASSET_TRANSFER) {
      let message = this.offerConfirmMessages?.filter(
        (item: any) => item.key == environment.CONFIRM_ASSET_TRANSFER_DISCLAIMER
      );
      this.offerDisclaimer = message[0];
    }
  }

  async handleUpdateEckardTransactions(transaction: any, type: any) {
    this.spinner.show();

    if (type == environment.TRANSACTION_TYPE_PSA_TO_BE_CREATED) {
      transaction.status = this.statusOptions?.find(
        (item: any) => item.status == environment.TRANSACTION_STATUS_PENDING_PSA
      );
    } else if (type == environment.TRANSACTION_STATUS_PENDING_PSA) {
      transaction.status = this.statusOptions?.find(
        (item: any) =>
          item.status == environment.TRANSACTION_STATUS_PSA_EXECUTED
      );
    } else if (type == environment.TRANSACTION_FUND_TRANSFER_CONFIRMED) {
      transaction.status = this.statusOptions?.find(
        (item: any) =>
          item.status == environment.TRANSACTION_STATUS_PENDING_ASSET_TRANSFER
      );
    } else if (type == environment.TRANSACTION_STATUS_PENDING_ASSET_TRANSFER) {
      transaction.status = this.statusOptions?.find(
        (item: any) =>
          item.status == environment.TRANSACTION_STATUS_ASSETS_TRANSFER
      );
    }

   await this.myListingsService
      .handleUpdateEckardTransactions(transaction)
      .subscribe(
        (response: any) => {
          let listsType =
            type == environment.TRANSACTION_TYPE_PSA_TO_BE_CREATED
              ? environment.TRANSACTION_STATUS_PENDING_PSA
              : type;
          this.handleGetEckardTransactions(listsType);
          this.toastr.success(environment.TRANSACTION_UPDATE_STATUS_MESSAGE);
        },
        (error: any) => {
          this.spinner.hide();
          console.log('Error getting Update Eckard Transactions', error);
        },
        () => console.log('Done getting Update Eckard Transactions ')
      );
  }

  onTableDataChange(event: any) {
    this.page = event;
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  handleChange() {
    if (!this.searchParam) {
      this.transactionsData = this.copyTransactionsData;
      return;
    }

    const filteredData = this.copyTransactionsData?.filter((item: any) => {
      const searchParamLower = this.searchParam.toLowerCase(); // convert searchParam to lowercase
      const itemListingTrnxId = item?.trnxId?.toLowerCase();
      const itemListingAccountContactFirstName =
        item?.listing?.account?.contact?.mpName?.toLowerCase();
      const itemOfferContactFirstName =
        item?.offer?.contact?.mpName?.toLowerCase();
      const itemListingProjectProjectId =
        item?.listing?.project?.projectId?.toLowerCase();

      return (
        itemListingAccountContactFirstName.includes(searchParamLower) ||
        itemOfferContactFirstName.includes(searchParamLower) ||
        itemListingTrnxId.includes(searchParamLower) ||
        itemListingProjectProjectId.includes(searchParamLower)
      );
    });

    this.transactionsData = filteredData;
  }

  handleLength(array: any) {
    if (array && array.length > 0) {
      return true;
    }
    return false;
  }

  async handleGetStatus() {
    await this.addNewListingService.handleGetStatus().subscribe(
      (response: any) => {
        this.statusOptions = response;
      },
      (error: any) => {
        console.log('Error getting status', error);
      },
      () => console.log('Done getting status ')
    );
  }

  async handleOfferDealMessages() {
    await this.myOffersService.handleOfferDealMessages().subscribe(
      (response: any) => {
        this.offerConfirmMessages = response;
      },
      (error: any) => {
        console.error('Error getting key vlaue  : ', error);
      },
      () => console.log('Done getting key vlaue .')
    );
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }

  onSortChange(event: any): void {
    let sortProperty = '';
    switch (event.active) {
      case 'Trxn ID':
        sortProperty = 'trnxId';
        break;
      case 'Seller':
        sortProperty = 'listing.account.contact.mpName';
        break;
      case 'Buyer':
        sortProperty = 'offer.contact.mpName';
        break;
      case 'Project':
        sortProperty = 'listing.project.projectId';
        break;
      case 'NMA':
        sortProperty = 'nma';
        break;
      case 'Status':
        sortProperty = 'status.statusLabel';
        break;

      default:
        return;
    }
    const sortOrder = event.direction === 'asc' ? 1 : -1;

    this.transactionsData = this.transactionsData.sort((a: any, b: any) => {
      const aValue = this.getPropertyValue(a, sortProperty);
      const bValue = this.getPropertyValue(b, sortProperty);

      if (aValue < bValue) {
        return -1 * sortOrder;
      } else if (aValue > bValue) {
        return 1 * sortOrder;
      } else {
        return 0;
      }
    });
  }

  getPropertyValue(object: any, propertyPath: string): any {
    const props = propertyPath.split('.');
    let value = object;
    for (const prop of props) {
      value = value[prop];
    }
    return value;
  }
}
