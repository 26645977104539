<div class="mt-4">
    <h5 class="offer-heading">
        <ng-container *ngIf="!(isTransaction || isEckardTeam); else elseTransactionsBlock">My Offer</ng-container>
        <ng-template #elseTransactionsBlock>
            Offer Details
        </ng-template>
    </h5>
</div>
<div class="mt-4">

    <label for="constraints" class="form-label fw-bold">Constraints / Contingencies</label>
    <div class="col-sm-12">
        <ng-container *ngIf="isTransaction || isEckardTeam; else elseBlockConstraints">
            <ul class="checkbox-container">
                <ng-container *ngIf="newOffer?.constraints?.length > 0 ; else elseBlockOffer">

                    <li *ngFor="let item of newOffer.constraints">
                        {{item.buyLabel}}
                    </li>
                </ng-container>

                <ng-template #elseBlockOffer>
                    <li>
                        No constraints
                    </li>
                </ng-template>
            </ul>
        </ng-container>
        <ng-template #elseBlockConstraints>

            <div class="checkbox-container pe-auto">
                <div class="form-check" *ngFor="let item of constraintOptions">
                    <input class="form-check-input mt-1" type="checkbox" [(ngModel)]="item.isChecked"
                        (change)="changeSelection()" [disabled]="(offer.offer_Status == 'Accepted') || listDetails.is_expired_with_offers"
                        [name]="item.constraint" [id]="'constraint'+item.id">
                    <label for="'constraint'+item.id">{{item.buyLabel}}</label>
                    <span class="tooltip-container">
                        <span class="tooltip-container-icon">
                            <span class="tooltip-container-text">
                                {{item.info}}
                            </span>
                            <span class="tooltip-container-img">
                                <img src="assets/cash-flow.svg" width='16' height="16" class="ml-1"
                                    alt="chevron-info" />
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<div class="mt-3 row">
    <label for="comments" class="form-label fw-bold">Comments</label>
    <textarea class="form-control" [(ngModel)]="newOffer.comments" id="comments" name="comments" rows="3"
        [disabled]="(offer.offer_Status == 'Accepted') || isTransaction || isEckardTeam || listDetails.is_expired_with_offers"></textarea>
</div>

<div class="row mt-3">
    <div class="form-inline d-flex gap-5 ">

        <div class="form-group  mb-2">
            <label for="myoffer" class="col-form-label fw-bold">
                <ng-container *ngIf="!isTransaction; else elseTransactionBlock">My Offer</ng-container>
                <ng-template #elseTransactionBlock>
                    Transaction Amount
                </ng-template>
            </label>
            <input [(ngModel)]="newOffer.offerAmount" (ngModelChange)="handleChange(offer)" class="mx-2 form-control"
                id="offerAmount" name="offerAmount" currencyMask
                [options]="{ prefix: '$', thousands: ',',precision: 0, align:'left', allowNegative: false}"
                [disabled]="offer.auctionType == 'Fix Price' || offer.auctionType == 'Direct Sale' || isTransaction || isEckardTeam || listDetails.is_expired_with_offers"
                [ngClass]="{'border border-danger': handleMinimumAsking()}">
            <small class="text-muted">
                <ng-container *ngIf="isTransaction || isEckardTeam; else transactionAmount">
                    {{(newOffer.offerAmount / offer.nma) | currency:'':'':'.2-2'
                    }}/NMA
                </ng-container>
                <ng-template #transactionAmount>
                    {{(newOffer.offerAmount / offer.listedNMA) | currency:'':'':'.2-2'
                    }}/NMA
                </ng-template>
            </small>

        </div>
    </div>
    <span *ngIf="handleMinimumAsking()" class="text-danger rounded px-4 error-message">
        Offer must be greater than Minimum Asking Price
    </span>
</div>

<ng-container *ngIf="transactionStatus == 'Buy'">
    <div class="row mt-3">

        <div class="form-group d-flex d-flex gap-5 mb-2">
            <div>

                <label for="myoffer" class="col-form-label text-success fw-bold">
                    Target Account
                </label>
            </div>
            <div>

                <ng-container *ngIf="newOffer.account ; else targetAccountBlock">
                    <select id="account" name="account" class="form-select" [(ngModel)]="newOffer.account"
                        disabled [compareWith]="auctionTypeComparator">
                        <option *ngFor="let item of accountsOptions" [ngValue]="item">{{item.accountName}}</option>
                    </select>
                </ng-container>
                <ng-template #targetAccountBlock>
                    <select id="account" name="account" class="form-select" [(ngModel)]="selectedAccount"
                        (change)="handleAccountTargetMessage(offer)" [disabled]="isEckardTeam">
                        <option [value]="selectedAccount" [disabled]="true " [selected]="true">select an account
                        </option>
                        <option *ngFor="let item of accountsOptions" [ngValue]="item">{{item.accountName}}</option>
                    </select>

                </ng-template>

            </div>


        </div>
    </div>

</ng-container>

<div class="my-3 row p-0" *ngIf="!isTransaction && !isEckardTeam">
    <app-cash-flow-calculator-tab [offerConfirmMessages]="offerConfirmMessages" [createNewListing]="listDetails" ></app-cash-flow-calculator-tab>
</div>

<div class="d-flex gap-3 mt-5">
    <ng-container *ngIf="!isTransaction && !isEckardTeam">

        <button type="submit" class="btn btn-warning text-white fw-bold mb-2"
            *ngIf="(offer?.offer_Status != 'Accepted' && listDetails?.status?.status != 'Accepted' && !listDetails.is_expired_with_offers) "
            [disabled]="(handleAvailableCapital())"
            data-bs-toggle="modal" [attr.data-bs-target]="'#staticBackdrop'+index" (click)="handleMessage(offer)">
            {{offer.auctionType == "Fix Price" || offer.auctionType == 'Direct Sale' ||
            (offer.auctionType?.endsWith('Buy Now or Make an Offer') && newOffer.offerAmount >= offer.buyNowPrice)
            ? 'BUY NOW' : 'SUBMIT OFFER'}}</button>
        <button type="submit" class="btn btn-danger mb-2 fw-bold" data-bs-toggle="modal"
            [attr.data-bs-target]="'#staticBackdrop1'+index" (click)="handleCancelMessage()"
            *ngIf="(newOffer.id && (offer.offer_Status != 'Accepted') && offer.auctionType != 'Fix Price' && offer.auctionType != 'Direct Sale' && offer.offer_Status != 'Cancelled' &&  listDetails.status.status != 'Accepted')">CANCEL
            OFFER</button>
    </ng-container>
    <button *ngIf="!isEckardTeam" type="submit" class="btn btn-outline-dark cancel-button  mb-2 fw-bold"
        data-toggle="collapse" [attr.data-target]="'#collapse'+index" aria-expanded="false"
        [attr.aria-controls]="'collapse'+index">
        Close Panel
    </button>

</div>

<!-- Button trigger modal -->
<!-- Modal -->
<div class="modal fade text-wrap" id="staticBackdrop{{index}}" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header" *ngIf="!blockOffer">
                <ng-container *ngIf="listConstraintsOfferWarning ; else listConstraintsOfferWarningHeaderElseBlock">
                    <h1 class="modal-title" id="staticBackdropLabel">Constraints Warning Message</h1>
                </ng-container>
                <ng-template #listConstraintsOfferWarningHeaderElseBlock>
                    <h5 class="modal-title" id="staticBackdropLabel" [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"></h5>
                </ng-template>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <ng-container *ngIf="listConstraintsOfferWarning ; else listConstraintsOfferWarningBodyElseBlock">
                <div class="modal-body">
                    <p>Please note that while you can submit an offer, the seller may reject it if
                    there are violations of the existing constraints.</p>
                    <ul>
                        <li *ngFor="let message of constraintsViolationMessages ">
                            <ng-container *ngIf="message.violation_message ; else elseBlockViolationMessage">
    
                                <dd>{{message.violation_message}}</dd>
                            </ng-container>
                            <ng-template #elseBlockViolationMessage>
    
                                <dd>{{message.constraint}}</dd>
                            </ng-template>
                        </li>
    
                    </ul>
                </div>

            </ng-container>
            <ng-template #listConstraintsOfferWarningBodyElseBlock>

                <div class="modal-body" *ngIf="!blockOffer;else offerBlock" [innerHTML]="sanitizeHTML(offerDisclaimer.value2)">
                </div>
                <ng-template #offerBlock>
                    <div class="modal-body">
                        <p>The Buying process is blocked due to incompatible Constraints/Contingencies</p>
                        <ul>
                            <li *ngFor="let message of constraintsViolationMessages ">
                                <ng-container *ngIf="message.violation_message ; else elseBlockViolationMessage">
    
                                    <dd>{{message.violation_message}}</dd>
                                </ng-container>
                                <ng-template #elseBlockViolationMessage>
    
                                    <dd>{{message.constraint}}</dd>
                                </ng-template>
                            </li>
    
                        </ul>
                    </div>
                    <div class="modal-footer">
                        <div class="mr-auto ">

                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-danger ml-2 fw-bold">CANCEL</button>
                        </div>
                    </div>
                </ng-template>
            </ng-template>

            <div class="modal-footer" *ngIf="!blockOffer">
                <div class="mr-auto ">
                    <ng-container *ngIf="listConstraintsOfferWarning ; else listConstraintsOfferWarningButtonElseBlock">
                        <button type="button" (click)="listConstraintsOfferWarning = false"
                            class="btn btn-warning text-white mr-2 fw-bold">SUBMIT</button>
                    </ng-container>
                    <ng-template #listConstraintsOfferWarningButtonElseBlock>
                        <button type="button" (click)="handleSubmitOffer(newOffer, offer)"
                            class="btn btn-warning text-white mr-2 fw-bold" data-bs-dismiss="modal">ACCEPT</button>
                    </ng-template>

                    <button type="button" data-bs-dismiss="modal" class="btn btn-danger ml-2 fw-bold">CANCEL</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade text-wrap" id="staticBackdrop1{{index}}" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel" [innerHTML]="sanitizeHTML(offerDisclaimer.value1)">
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" [innerHTML]="sanitizeHTML(offerDisclaimer.value2)">
            </div>
            <div class="modal-footer">
                <div class="mr-auto ">

                    <button type="button" class="btn btn-warning text-white mr-2 fw-bold" data-bs-dismiss="modal"
                        (click)="handleCancelOffer(newOffer)">YES</button>
                    <button type="button" class="btn btn-danger ml-2 fw-bold" data-bs-dismiss="modal">NO</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
</ngx-spinner>


<div class="modal fade text-wrap" id="exampleModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true" [ngClass]="{ 'show': isShow == true }"
    [style.display]="isShow == true ? 'block' : 'none'">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg text-dark">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel" [innerHTML]="sanitizeHTML(offerDisclaimer.value1)">
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="clearSelectedAccount()"></button>
            </div>
            <div class="modal-body" [innerHTML]="sanitizeHTML(offerDisclaimer.value2)">
            </div>
            <div class="modal-footer">
                <div class="mr-auto">
                    <button type="button" class="btn btn-warning text-white mr-2 fw-bold" data-bs-dismiss="modal"
                        (click)="handleAssociateAccount()">
                        YES
                    </button>
                    <button type="button" class="btn btn-danger ml-2 fw-bold" data-bs-dismiss="modal"
                        (click)="clearSelectedAccount()">
                        NO
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>