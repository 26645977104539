<div class="d-flex m-auto  mt-5 card w-50 border-0 auth-container">
    <ng-container *ngIf="!isResetPasswordFlag ; else elseBlockResetPassword">

        <form #signUp="ngForm" class="auth-container-form ">
            <h3 class="auth-container-title">
                
                <ng-container *ngIf="isResetPassword ; else isResetPasswordElseBlock">P2PMP Reset Password</ng-container>
                <ng-template #isResetPasswordElseBlock>
                 P2P Marketplace   Sign Up
                </ng-template>
            </h3>

            <div class=" px-4 position-relative">
                <ng-container *ngIf="!isRegisterUser; else isRegisterUserInfoElseBlock ">

                    <p>
                        The Eckard Pier-to-Pier Marketplace allows Eckard Partners to trade their Mineral Rights with
                        other
                        Eckard
                        Partners
                    </p>
                    <p>Use your existing Eckard Login to Sign Up.
                    </p>
                </ng-container>
                <ng-template #isRegisterUserInfoElseBlock>
                    <p>
                        Please check your email inbox (and Spam folder) and enter
                        the Verification Code below to complete the Sign-Up for the Peer-to-Peer Marketplace.
                    </p>

                </ng-template>
            </div>

            <ng-container *ngIf="!isRegisterUser ; else isRegisterUserFieldElseBlock ">

                <div class=" px-4 position-relative">
                    <input type="email" id="email" name="email" class="form-control py-2 px-5" placeholder="Email"
                        [(ngModel)]="signUPForm.email" required #email="ngModel" email autocomplete="username"
                        [ngClass]="{'border border-danger':email.invalid && (email.dirty || email.touched)}">
                    <i class="fa fa-envelope px-4" aria-hidden="true"></i>
                </div>
                <div *ngIf="email.invalid && (email.dirty || email.touched ) &&  email.errors"
                    class="text-danger rounded px-4 error-message">
                    Please enter valid email
                </div>
                <div class="mt-3 d-flex justify-content-end px-4">
                    <span class="auth-container-redirect-path" [routerLink]="['/']">
                        <ng-container *ngIf="isResetPassword ; else elseBlockIsResetPassword">

                            Back to Login
                        </ng-container>
                        <ng-template #elseBlockIsResetPassword>
                            Marketplace Login
                        </ng-template>
                    </span>
                </div>
            </ng-container>

            <ng-template #isRegisterUserFieldElseBlock>
                <div class=" px-4 position-relative">
                    <input type="text" id="verifyCode" name="verifyCode" class="form-control py-2 px-5"
                        placeholder="Verification Code" [(ngModel)]="signUPForm.verifyCode" required
                        #verifyCode="ngModel"
                        [ngClass]="{'border border-danger':verifyCode.invalid && (verifyCode.dirty || verifyCode.touched)}">

                </div>
                <div *ngIf="verifyCode.invalid && (verifyCode.dirty || verifyCode.touched ) &&  verifyCode.errors"
                    class="text-danger rounded px-4 error-message">
                    Please enter valid Verification Code
                </div>


            </ng-template>

            <div class="px-4 mt-3">
                <input type="submit" class="btn btn-warning btn-lg form-control form-button" data-bs-toggle="modal"
                    data-bs-target="#exampleModal" [value]="isRegisterUser ? isResetPassword ? 'Complete Reset Password': 'Complete Sign Up': (isResetPassword ? 'Reset Password':'Sign Up')"
                    (click)="isRegisterUser ? handleMessage('verification') : isResetPassword? handleMessage('reset-password'):  handleMessage('sign-up') " [disabled]="signUp.form.invalid" />
            </div>

        </form>
    </ng-container>
    <ng-template #elseBlockResetPassword>
        <app-reset-password [resetPassword]="resetPassword" [isChangePassword]="isResetPassword"></app-reset-password>
    </ng-template>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
</ngx-spinner>


<div class="modal fade text-wrap" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel" [innerHTML]="sanitizeHTML(offerDisclaimer.value1)">
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" [innerHTML]="sanitizeHTML(offerDisclaimer.value2)">
            </div>
            <div class="modal-footer">
                <div class="mr-auto ">

                    <button type="button" class="btn btn-warning text-white mr-2 fw-bold" data-bs-dismiss="modal"
                        (click)="isRegisterUser ? handleVerificationCode() : handleSubmit() ">YES</button>
                    <button type="button" class="btn btn-danger ml-2 fw-bold" data-bs-dismiss="modal">NO</button>
                </div>
            </div>
        </div>
    </div>
</div>