import { LoginService } from 'src/components/services/login.service';
import { Component, OnInit } from '@angular/core';
import { MyListingsService } from '../services/my-listings.service';
import { MyOffersService } from '../services/my-offers.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OfferConfirmMessages } from '../model/login';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-market-place',
  templateUrl: './market-place.component.html',
  styleUrls: ['./market-place.component.scss'],
})
export class MarketPlaceComponent implements OnInit {
  totalMyListings: number = 0;
  totalMyOffers: number = 0;
  isActiveUser: Boolean = false;
  allActiveListings: number = 0;
  sellerTotalPendingList: number = 0;
  sellerTotalClosedList: number = 0;
  buyerTotalPendingList: number = 0;
  buyerTotalClosedList: number = 0;
  sellerNotificationsCount: number = 0;
  buyerNotificationsCount: number = 0;
  offerConfirmMessages: OfferConfirmMessages[] = [];
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: '',
  };
  isEckard: Boolean = false;

  constructor(
    private myListingsService: MyListingsService,
    private loginService: LoginService,
    private myOffersService: MyOffersService,
    private spinner: NgxSpinnerService,private commonService: CommonService

  ) {}

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  ngOnInit(): void {
    this.isActiveUser =
      this.loginService?.user?.status == 'active' ? true : false;
    this.isEckard =
      this.loginService?.user?.role?.name != 'Eckard' ? true : false;
    this.isEckard && this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      this.spinner.show();

      await Promise.all([
        this.getAllMyListings(),
        this.getAllMyOffers(),
        this.handlePendingListsTransactions(),
        this.handleClosedListsTransactions(),
        this.handlePendingOfferTransactions(),
        this.handleClosedOfferTransactions(),
        this.getAllActiveListings(),
        this.handleSellerNotifications(),
        this.handleBuyerNotifications(),
        this.handleOfferDealMessages(),
      ]);

      this.spinner.hide();
    } catch (error) {
      console.error(error);
      this.spinner.hide();
    }
  }

  async getAllMyListings(): Promise<void> {
    const response = await this.myListingsService
      .getAllMyListings(this.loginService.user!.id, 1, 0, 'Active')
      .toPromise();

    this.totalMyListings = response?.metadata?.total_results || 0;
  }
  async getAllMyOffers(): Promise<void> {
    const response: any = await this.myOffersService
      .getAllMyOffers(this.loginService.user!.id, 1, 0)
      .toPromise();

    this.totalMyOffers = response?.metadata?.total_results || 0;
  }

  async getAllActiveListings(): Promise<void> {
    const response: any = await this.myOffersService
      .getAllListings(this.loginService.user!.id, 1, 0, [], [], false)
      .toPromise();

    this.allActiveListings = response?.metadata?.total_results || 0;
  }

  async handlePendingListsTransactions(): Promise<void> {
    const response = await this.myListingsService
      .handleGetSellerPendingTransactions(
        this.loginService.user!.id,
        1,
        0,
        'sellerPendingTransactions'
      )
      .toPromise();

    this.sellerTotalPendingList = response?.metadata?.total_results || 0;
  }

  async handleClosedListsTransactions(): Promise<void> {
    const response = await this.myListingsService
      .handleGetSellerPendingTransactions(
        this.loginService.user!.id,
        1,
        0,
        'sellerClosedTransactions'
      )
      .toPromise();

    this.sellerTotalClosedList = response?.metadata?.total_results || 0;
  }

  async handlePendingOfferTransactions(): Promise<void> {
    const response = await this.myListingsService
      .handleGetBuyerPendingTransactions(
        this.loginService.user!.id,
        1,
        0,
        'buyerPendingTransactions'
      )
      .toPromise();

    this.buyerTotalPendingList = response?.metadata?.total_results || 0;
  }

  async handleClosedOfferTransactions(): Promise<void> {
    const response = await this.myListingsService
      .handleGetBuyerPendingTransactions(
        this.loginService.user!.id,
        1,
        0,
        'buyerClosedTransactions'
      )
      .toPromise();

    this.buyerTotalClosedList = response?.metadata?.total_results || 0;
  }

  async handleSellerNotifications(): Promise<void> {
    const response = await this.myListingsService
      .handleNotifications('Sell', this.loginService.user!.id, 1, 0, '')
      .toPromise();

    this.sellerNotificationsCount = response?.metadata?.total_results || 0;
  }

  async handleBuyerNotifications(): Promise<void> {
    const response = await this.myListingsService
      .handleNotifications('Buyer', this.loginService.user!.id, 1, 0, '')
      .toPromise();

    this.buyerNotificationsCount = response?.metadata?.total_results || 0;
  }

  async handleOfferDealMessages(): Promise<any> {
    this.offerConfirmMessages = await this.myOffersService
      .handleOfferDealMessages()
      .toPromise();
  }

  handleMessage(key: string) {
    let message = this.offerConfirmMessages?.filter(
      (item: any) => item.key == key
    );
    this.offerDisclaimer = message[0];
  }
}
