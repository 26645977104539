import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpComponent } from './sign-up.component';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomDatePipe } from 'src/common/custom-date.pipe';
import { RouterModule } from '@angular/router';
import { ResetPasswordModule } from '../reset-password/reset-password.module';
import { ChildRoutes } from 'src/common/child-routes';


@NgModule({
  declarations: [SignUpComponent],
  imports: [
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    RouterModule.forChild(ChildRoutes),
    ResetPasswordModule,
  ],
  providers: [CustomDatePipe],
  exports: [SignUpComponent]
})
export class SignUpModule {}
