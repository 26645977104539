import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotificationsModule } from '../../notifications/notifications.module';
import { BuyerNotificationsComponent } from './buyer-notifications.component';
import { RouterModule } from '@angular/router';
import { ChildRoutes } from 'src/common/child-routes';



@NgModule({
    declarations: [
        BuyerNotificationsComponent
    ],
    imports: [
        RouterModule.forChild(ChildRoutes),
        FormsModule,
        NotificationsModule
    ],
    providers: [],
    exports: [BuyerNotificationsComponent]
})
export class BuyerNotificationsModule { }
