import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { EckardExpiredListingsComponent } from './eckard-expired-listings.component';
import { ChildRoutes } from 'src/common/child-routes';
import { MyListingModule } from '../../seller/my-listing/my-listing.module';


@NgModule({
  declarations: [EckardExpiredListingsComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    RouterModule.forChild(ChildRoutes),
    MyListingModule
  ],
  providers: [EckardExpiredListingsComponent],
  exports: []

})
export class EckardExpiredListingsModule { }
