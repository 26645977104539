<div>
  <h5 class="offer-heading">Further Credit Details</h5>
</div>

<div class="d-flex flex-row mt-4">
  <ng-container *ngIf="isFurtherCredit">
    <div class="col gap-2 p-0">

    </div>

  </ng-container>
  <div class="col gap-2 p-0">
    <div>
      <label for="Futhter Credit" class="form-label fw-bold"
        >Account Holder</label
      >
    </div>
    <div>
      <span class="text-muted listing-details-content">
        {{ furtherCreditDetails?.Recipient }}
      </span>
    </div>
  </div>

  <div class="col gap-2 p-0">
    <div>
      <label for="Futhter Credit" class="form-label fw-bold"
        >Account Number</label
      >
    </div>
    <div>
      <span class="text-muted listing-details-content">
        <ng-container
          *ngIf="isFurtherCredit || showData; else showDataElseBlock"
        >
          {{ furtherCreditDetails?.Account_Number }}
        </ng-container>
        <ng-template #showDataElseBlock>
          ...{{ furtherCreditDetails?.Account_Number?.toString()?.slice(-4) }}
        </ng-template>
      </span>
      <ng-container *ngIf="!isFurtherCredit">
        <i
          class="fa fa-email px-4"
          [ngClass]="showData ? 'fa-eye-slash' : 'fa-eye'"
          (click)="toggleData()"
        ></i>
      </ng-container>
    </div>
  </div>
  <div class="col gap-2 p-0">
    <div>
      <label for="Further Credit" class="form-label fw-bold"
        >Recipient Address:</label
      >
    </div>
    <div>
      <span class="text-muted listing-details-content">
        {{ furtherCreditDetails?.Recipient_Address }},
        {{ furtherCreditDetails?.City }}, {{ furtherCreditDetails?.State }},
        {{ furtherCreditDetails?.country }}
      </span>
    </div>
  </div>
</div>
