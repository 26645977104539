<ng-container *ngIf="isError">

    <div class="alert alert-danger alert-dismissible fade show  mt-2 w-50 mx-auto" role="alert">
        <strong>{{errorMessage}} </strong>
        <button (click)="isError = !isError" type="button" class="btn-close" data-bs-dismiss="alert"
            aria-label="Close"></button>
    </div>
</ng-container>

<div class="d-flex m-auto  mt-5 card w-50 border-0 auth-container">

    <form (ngSubmit)="handleSubmit()" #login="ngForm" class="auth-container-form">
        <h3 class="auth-container-title">Log In</h3>

        <div class=" px-4 position-relative">
            <input type="email" id="email" name="email" class="form-control py-2 px-5" placeholder="Email"
                [(ngModel)]="loginForm.email" required #email="ngModel"
                [ngClass]="{'border border-danger':email.invalid && (email.dirty || email.touched)}" email autocomplete="username">
            <i class="fa fa-envelope px-4" aria-hidden="true"></i>
        </div>
        <div *ngIf="email.invalid && (email.dirty || email.touched ) &&  email.errors"
            class="text-danger rounded px-4 error-message">
            Please enter valid email
        </div>

        <div class="mt-3 px-4 position-relative">
            <input [type]="isPassword ? 'text' : 'password' " class="form-control py-2 px-5"
                [ngClass]="{'border border-danger':password.invalid && (password.dirty || password.touched)}"
                name="password " id="password" placeholder="Password" [(ngModel)]="loginForm.password" required
                #password="ngModel" autocomplete="current-password">
            <i class="fa fa-lock px-4" aria-hidden="true"> </i>

            <i class="fa fa-email px-4 " [ngClass]="{'fa-eye': !isPassword , 'fa-eye-slash': isPassword}"
                (click)="togglePassword()"> </i>

        </div>
        <div *ngIf="password.invalid && (password.dirty || password.touched)"
            class="text-danger rounded px-4 error-message ">
            Please enter password
        </div>

        <div class="mt-3  d-flex justify-content-between d px-4">
            <div>

                <span for="forgot_password" class="auth-container-redirect-path" [routerLink]="['/reset-password']"> Reset Password</span>
            </div>
            <div>

                <span for="forgot_password" class="auth-container-redirect-path" [routerLink]="['/sign-up']"> Sign Up for
                    Marketplace</span>
            </div>
        </div>
        <div class="px-4 mt-3">
            <input type="submit" class="btn btn-warning btn-lg form-control form-button"
                [value]="!isloading ? 'Log In' : 'Loading ...'" [disabled]="login.form.invalid" />
        </div>
    </form>
</div>