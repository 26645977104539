import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-buttons',
  templateUrl: './radio-buttons.component.html',
})
export class RadioButtonsComponent implements OnInit {
  @Input() label!: string;
  @Input() selectedOption!: string;
  @Output() optionChanged: EventEmitter<string> = new EventEmitter<string>();
  furtherInstructionsOptions = ['Yes', 'No'];

  ngOnInit() {
    if (!this.selectedOption) {
        setTimeout(() => {
            this.selectedOption = 'No';
            this.emitOptionChanged();
          });
    }
  }

  onOptionChanged(value: string) {
    this.selectedOption = value;
    this.emitOptionChanged();
  }

  private emitOptionChanged() {
    this.optionChanged.emit(this.selectedOption);
  }
}
