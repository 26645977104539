import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotificationsModule } from '../../notifications/notifications.module';
import { SellerNotificationsComponent } from './seller-notifications.component';
import { RouterModule } from '@angular/router';
import { ChildRoutes } from 'src/common/child-routes';


@NgModule({
    declarations: [
        SellerNotificationsComponent
    ],
    imports: [
        RouterModule.forChild(ChildRoutes),
        FormsModule,
        NotificationsModule
    ],
    providers: [],
    exports: [SellerNotificationsComponent]
})
export class SellerNotificationsModule { }
