<div class="header-container">
    <nav class="navbar navbar-expand-lg navbar-light p-0 px-1">
        <a class="navbar-brand pl-2">
            <img [src]="logo" class=" m-1" alt="Avatar" />
        </a>
        <ng-container *ngIf="isAuthanticated()">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">

                <div
                    class="navbar-nav mx-auto bg-dark h-100 text-center d-flex justify-content-center m-auto align-items-center p-4">
                    <a class="nav-link text-warning" [routerLink]="['/market-place']">Marketplace </a>

                </div>
                <div class="navbar-nav">
                    <div class="dropdown">
                        <a class="nav-item nav-link dropdown-toggle text-black" href="#" id="user-dropdown"
                            data-toggle="dropdown" *ngIf="user">
                            <ng-container *ngIf="user.name ; else userElseBlock">
                                {{user.name}}
                            </ng-container>
                            <ng-template #userElseBlock>
                                {{user.email}}
                            </ng-template>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="nav-link text-black pe-auto mx-2" [routerLink]="['/profile']"
                                title="View Profile"><i class="fa fa-user" aria-hidden="true"></i>
                                Profile</a>
                            <a class="nav-link text-black pe-auto mx-2" (click)="handleLogOut()" title="Log Out"><i
                                    class="fa fa-sign-out" aria-hidden="true"></i>
                                Log Out</a>

                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </nav>
</div>