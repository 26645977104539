import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { RouterModule } from '@angular/router';
import { ChildRoutes } from 'src/common/child-routes';
import { FurtherCreditDetailsComponent } from './further-credit-details.component';


@NgModule({
  declarations: [FurtherCreditDetailsComponent],
  imports: [
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    CurrencyMaskModule,
    
  ],
  providers: [],
  exports: [FurtherCreditDetailsComponent],
})
export class FurtherCreditDetailsModule {}
