import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { enc, AES } from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor(private router: Router){}

  public encryptData(data: any = ''):  any {
    try {
      if (!data) {
        return null; // or handle the case of a null value as desired
      }
      const encryptedData = AES.encrypt(
        data,
        environment.SECURITY_KEY
      ).toString();
      return encryptedData;
    } catch (error) {
      // Handle the error case as desired
      console.error('Encryption error:', error);
      localStorage.clear();
      this.router.navigate(['']);
      return null;
    }
  }

  public decryptData(encryptedData: any = ''): any {
    try {
      if (!encryptedData) {
        return null; // or handle the case of a null value as desired
      }
      const decryptedBytes = AES.decrypt(encryptedData, environment.SECURITY_KEY);
      const decryptedData = decryptedBytes.toString(enc.Utf8);
      return decryptedData;
    } catch (error: any) {
      // Handle the error case as desired
      console.error('Decryption error:', error);
      localStorage.clear();
      this.router.navigate(['']);
      return null;
    }
  }
}
