import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/components/services/login.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-eckard-staff-place',
  templateUrl: './eckard-staff-place.component.html',
})
export class EckardStaffPlaceComponent implements OnInit {
  pendingPSA: number = 0;
  completedFT: number = 0;
  pendingPAT: number = 0;
  totalFTM: number = 0;
  totalExpiredListings: number = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private myListingsService: MyListingsService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    try {
      this.spinner.show();
      const [
        myListings,
        pendingTransactions,
        completeTransaction,
        pendingAssetTransaction,
        eckardTransaction,
      ] = await Promise.all([
        this.getAllMyListings(),
        this.handleGetPendingTransactions(),
        this.handleGetCompeleteTransactions(),
        this.handleGetPendingAssetTransactions(),
        this.handleGetEckardTransactionsMethods(),
      ]);
      this.totalExpiredListings = myListings?.metadata?.total_results || 0;
      this.pendingPSA = pendingTransactions ? pendingTransactions?.length : 0;
      this.completedFT = completeTransaction ? completeTransaction?.length : 0;
      this.pendingPAT = pendingAssetTransaction ? pendingAssetTransaction?.length : 0;
      this.totalFTM = eckardTransaction ? eckardTransaction?.length : 0;
      this.spinner.hide();
      
    } catch (e) {
      this.spinner.hide();
      console.log('Error', e);
    }
  }

 async handleGetPendingTransactions() {
   const response: any = await  this.myListingsService
      .handleGetEckardTransactions(environment.TRANSACTION_STATUS_PENDING_PSA)
      .toPromise()
    return response
       
  }

  async handleGetCompeleteTransactions() {
    const response : any = await this.myListingsService
      .handleGetEckardTransactions(
        environment.TRANSACTION_FUND_TRANSFER_CONFIRMED
      )
      .toPromise()
    return response;
  }

  async handleGetPendingAssetTransactions() {
    const response: any = await this.myListingsService
      .handleGetEckardTransactions(
        environment.TRANSACTION_STATUS_PENDING_ASSET_TRANSFER
      )
      .toPromise()
      return response;
  }

 async handleGetEckardTransactionsMethods() {
    const response : any =  await this.myListingsService.handleGetEckardTransactionsMethods().toPromise()
    return response;
  }

  async getAllMyListings() {
    const response = await this.myListingsService
      .getAllMyListings(this.loginService.user!.id, 1, 0, 'Active', true)
      .toPromise();
    return response;
  }
}
