<div class=" p-4 rounded text-dark" [ngClass]="{'card-body mb-5 border shadow-lg bg-white ':notification}">
  <div class="mt-2">
    <h5 style="color: navy">Direct Messages</h5>
  </div>
  <div class="chat-container" *ngIf="emailMessages.length > 0">
    <div class="chat-header">Chat with {{ userName }}</div>
    <div class="chat-messages">
      <ng-container *ngFor="let message of emailMessages">
        <div
          class="message"
          [ngClass]="{
            outgoing: message.sender.id == currentUser!.id,
            incoming: message.sender.id != currentUser!.id
          }"
        >
          {{ message.message }}
          <sub class="message-date">{{ message.created_at | date : "MMM d, y, h:mm a" }}</sub>
        </div>
      </ng-container>

    </div>
  </div>

  <div class="mb-3 row mt-4">
    <label for="subject" class="col-sm-2 col-form-label fw-bold">Subject</label>
    <div class="col-6">
      <div class="input-group">
        <span class="input-group-text py-0">Listing {{ listingId }}</span>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="emailNotifications.subject"
          id="subject"
          name="subject"
        />
      </div>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="inputPassword" class="col-sm-2 col-form-label fw-bold"
      >Message</label
    >
    <div class="col-6">
      <textarea
        class="form-control"
        id="body"
        name="body"
        rows="5"
        [(ngModel)]="emailNotifications.body"
      ></textarea>
    </div>
  </div>

  <div class="d-flex gap-3 mt-5">
    <button
      type="submit"
      class="btn btn-warning text-white mb-2 fw-bold"
      [attr.data-bs-target]="notification ? '#exampleModal' + index : null"
      [attr.data-bs-toggle]="notification? 'modal' : null "
      [disabled]="
        emailNotifications.body == '' || emailNotifications.subject == ''
      "
      (click)="!notification ? isShow = true : null "
    >
      Send Notification
    </button>

    <button
      type="submit"
      class="btn btn-outline-dark cancel-button mb-2 fw-bold"
      data-toggle="collapse"
      [attr.data-target]="'#collapse' + index"
      aria-expanded="false"
      [attr.aria-controls]="'collapse' + index"
      (click)="handleClearNotifications()"
      *ngIf="notification"
    >
      Close Panel
    </button>
  </div>
</div>

<ng-container>
  <div
    class="modal fade text-wrap"
    [id]="'exampleModal' + index"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    [ngClass]="{ 'show': isShow == true }"
    [style.display]="isShow == true ? 'block' : 'none'"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"
          ></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="isShow = false"
          ></button>
        </div>
        <div
          class="modal-body"
          [innerHTML]="sanitizeHTML(offerDisclaimer.value2)"
        ></div>
        <div class="modal-footer">
          <div class="mr-auto">
            <button
              type="button"
              class="btn btn-warning text-white mr-2 fw-bold"
              data-bs-dismiss="modal"
              (click)="handleSendEmailNotifications(listDetails)"
            >
              YES
            </button>
            <button
              type="button"
              class="btn btn-danger ml-2 fw-bold"
              data-bs-dismiss="modal"
              (click)="handleClearNotifications()"
            >
              NO
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
