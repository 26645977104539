import { User } from './../../model/login';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/components/services/login.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { MatSort } from '@angular/material/sort';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/components/services/common.service';
import {
  EmailMessages,
  MyListings,
  Notification,
} from '../../model/my-listings';
import { Offer } from 'src/components/model/my-offer';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
  @Input() notificationStatus!: string;

  notificationsData!: Notification[];
  copyNotificationsData!: Notification[];
  searchParam = '';
  notificationsColumns = ['Subject', 'Date/Time'];
  page: number = 1;
  count: number = 0;
  tableSize: number = 50;
  tableSizes: number[] = [3, 6, 9, 12];
  @ViewChild(MatSort) sort!: MatSort;
  listDetails!: MyListings;
  offer: Offer = {
    id: null,
    contact: null,
    status: null,
    constraints: [],
    comments: '',
    offerAmount: 0,
  };
  emailMessages: EmailMessages[] = [];
  receiverName!: string;
  index!: any;

  constructor(
    private spinner: NgxSpinnerService,
    private loginService: LoginService,
    private router: Router,
    private myListingsService: MyListingsService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (this.loginService?.user?.status != 'active') {
      this.router.navigate(['/market-place']);
      return;
    }
    this.handleNotifications();
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.handleNotifications();
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  handleNotifications() {
    this.spinner.show();
    let offset = (this.page - 1) * this.tableSize;
    this.myListingsService
      .handleNotifications(
        this.notificationStatus,
        this.loginService.user!.id,
        this.tableSize,
        offset,
        this.searchParam
      )
      .subscribe(
        (response: any) => {
          this.notificationsData = response?.data;
          this.copyNotificationsData = response?.data;
          this.page = response?.metadata?.page;
          this.count = response?.metadata?.total_results;
          this.tableSize = response?.metadata?.limit;
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
        },
        () => console.log('Done getting notifications')
      );
  }

  handleChange() {
    this.page = 1;
    this.count = 0;
    this.tableSize = 50;
    this.handleNotifications();
  }

  handleLength(array: any) {
    if (array && array.length > 0) {
      return true;
    }
    return false;
  }

  onSortChange(event: any): void {
    let sortProperty = '';
    switch (event.active) {
      case 'Subject':
        sortProperty = 'subject';
        break;
      case 'Date/Time':
        sortProperty = 'created_at';
        break;
      default:
        return;
    }
    const sortOrder = event.direction === 'asc' ? 1 : -1;

    this.notificationsData = this.notificationsData.sort((a: any, b: any) => {
      const aValue = a[sortProperty];
      const bValue = b[sortProperty];

      if (aValue < bValue) {
        return -1 * sortOrder;
      } else if (aValue > bValue) {
        return 1 * sortOrder;
      } else {
        return 0;
      }
    });
  }

  handleDirectMessage(
    listId: any,
    offerId: any,
    index: any
  ) {
    this.spinner.show();
    this.index = index;
    this.handleGetListEmailMessages(listId);
    this.myListingsService.getMyList(listId).subscribe(
      (response: MyListings) => {
        this.spinner.hide();
        this.listDetails = response;
        this.offer = response?.offer?.find((item) => item.id == offerId);
        this.receiverName =
          this.notificationStatus == 'Buy'
            ? this.listDetails?.account?.contact?.mpName
            : this.offer?.contact?.mpName || '';
      },
      (error: any) => {
        this.spinner.hide();

        console.log('Error getting list details', error);
      },
      () => console.log('Done getting list details')
    );
  }

  async handleGetListEmailMessages(id: number): Promise<void> {
    this.spinner.show();  
    const response: any = await this.myListingsService
      .handleGetListEmailMessages(id)
      .toPromise();
      this.spinner.hide();
    const filteredMessages = response.filter(
      (msg: any) =>
        msg.sender.mpName === this.loginService.user?.mpName ||
        msg.receiver.mpName === this.loginService.user?.mpName
    );
    this.emailMessages = filteredMessages;
  }

  handleUpdateEmailMessages(id: any) {
    this.handleGetListEmailMessages(id);
  }
}
