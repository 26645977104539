<div class="container card mt-4 pt-4 h-100">
  <app-breadcrumb-container
    [containerClass]="'breadcrumb-container mb-3'"
    [breadcrumbItems]="[
      { label: 'Home', route: '/home' },
      { label: 'MarketPlace', route: '/market-place' },
      { label: 'Profile', route: '/profile' }
    ]"
  ></app-breadcrumb-container>
  <div class="container rounded bg-white mb-3">
    <div class="row">
      <div class="col p-0">
        <div class="py-2">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h4 class="text-right">Profile Settings</h4>
          </div>
          <div class="row">
            <div class="col-md-6 mt-2 text-start">
              <label class="labels">First Name</label
              ><input
                type="text"
                class="form-control"
                id="firstName"
                name="firstName"
                [(ngModel)]="profile.firstName"
                #firstName="ngModel"
                required
                [ngClass]="{
                  'border border-danger':
                    firstName.invalid && (firstName.dirty || firstName.touched)
                }"
              />
              <div
                *ngIf="
                  firstName.invalid &&
                  (firstName.dirty || firstName.touched) &&
                  firstName.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please enter First Name
              </div>
            </div>

            <div class="col-md-6 mt-2 text-start">
              <label class="labels">Last Name</label
              ><input
                type="text"
                class="form-control"
                id="lastName"
                name="lastName"
                [(ngModel)]="profile.lastName"
                #lastName="ngModel"
                required
                [ngClass]="{
                  'border border-danger':
                    lastName.invalid && (lastName.dirty || lastName.touched)
                }"
              />
              <div
                *ngIf="
                  lastName.invalid &&
                  (lastName.dirty || lastName.touched) &&
                  lastName.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please enter Last Name
              </div>
            </div>

            <div class="col-md-6 mt-2 text-start">
              <label class="labels">Email</label
              ><input
                type="email"
                class="form-control"
                [(ngModel)]="profile.email"
                required
                #email="ngModel"
                id="email"
                name="email"
                [ngClass]="{
                  'border border-danger':
                    email.invalid && (email.dirty || email.touched)
                }"
                email
                disabled
              />
              <div
                *ngIf="
                  email.invalid &&
                  (email.dirty || email.touched) &&
                  email.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please enter valid email
              </div>
            </div>

            <div class="col-md-6 text-start mt-2">
              <label class="labels">Account Status</label
              ><input
                type="text"
                class="form-control"
                [ngClass]="{
                  'text-success': profile.mpStatus == 'active',
                  'text-danger': profile.mpStatus != 'active'
                }"
                id="mpStatus"
                name="mpStatus"
                [(ngModel)]="profile.mpStatus"
                disabled
              />
            </div>
            <div class="col-md-12 text-start mt-3">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input pe-auto"
                  id="emailNotificationsCheckbox"
                  [(ngModel)]="profile.notification"
                />
                <label
                  class="form-check-label pe-auto"
                  for="emailNotificationsCheckbox"
                  >Send email notifications</label
                >
              </div>
            </div>
            <div class="row mt-5 align-items-center mb-4">
              <div
                class="col-md-3 col-sm-6 text-start fw-bold"
                style="font-size: medium"
              >
                <button
                  class="btn btn-primary profile-button"
                  type="button"
                  (click)="handleSubmit()"
                  [disabled]="
                    !profile.firstName ||
                    !profile.lastName ||
                    !profile.email ||
                    email.invalid
                  "
                >
                  Save Profile
                </button>
              </div>

              <div
                class="col-md-3 col-sm-6 text-start pe-auto"
                style="text-decoration: underline"
                data-toggle="modal"
                data-target="#exampleModalLong"
                title="change password"
              >
                <span (click)="handleChangePasswor()"> Change Password </span>
              </div>
              <div
                class="col-md-3 col-sm-6 text-start pe-auto"
                style="text-decoration: underline"
                data-toggle="modal"
                data-target="#exampleModalLong1"
                title="add capital amount"
                *ngIf="handleIsEckardUser()"
              >
                <span
                  (click)="
                    capitalAmount.addFunds = capitalAmount.availableCapital
                  "
                >
                  Available Capital Settings
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="handleIsEckardUser()">
      <div class="row mt-2 p-0 mb-3" *ngIf="handleUserHandles()">
        <div class="text-start fw-bold" style="font-size: medium">
          User Handles
        </div>
        <div class="row px-3 mt-3">
          <div>Buyer: {{ profile.mpName || " " }}</div>
          <div *ngFor="let item of accountsOptions">
            Seller '{{ item?.accountName }}':
            {{ item?.mpName }}
          </div>
        </div>
      </div>

      <div class="row mt-2 p-0">
        <div class="col-md-3 text-start fw-bold" style="font-size: medium">
          Fund Transfer Methods
        </div>

        <div
          class="col text-start pe-auto"
          (click)="clickAddAccount()"
          style="text-decoration: underline"
        >
          Add New Transfer Method
        </div>
      </div>

      <div *ngIf="addAccount">
        <div class="row mt-2">
          <div class="row">
            <div class="col-md-6">
              <label for="account" class="form-label"
                >Account <span class="text-danger">*</span></label
              >
              <select
                id="account"
                class="form-select"
                [(ngModel)]="selectAccount"
                (ngModelChange)="handleChange('account')"
                #account="ngModel"
                [ngClass]="{
                  'border border-danger':
                    account.invalid && (account.dirty || account.touched)
                }"
                required
              >
                <option *ngFor="let item of accountsOptions" [value]="item.id">
                  {{ item.accountName }}
                </option>
              </select>

              <span
                *ngIf="
                  account.invalid &&
                  (account.dirty || account.touched) &&
                  account.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please select valid account
              </span>
            </div>
            <div class="col-md-6">
              <label for="type" class="form-label"
                >Transfer Type <span class="text-danger">*</span></label
              >
              <select
                id="type"
                class="form-select"
                [(ngModel)]="selectedType"
                (ngModelChange)="handleChange(selectedType)"
                #type="ngModel"
                [ngClass]="{
                  'border border-danger':
                    type.invalid && (type.dirty || type.touched)
                }"
                required
              >
                <option *ngFor="let item of accountsTypes" [value]="item">
                  {{ item }}
                </option>
              </select>

              <span
                *ngIf="
                  type.invalid && (type.dirty || type.touched) && type.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please select valid Type
              </span>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="selectedType === 'Wire'">
          <div
            class="col-md-3 text-start fw-bold mt-3"
            style="font-size: medium"
          >
            For credit to
          </div>
          <div class="row">
            <div class="col-md-6 mt-2 text-start">
              <app-custom-input-fields
                [label]="'Account number'"
                [fieldId]="'accountNumber'"
                [fieldName]="methods.Account_Number"
                [errorMessage]="'Please enter account number'"
                [ngModelRef]="'accountNumberRef'"
                (inputChanged)="handleInputChanged('Account_Number', $event)"
              ></app-custom-input-fields>
            </div>

            <div class="col-md-6 mt-2 text-start">
              <app-custom-input-fields
                [label]="'Re-enter acount number Name'"
                [fieldId]="'reEnterAccountNumber'"
                [fieldName]="methods.Re_Enter_AccountNumber"
                [errorMessage]="'Please re-enter account number'"
                [ngModelRef]="'reEnterAccountNumber'"
                (inputChanged)="
                  handleInputChanged('Re_Enter_AccountNumber', $event)
                "
              ></app-custom-input-fields>
              <div
                *ngIf="isAccountNumberMismatch()"
                class="text-danger rounded px-4 error-message"
              >
                Account numbers do not match
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mt-2 text-start">
              <app-custom-input-fields
                [label]="'Name of recipient account holder'"
                [fieldId]="'recipientName'"
                [fieldName]="methods.Recipient"
                [errorMessage]="'Please enter recipient name'"
                [ngModelRef]="'recipientName'"
                (inputChanged)="handleInputChanged('Recipient', $event)"
              ></app-custom-input-fields>
              <p class="font-italic my-0">
                <small
                  >May be an escrow company, brokerage firm or credit
                  union.</small
                >
              </p>
            </div>

            <div class="col-md-6 mt-2 text-start">
              <app-custom-input-fields
                [label]="'Address of recipient acount holder'"
                [fieldId]="'recipientAddress'"
                [fieldName]="methods.Recipient_Address"
                [errorMessage]="'Please enter recipient address'"
                [ngModelRef]="'recipientAddress'"
                (inputChanged)="handleInputChanged('Recipient_Address', $event)"
              ></app-custom-input-fields>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 mt-2 text-start">
              <app-custom-input-fields
                [label]="'City'"
                [fieldId]="'city'"
                [fieldName]="methods.City"
                [errorMessage]="'Please enter city name'"
                [ngModelRef]="'city'"
                (inputChanged)="handleInputChanged('City', $event)"
              ></app-custom-input-fields>
            </div>

            <div class="col-md-4 mt-2 text-start">
              <app-custom-input-fields
                [label]="'State'"
                [fieldId]="'state'"
                [fieldName]="methods.State"
                [errorMessage]="'Please enter state name'"
                [ngModelRef]="'state'"
                (inputChanged)="handleInputChanged('State', $event)"
              ></app-custom-input-fields>
            </div>
            <div class="col-md-4 mt-2 text-start">
              <label class="labels">Country </label>

              <select
                id="country"
                class="form-select"
                [(ngModel)]="methods.country"
                (ngModelChange)="handleCountryChange($event)"
                #country="ngModel"
                [ngClass]="{
                  'border border-danger':
                    country.invalid && (country.dirty || country.touched)
                }"
                required
              >
                <option *ngFor="let item of countryOptions" [ngValue]="item">
                  {{ item }}
                </option>
              </select>

              <div
                *ngIf="
                  country.invalid &&
                  (country.dirty || country.touched) &&
                  country.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please select country
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col text-start fs-6 mt-3">
              <h6>Do you have any further credit instructions?</h6>
              <p class="my-1">
                <small
                  >This is commonly used for wires to brokerage or other
                  intermediaries.</small
                >
              </p>

              <app-radio-buttons
                label="Further Instructions"
                [selectedOption]="methods.Is_Further_Instruction"
                (optionChanged)="onOptionChanged($event)"
              ></app-radio-buttons>
            </div>
          </div>
          <ng-container *ngIf="methods.Is_Further_Instruction == 'Yes'">
            <div
              class="col-md-3 text-start fw-bold mt-3"
              style="font-size: medium"
            >
              For further credit to
            </div>
            <div class="row">
              <div class="col-md-6 mt-2 text-start">
                <app-custom-input-fields
                  [label]="'Account number for further credit to'"
                  [fieldId]="'furtherAccountNumber'"
                  [fieldName]="methods.further.Account_Number"
                  [errorMessage]="'Please enter further credit account number'"
                  [ngModelRef]="'furtherAccountNumber'"
                  (inputChanged)="
                    handleFurtherInputChanged('Account_Number', $event)
                  "
                ></app-custom-input-fields>
              </div>

              <div class="col-md-6 mt-2 text-start">
                <app-custom-input-fields
                  [label]="'Re-enter acount number Name'"
                  [fieldId]="'furtherReEnterAccountNumber'"
                  [fieldName]="methods.further.Re_Enter_AccountNumber"
                  [errorMessage]="'Please re-enter account number'"
                  [ngModelRef]="'furtherReEnterAccountNumber'"
                  (inputChanged)="
                    handleFurtherInputChanged('Re_Enter_AccountNumber', $event)
                  "
                ></app-custom-input-fields>
                <div
                  *ngIf="isFurtherAccountNumberMismatch()"
                  class="text-danger rounded px-4 error-message"
                >
                  Account numbers do not match
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mt-2 text-start">
                <app-custom-input-fields
                  [label]="'Name on further credit account'"
                  [fieldId]="'furtherRecipientName'"
                  [fieldName]="methods.further.Recipient"
                  [errorMessage]="'Please enter further credit recipient name'"
                  [ngModelRef]="'furtherRecipientName'"
                  (inputChanged)="
                    handleFurtherInputChanged('Recipient', $event)
                  "
                ></app-custom-input-fields>
              </div>

              <div class="col-md-6 mt-2 text-start">
                <app-custom-input-fields
                  [label]="'Address of account holder'"
                  [fieldId]="'furtherRecipientAddress'"
                  [fieldName]="methods.further.Recipient_Address"
                  [errorMessage]="'Please enter recipient address'"
                  [ngModelRef]="'furtherRecipientAddress'"
                  (inputChanged)="
                    handleFurtherInputChanged('Recipient_Address', $event)
                  "
                ></app-custom-input-fields>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 mt-2 text-start">
                <app-custom-input-fields
                  [label]="'City'"
                  [fieldId]="'furtherCity'"
                  [fieldName]="methods.further.City"
                  [errorMessage]="'Please enter city name'"
                  [ngModelRef]="'furtherCity'"
                  (inputChanged)="handleFurtherInputChanged('City', $event)"
                ></app-custom-input-fields>
              </div>

              <div class="col-md-4 mt-2 text-start">
                <app-custom-input-fields
                  [label]="'State'"
                  [fieldId]="'furtherState'"
                  [fieldName]="methods.further.State"
                  [errorMessage]="'Please enter state name'"
                  [ngModelRef]="'furtherState'"
                  (inputChanged)="handleFurtherInputChanged('State', $event)"
                ></app-custom-input-fields>
              </div>
              <div class="col-md-4 mt-2 text-start">
                <label class="labels">Country </label>

                <select
                  id="furtherCountry"
                  class="form-select"
                  [(ngModel)]="methods.further.country"
                  (ngModelChange)="handleCountryChange($event, true)"
                  #furtherCountry="ngModel"
                  [ngClass]="{
                    'border border-danger':
                      furtherCountry.invalid &&
                      (furtherCountry.dirty || furtherCountry.touched)
                  }"
                  required
                >
                  <option *ngFor="let item of countryOptions" [ngValue]="item">
                    {{ item }}
                  </option>
                </select>

                <div
                  *ngIf="
                    furtherCountry.invalid &&
                    (furtherCountry.dirty || furtherCountry.touched) &&
                    furtherCountry.errors
                  "
                  class="text-danger rounded px-4 error-message"
                >
                  Please select country
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="row" *ngIf="selectedType === 'Check'">
          <div class="row">
            <div class="col-md-6 mt-2 text-start">
              <app-custom-input-fields
                [label]="'Account Holder'"
                [fieldId]="'recipient'"
                [fieldName]="methods.Recipient"
                [errorMessage]="'Please enter recipient name'"
                [ngModelRef]="'Recipient'"
                (inputChanged)="handleInputChanged('Recipient', $event)"
              ></app-custom-input-fields>
            </div>
            <div class="col-md-6 mt-2 text-start">
              <app-custom-input-fields
                [label]="'Mail To'"
                [fieldId]="'mailto'"
                [fieldName]="methods.mailto"
                [errorMessage]="'Please enter mail to'"
                [ngModelRef]="'mailto'"
                (inputChanged)="handleInputChanged('mailto', $event)"
              ></app-custom-input-fields>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mt-2 text-start">
              <app-custom-input-fields
                [label]="'Street'"
                [fieldId]="'chedkStreet'"
                [fieldName]="methods.Street"
                [errorMessage]="'Please enter street address'"
                [ngModelRef]="'chedkStreet'"
                (inputChanged)="handleInputChanged('Street', $event)"
              ></app-custom-input-fields>
            </div>

            <div class="col-md-6 mt-2 text-start">
              <app-custom-input-fields
                [label]="'City'"
                [fieldId]="'CheckCity'"
                [fieldName]="methods.City"
                [errorMessage]="'Please enter city name'"
                [ngModelRef]="'CheckCity'"
                (inputChanged)="handleInputChanged('City', $event)"
              ></app-custom-input-fields>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mt-2 text-start">
              <app-custom-input-fields
                [label]="'State'"
                [fieldId]="'checkState'"
                [fieldName]="methods.State"
                [errorMessage]="'Please enter state name'"
                [ngModelRef]="'checkState'"
                (inputChanged)="handleInputChanged('State', $event)"
              ></app-custom-input-fields>
            </div>

            <div class="col-md-4 mt-2 text-start">
              <app-custom-input-fields
                [label]="'Zip'"
                [fieldId]="'zipCode'"
                [fieldName]="methods.Zip"
                [errorMessage]="'Please enter zip-code'"
                [ngModelRef]="'zipCode'"
                (inputChanged)="handleInputChanged('Zip', $event)"
              ></app-custom-input-fields>
            </div>
            <div class="col-md-4 mt-2 text-start">
              <label class="labels">Country </label>

              <select
                id="country"
                class="form-select"
                [(ngModel)]="methods.country"
                (ngModelChange)="handleCountryChange($event)"
                #country="ngModel"
                [ngClass]="{
                  'border border-danger':
                    country.invalid && (country.dirty || country.touched)
                }"
                required
              >
                <option *ngFor="let item of countryOptions" [ngValue]="item">
                  {{ item }}
                </option>
              </select>

              <div
                *ngIf="
                  country.invalid &&
                  (country.dirty || country.touched) &&
                  country.errors
                "
                class="text-danger rounded px-4 error-message"
              >
                Please enter country
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-4 d-flex gap-4">
          <div class="form-check p-0">
            <button
              class="btn btn-primary profile-button"
              type="button"
              [attr.data-bs-target]="'#exampleModal'"
              data-bs-toggle="modal"
              [disabled]="
                !(
                  addAccount &&
                  selectAccount &&
                  selectedType &&
                  handleFieldsAvailable()
                )
              "
              (click)="handleAlertMessage('Add')"
            >
              Add Transfer Method
            </button>
          </div>
          <div>
            <button
              type="submit"
              class="btn btn-outline-dark cancel-button mb-2 fw-bold"
              (click)="clickAddAccount()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <ng-container>
        <div
          class="modal fade text-wrap"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title"
                  id="staticBackdropLabel"
                  [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"
                ></h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="modal-body"
                [innerHTML]="sanitizeHTML(offerDisclaimer.value2)"
              ></div>
              <div class="modal-footer">
                <div class="mr-auto">
                  <button
                    type="button"
                    class="btn btn-warning text-white mr-2 fw-bold"
                    data-bs-dismiss="modal"
                    (click)="
                      deleteFTM
                        ? handleDeleteFTM()
                        : activeFTM
                        ? handleFTMState(deleteFTMethod, 'Activate')
                        : handleAccountSubmit()
                    "
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger ml-2 fw-bold"
                    data-bs-dismiss="modal"
                  >
                    NO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngFor="let k of objectKeys(accountsMethods)" class="mt-3">
        {{ k }}
        <!-- {{ k }} ({{ accountsMethods[k].length }}) -->
        <div
          style="
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: lightgray;
          "
        >
          <table class="table table-borderless" id="accordion">
            <ng-container
              *ngFor="let list of accountsMethods[k]; let i = index"
            >
              <thead *ngIf="i == 0">
                <tr>
                  <th
                    class="text-secondary"
                    *ngFor="let key of objectKeys(list.json_fields)"
                    [ngClass]="{
                      'w-20':
                        key == 'Eckard Account' || key == 'Account Holder',
                      'w-47': key == 'Mail To',
                      'text-center': key == 'Further Credit'
                    }"
                  >
                    {{ key }}
                  </th>
                  <th class="text-secondary text-center float-right">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-secondary"
                    *ngFor="
                      let key of objectKeys(list.json_fields);
                      let z = index
                    "
                    [ngClass]="{
                      'text-center': key == 'Further Credit'
                    }"
                    id="heading{{ list.id }}"
                  >
                    <ng-container *ngIf="key === 'Account Number'">
                      <ng-container
                        *ngIf="showData[i] && key === 'Account Number'"
                      >
                        {{ list.json_fields[key] }}
                      </ng-container>
                      <ng-container
                        *ngIf="!showData[i] && key === 'Account Number'"
                      >
                        ..{{ list.json_fields[key].toString().slice(-4) }}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="key !== 'Account Number'">
                      <ng-container
                        *ngIf="key == 'Further Credit'; else notFurtherCredit"
                      >
                        <ng-container
                          *ngIf="
                            list.json_fields[key].Is_Further_Instruction ===
                              'Yes';
                            else shoeFurtherCreditElseBlock
                          "
                        >
                          <span
                            data-toggle="collapse"
                            [attr.data-target]="'#collapse' + list.id"
                            aria-expanded="false"
                            [attr.aria-controls]="'collapse' + list.id"
                            class="data-details collapsed pe-auto"
                            title="View Further Credit Details"
                            (click)="handleFurtherCredit(list.json_fields[key])"
                          >
                            {{ list.json_fields[key].Is_Further_Instruction }}
                          </span>
                        </ng-container>
                        <ng-template #shoeFurtherCreditElseBlock>
                          <span class="pe-auto" title="Further Credit N/A">
                            {{ list.json_fields[key].Is_Further_Instruction }}
                          </span>
                        </ng-template>
                      </ng-container>
                      <ng-template #notFurtherCredit>
                        {{ list.json_fields[key] }}
                        <ng-container *ngIf="key === 'Eckard Account'">
                          <ng-container *ngIf="handleFTMAccepted(list)">
                            <img
                              src="assets/eckard-approve.svg"
                              class="mx-1 pb-1 eckard-ftm-approve"
                              alt="ftm-approve"
                            />
                          </ng-container>
                        </ng-container>
                      </ng-template>
                    </ng-container>
                    <ng-container *ngIf="key === 'Account Number'">
                      <i
                        class="fa fa-email px-4"
                        [ngClass]="showData[i] ? 'fa-eye-slash' : 'fa-eye'"
                        (click)="toggleData(i)"
                      ></i>
                    </ng-container>
                  </td>
                  <td class="text-center float-right mx-3">
                    <ng-container
                      *ngIf="
                        list?.method_state?.status == 'Deactivate';
                        else methodDeactiveElseBlock
                      "
                    >
                      <i
                        [attr.data-bs-target]="'#exampleModal'"
                        data-bs-toggle="modal"
                        (click)="handleAlertMessage('Activate', list)"
                        class="fa fa-ban text-warning pe-auto"
                        title="Activate FTM"
                        aria-hidden="true"
                      ></i>
                    </ng-container>
                    <ng-template #methodDeactiveElseBlock>
                      <i
                        [attr.data-bs-target]="'#exampleModal'"
                        data-bs-toggle="modal"
                        (click)="handleAlertMessage('Delete', list)"
                        class="fa fa-trash text-danger pe-auto"
                        title="Delete FTM"
                        aria-hidden="true"
                      ></i>
                    </ng-template>
                  </td>
                </tr>

                <tr
                  id="collapse{{ list.id }}"
                  class="collapse fade"
                  [attr.aria-labelledby]="'heading' + list.id"
                  data-parent="#accordion"
                >
                  <td [attr.colspan]="5">
                    <div
                      class="card-body border shadow-lg p-4 mb-5 bg-white rounded"
                    >
                      <app-further-credit-details
                        [furtherCreditDetails]="furtherCreditDetails"
                      ></app-further-credit-details>
                      <div class="mt-4">
                        <button
                          type="submit"
                          class="btn btn-outline-dark cancel-button mb-2 fw-bold"
                          data-toggle="collapse"
                          [attr.data-target]="'#collapse' + list.id"
                          aria-expanded="false"
                          [attr.aria-controls]="'collapse' + list.id"
                        >
                          Close Panel
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade text-wrap"
  id="exampleModal1"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  [ngClass]="{ show: isShow == true }"
  [style.display]="isShow == true ? 'block' : 'none'"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5
          class="modal-title"
          id="staticBackdropLabel"
          [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"
        ></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="isShow = false"
        ></button>
      </div>
      <div
        class="modal-body"
        [innerHTML]="sanitizeHTML(offerDisclaimer.value2)"
      ></div>
      <div class="modal-footer">
        <div class="mr-auto">
          <button
            type="button"
            class="btn btn-warning text-white mr-2 fw-bold"
            data-bs-dismiss="modal"
            (click)="handleFTMState(deleteFTMethod, 'Deactivate')"
          >
            YES
          </button>
          <button
            type="button"
            class="btn btn-danger ml-2 fw-bold"
            data-bs-dismiss="modal"
            (click)="isShow = false"
          >
            NO
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
<!-- Modal -->
<div
  class="modal fade text-wrap"
  id="exampleModalLong"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body mb-5">
        <app-reset-password
          [resetPassword]="resetPassword"
          [isChangePassword]="true"
        ></app-reset-password>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade text-wrap"
  id="exampleModalLong1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLong1Title"
  aria-hidden="true"
  *ngIf="handleIsEckardUser()"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body mb-5">
        <app-capital-amount
          [capitalAmount]="capitalAmount"
        ></app-capital-amount>
      </div>
    </div>
  </div>
</div>
