import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/components/services/login.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import {
  OfferConfirmMessages,
  ResetPassword,
  signUpForm,
} from 'src/components/model/login';
import { SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/components/services/common.service';
import { environment } from 'src/environments/environment';
import { CustomDatePipe } from 'src/common/custom-date.pipe';
import { EncryptionService } from 'src/components/services/encryption.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
})
export class SignUpComponent implements OnInit {
  isPassword = false;
  isResetPasswordFlag: boolean = false;
  signUPForm: signUpForm = {
    email: '',
    verifyCode: '',
  };
  resetPassword: ResetPassword = {
    id: null,
    regular_user: null,
    email: '',
    newPassword: '',
    confirmNewPassword: '',
    verificationCode: '',
    codeValidTime: '',
  };
  isRegisterUser: boolean = false;
  offerConfirmMessages: OfferConfirmMessages[] = [];
  offerDisclaimer: OfferConfirmMessages = {
    id: null,
    key: '',
    value1: '',
    value2: '',
  };
  isResetPassword: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private loginService: LoginService,
    private router: Router,
    private myOffersService: MyOffersService,
    private commonService: CommonService,
    private customDatePipe: CustomDatePipe,
    private encryptionService: EncryptionService
  ) {}

  sanitizeHTML(html: string): SafeHtml {
    return this.commonService.getSanitizeHTML(html);
  }

  ngOnInit(): void {
    let userData = JSON.parse(
      this.encryptionService.decryptData(localStorage.getItem('user'))
    );
    if (userData && userData != 'null') {
      this.router.navigate(['/market-place']);
      return;
    }
    this.checkCurrentPath();
    this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      this.spinner.show();
      await Promise.all([this.handleOfferDealMessages()]);
      this.spinner.hide();
    } catch (error) {
      console.error(error);
      this.spinner.hide();
    }
  }

  private checkCurrentPath(): void {
    const currentUrl = this.router.url;
    const currentPath = currentUrl.split('/')[1];

    if (currentPath === environment.RESET_ROUTE_PATH) {
      this.isResetPassword = true;
    }
  }

  handleSubmit() {
    this.spinner.show();
    this.loginService
      .handleVerifySignUp(this.signUPForm.email, this.isResetPassword)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response.contact.length > 0) {
            if (response.contact[0].mp_name != null && !this.isResetPassword) {
              this.toastr.warning(environment.EMAILE_ALREADY_EXIST_WARNING);
              this.router.navigate(['']);
            } else {
              this.toastr.info(environment.VERIFICATION_CODE_MESSAGE);
              this.resetPassword.id = response?.contact[0]?.id;
              this.resetPassword.regular_user = response.reg_user[0].id;
              this.resetPassword.email = response?.user[0]?.email;
              this.resetPassword.verificationCode =
                response?.signup_token?.verification_token;
              this.resetPassword.codeValidTime = response?.signup_token?.time;
              this.isRegisterUser = true;
            }
          } else {
            if(this.isResetPassword){
              this.toastr.info(environment.VERIFICATION_CODE_MESSAGE);
              this.isRegisterUser = true;
              return 
            }

            this.toastr.error(environment.INVALID_EMAIL_MESSAGE, '', {
              progressBar: false,
              tapToDismiss: false,
              disableTimeOut: true,
            });
          }
        },
        (error: any) => {
          this.spinner.hide();
          this.toastr.error(environment.ERROR_WARNING_MESSAGE, '', {
            progressBar: false,
            tapToDismiss: false,
            disableTimeOut: true,
          });
          console.log('error', error);
        },
        () => console.log('Sign-Up New User')
      );
  }

  togglePassword() {
    this.isPassword = !this.isPassword;
  }

  async handleOfferDealMessages(): Promise<void> {
    let response = await this.myOffersService
      .handleOfferDealMessages()
      .toPromise();
    this.offerConfirmMessages = response;
  }

  handleMessage(type: string) {
    let message = [];
    switch (type) {
      case 'sign-up':
        message = this.offerConfirmMessages?.filter(
          (item: any) => item.key == environment.SIGNUP_DISCLAIMER
        );
        this.offerDisclaimer = message[0];
        break;
      case 'verification':
        message = this.offerConfirmMessages?.filter(
          (item: any) => item.key == environment.VERIFICATION_DISCLAIMER
        );
        this.offerDisclaimer = message[0];
        break;
      case 'reset-password':
        message = this.offerConfirmMessages?.filter(
          (item: any) => item.key == environment.RESET_PASSWORD_DISCLAIMER
        );
        this.offerDisclaimer = message[0];
        break;
      default:
        return;
    }
  }

  handleVerificationCode() {
    const dateTime = new Date(
      this.customDatePipe.transform(
        this.resetPassword.codeValidTime,
        'yyyy-MM-ddTHH:mm'
      )
    );
    const currentDateTime = new Date();
    const diffInMinutes = Math.round(
      (currentDateTime.getTime() - dateTime.getTime()) / 60000
    );

    if (this.signUPForm.verifyCode === this.resetPassword.verificationCode) {
      if (diffInMinutes > 10) {
        this.toastr.error(environment.VERIFICATION_CODE_EXPIRE_MESSAGE, '', {
          progressBar: false,
          tapToDismiss: false,
          disableTimeOut: true,
        });
        this.isRegisterUser = false;
        this.resetVerificationCodeAndTime();
      } else {
        this.toastr.info(environment.SET_PASSWORD_MESSAGE);
        this.isResetPasswordFlag = true;
        this.isRegisterUser = false;
        this.resetVerificationCodeAndTime();
      }
    } else {
      this.toastr.error(environment.INVALID_VERIFICATION_CODE_MESSAGE, '', {
        progressBar: false,
        tapToDismiss: false,
        disableTimeOut: true,
      });
    }
  }

  private resetVerificationCodeAndTime(): void {
    this.resetPassword.verificationCode = '';
    this.resetPassword.codeValidTime = '';
  }
}
