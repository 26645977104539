import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-input-fields',
  templateUrl: './input-fields.component.html',
})
export class InputFieldsComponent {
  @Input() label!: string;
  @Input() fieldId!: string;
  @Input() fieldName!: any;
  @Input() errorMessage!: string;
  @Input() dynamicFieldName!: string;
  @Input() ngModelRef!: string;

  @Output() inputChanged: EventEmitter<string> = new EventEmitter<string>();

  onInputChanged(value: any) {
    this.fieldName = value;
    this.emitInputChanged();
  }

  private emitInputChanged() {
    this.inputChanged.emit(this.fieldName);
  }
}
