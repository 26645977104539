import { Component } from '@angular/core';

@Component({
  selector: 'app-eckard-expired-listings',
  templateUrl: './eckard-expired-listings.component.html'
})
export class EckardExpiredListingsComponent {
  
  constructor() { }

}
