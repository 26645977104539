import { Constraint, EmailMessages, MyListings } from './../../../../model/my-listings';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Status } from 'src/components/model/my-listings';
import {
  ModifiedColumns,
  MyOffers,
  Offer,
  OfferFilterList,
  OfferFilterOption,
} from 'src/components/model/my-offer';
import { AddNewListingService } from 'src/components/services/add-new-listing.service';
import { LoginService } from 'src/components/services/login.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { MyOffersService } from 'src/components/services/my-offers.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-my-all-active-listings',
  templateUrl: './all-active-listings.component.html'
})
export class AllActiveListingComponent implements OnInit {
  selectedItems = [];
  minAskFiltersSelected = [];
  auctionFiltersSelected = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    enableCheckAll: false,
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  offerStatus: string = 'Active';
  filterByProject: string = '';
  statusOptions!: Status[];
  isDirectSaleOffer: boolean = false;
  directSaleId!: string;

  page: number = 1;
  count: number = 0;
  minimumAskFilter = null;
  tableSize: number = 50;
  tableSizes: number[] = [3, 6, 9, 12];
  testMyOffer = [];
  myOffers!: MyOffers[];
  copymyOffers!: MyOffers[];
  constraintOptions!: Constraint[];
  listDetails!: MyListings;

  newOffer: Offer = {
    id: null,
    contact: null,
    status: null,
    constraints: [],
    comments: '',
    offerAmount: 0,
  };
  activeListing: boolean = true;

  offersFilterOptions: OfferFilterOption[] = [
    {
      value: 'Active',
      label: 'All Active Listings',
    },
  ];
  allActiveProjects: OfferFilterList[] = [];
  auctionTypeFilters: OfferFilterList[] = [];
  minAskFilters: OfferFilterList[] = [];
  offersColumns!: string[];
  isSendEmil: boolean = false
  modifiedColumns: ModifiedColumns = {
    'Active Listings': [
      'Project / Seller',
      'Ending',
      'Auction Type',
      'NMA',
      'Min. Ask',
      'Activation',
      'Buy Now',
      'Offers',
      'Highest',
      'My Offer',
    ],
    'Closed Listings': [
      'Project / Seller',
      'Ending',
      'Auction Type',
      'NMA',
      'Min. Ask',
      'Buy Now',
      'Offers',
      'Highest',
      'Sale Price',
      'Sale Date',
    ],
    'Canceled Listings': [
      'Project / Seller',
      'Ending',
      'Auction Type',
      'NMA',
      'Min. Ask',
      'Buy Now',
      'Offers',
      'Highest',
      'Cancel Date',
    ],
  };
  @ViewChild(MatSort) sort!: MatSort;
  emailMessages: EmailMessages[] = []

  constructor(
    private router: Router,
    private loginService: LoginService,
    private myListingsService: MyListingsService,
    private myOffersService: MyOffersService,
    private addNewListingService: AddNewListingService,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    if (
      this.loginService?.user?.status != 'active' ||
      this.loginService?.user?.role?.name == 'Eckard'
    ) {
      this.router.navigate(['/market-place']);
      return;
    }
    if (this.router.url.split('/')[1] == 'direct-sale') {
      this.isDirectSaleOffer = true;
      this.directSaleId = this.activeRoute.snapshot?.params['id'];
    }
    this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      this.spinner.show();
      await Promise.all([
        this.getAllListings(),
        this.handleConstraint(),
        this.handleGetStatus(),
      ]);
      this.spinner.hide();
    } catch (e) {
      this.spinner.hide();
      console.log('Error', e);
    }
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  async getAllListings(): Promise<void> {
    let offset = (this.page - 1) * this.tableSize;
    this.offersColumns = this.modifiedColumns['Active Listings'];
    this.spinner.show();
    const response: any = await this.myOffersService
      .getAllListings(
        this.loginService.user!.id,
        this.tableSize,
        offset,
        this.selectedItems,
        this.auctionFiltersSelected,
        this.isDirectSaleOffer
      )
      .toPromise();
    if (response) {
      this.myOffers = response.data;
      this.copymyOffers = response.data;
      this.page = response?.metadata?.page;
      this.count = response?.metadata?.total_results;
      this.tableSize = response?.metadata?.limit;
      if (this.isDirectSaleOffer) {
        this.offersColumns = this.modifiedColumns['Active Listings'];
        this.myOffers = this.copymyOffers?.filter(
          (item) =>
            item.status == 'Active' &&
            item.auctionType == environment.AUCTION_TYPE_DIRECT &&
            item.directSaleToken == this.directSaleId
        );
        if (this.myOffers.length == 0) {
          this.toastr.info(environment.OFFER_NOT_FOUND_MESSAGE);
          this.router.navigate(['/market-place']);
        } else {
          this.handleListDetails(
            this.myOffers[0]?.listingId,
            this.myOffers[0]?.offer_id
          );
        }
        return;
      }
      this.handleFilterList();
    }
    this.spinner.hide();
  }

  async getAllClosedListings(): Promise<void> {
    let offset = (this.page - 1) * this.tableSize;
    this.offersColumns = this.modifiedColumns['Closed Listings'];

    this.spinner.show();
    const response: any = await this.myOffersService
      .getAllClosedListings(
        this.loginService.user!.id,
        this.tableSize,
        offset,
        this.selectedItems,
        this.auctionFiltersSelected
      )
      .toPromise();

    if (response) {
      this.myOffers = response.data;
      this.copymyOffers = response.data;
      this.page = response?.metadata?.page;
      this.count = response?.metadata?.total_results;
      this.tableSize = response?.metadata?.limit;
      this.handleFilterList();
    }
    this.spinner.hide();
  }

  async getAllCancelledListings(): Promise<void> {
    let offset = (this.page - 1) * this.tableSize;
    this.offersColumns = this.modifiedColumns['Canceled Listings'];

    this.spinner.show();
    const response: any = await this.myOffersService
      .getAllCancelledListings(
        this.loginService.user!.id,
        this.tableSize,
        offset,
        this.selectedItems,
        this.auctionFiltersSelected
      )
      .toPromise();

    if (response) {
      this.myOffers = response.data;
      this.copymyOffers = response.data;
      this.page = response?.metadata?.page;
      this.count = response?.metadata?.total_results;
      this.tableSize = response?.metadata?.limit;
      this.handleFilterList();
    }
    this.spinner.hide();
  }

  handleChange() {
    this.toggleListing(this.offerStatus, false);
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.toggleListing(this.offerStatus, true);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  handleLength(array: any) {
    if (array && array.length > 0) {
      return true;
    }
    return false;
  }

  handleFilterList(): void {
    this.filterByProject = '';
    this.allActiveProjects = [];

    const projects: OfferFilterList[] = [];
    const minAsk: OfferFilterList[] = [];
    const auctionsTypes: OfferFilterList[] = [];

    this.myOffers?.forEach((offer: any) => {
      if (!projects.some((project) => project.item_id === offer.projectId)) {
        projects.push({ item_id: offer.projectId, item_text: offer.projectId });
      }

      if (!minAsk.some((project) => project.item_id === offer.minimumAsk)) {
        minAsk.push({ item_id: offer.minimumAsk, item_text: offer.minimumAsk });
      }

      if (
        !auctionsTypes.some((project) => project.item_id === offer.auctionType)
      ) {
        auctionsTypes.push({
          item_id: offer.auctionType,
          item_text: offer.auctionType,
        });
      }
    });

    this.allActiveProjects = projects;
    this.minAskFilters = minAsk;
    this.auctionTypeFilters = auctionsTypes;
  }

  async handleConstraint(): Promise<void> {
    const response: any = await this.addNewListingService
      .handleConstraint()
      .toPromise();
    if (response) {
      const buyOptions: any = [];
      response?.map((item: any) => {
        if (item.buyLabel) {
          buyOptions.push({ ...item, isChecked: false });
        }
      });
      this.constraintOptions = buyOptions;
    }
  }

  async handleListDetails(id: number, offerId: any, flag:boolean = false): Promise<void> {
    this.spinner.show();
    this.isSendEmil = flag
    flag && this.handleGetListEmailMessages(id)
    const response: MyListings | undefined = await this.myListingsService
      .getMyList(id)
      .toPromise();
    if (response) {
      this.listDetails = response;
      if (!offerId) {
        this.newOffer.offerAmount = parseFloat(response.minimumAsk);
        this.newOffer.id = null;
        this.newOffer.contact = null;
        this.newOffer.status = null;
        this.newOffer.constraints = [];
        this.newOffer.comments = '';
      }
    }

    if (offerId) {
      const response: any = await this.myOffersService.getofferDetails(offerId).toPromise()
        if (response)  {
          this.newOffer = response;
          this.constraintOptions = this.constraintOptions?.map((obj: any) => {
            return {
              id: obj.id,
              constraint: obj.constraint,
              buyLabel: obj.buyLabel,
              info: obj.info,
              violation_message: obj.violation_message,
              isChecked: this.newOffer.constraints?.some(
                (item: any) => item.id === obj.id
              ),
            };
          });
        }
    }

    this.spinner.hide();
  }

  handleUpdateOffers() {
    this.getAllListings();
  }

  async handleGetStatus(): Promise<void> {
    const response: any = await this.addNewListingService
      .handleGetStatus()
      .toPromise();

    if (response) {
      this.statusOptions = response;
    }
  }

  toggleListing(type: any, flag: boolean = false) {
    if (!flag) {
      this.page = 1;
      this.count = 0;
      this.tableSize = 50;
    }
    this.myOffers = [];
    this.copymyOffers = [];
    this.offerStatus = type;
    switch (this.offerStatus) {
      case 'Active':
        this.getAllListings();
        break;
      case 'Accepted':
        this.getAllClosedListings();
        break;
      case 'Cancelled':
        this.getAllCancelledListings();
        break;

      default:
        return;
    }
  }

  onSortChange(event: any): void {
    let sortProperty = '';
    switch (event.active) {
      case 'Project / Seller':
        sortProperty = 'projectId';
        break;
      case 'Ending':
        sortProperty = 'auctionEnd';
        break;
      case 'Auction Type':
        sortProperty = 'auctionType';
        break;
      case 'NMA':
        sortProperty = 'listedNMA';
        break;
      case 'Min. Ask':
        sortProperty = 'minimumAsk';
        break;
      case 'Buy Now':
        sortProperty = 'buyNowPrice';
        break;
      case 'Offers':
        sortProperty = 'noOfBids';
        break;
      case 'Highest':
        sortProperty = 'highestBid';
        break;
      case 'My Offer':
        sortProperty = 'offerAmount';
        break;
      case 'Sale Price':
        sortProperty = 'SalePrice';
        break;
      case 'Sale Date':
        sortProperty = 'SaleDate';
        break;
      case 'Cancel Date':
        sortProperty = 'Cancel_Date';
        break;
      case 'Activation':
        sortProperty = 'activation_time';
        break;
      default:
        return;
    }
    const sortOrder = event.direction === 'asc' ? 1 : -1;

    this.myOffers = this.myOffers.sort((a: any, b: any) => {
      const aValue = a[sortProperty];
      const bValue = b[sortProperty];

      if (aValue < bValue) {
        return -1 * sortOrder;
      } else if (aValue > bValue) {
        return 1 * sortOrder;
      } else {
        return 0;
      }
    });
  }

  async handleGetListEmailMessages(id:number): Promise<void> {
    
    const response: any = await this.myListingsService
      .handleGetListEmailMessages(id)
      .toPromise();
      const filteredMessages = response.filter((msg:any) => (
         msg.sender.mpName === this.loginService.user?.mpName || msg.receiver.mpName === this.loginService.user?.mpName
    ));
    this.emailMessages = filteredMessages;
  }

  handleUpdateEmailMessages(id:any, index: any){
    this.myOffers[index].messages = true
    this.handleGetListEmailMessages(id)
  }
  
}
