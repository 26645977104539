<form class="auth-container-form ">
    <h5 class="auth-container-title">
        <ng-container *ngIf="(isChangePassword || resetPassword.isVerifyPassword ); else changePasswordElseBlock">
            Change Password
        </ng-container>
        <ng-template #changePasswordElseBlock>
            Set Password
        </ng-template>
    </h5>
    <!-- Optional (hidden) username field -->
    <div class="visually-hidden d-none">
        <input type="text" id="username" name="username" autocomplete="">
    </div>
    <ng-container *ngIf="resetPassword.isVerifyPassword ; else updatePasswordBlock">

        <div class="mt-3 px-4 position-relative" >
            <input [type]="isCurrentPassword ? 'text' : 'password' " class="form-control py-2 px-5" name="currenPassword"
                id="currentPassword" placeholder="Current Password" [(ngModel)]="resetPassword.currentPassword" required>
            <i class="fa fa-lock px-4" aria-hidden="true"> </i>
    
            <i class="fa fa-email px-4 " [ngClass]="{'fa-eye': !isCurrentPassword , 'fa-eye-slash': isCurrentPassword}"
                (click)="isCurrentPassword = !isCurrentPassword"> </i>
    
        </div>
    </ng-container>
    <ng-template #updatePasswordBlock>
        <div class="mt-3 px-4 position-relative">
            <input [type]="isPassword ? 'text' : 'password' " class="form-control py-2 px-5" name="newPassword"
                id="newPassword" placeholder="New Password" [(ngModel)]="resetPassword.newPassword" required autocomplete="current-password">
            <i class="fa fa-lock px-4" aria-hidden="true"> </i>
    
            <i class="fa fa-email px-4 " [ngClass]="{'fa-eye': !isPassword , 'fa-eye-slash': isPassword}"
                (click)="isPassword = !isPassword"> </i>
    
        </div>
    
    
        <div class="mt-3 px-4 position-relative">
            <input [type]="isconfirmPassword ? 'text' : 'password' " class="form-control py-2 px-5"
                [ngClass]="{'border border-danger':(resetPassword.confirmNewPassword != resetPassword.newPassword)}"
                name="confirmNewPassword " id="confirmNewPassword" placeholder="Confirm Password"
                [(ngModel)]="resetPassword.confirmNewPassword" required autocomplete="current-password">
            <i class="fa fa-lock px-4" aria-hidden="true"> </i>
    
            <i class="fa fa-email px-4 " [ngClass]="{'fa-eye': !isconfirmPassword , 'fa-eye-slash': isconfirmPassword}"
                (click)="isconfirmPassword = !isconfirmPassword"> </i>
    
        </div>
    
        <div *ngIf="resetPassword.confirmNewPassword != resetPassword.newPassword "
            class="text-danger rounded px-4 error-message ">
            Confirm password does not match with new password.
        </div>

    </ng-template>
    <div class="px-4 mt-3">
        <ng-container *ngIf="isChangePassword || resetPassword.isVerifyPassword ; else elseChangePasswordMessage">
            <input type="submit" class="btn btn-warning btn-lg form-control form-button" [value]="resetPassword.isVerifyPassword ? 'Confirm Password':'Change Password'"
                (click)=" resetPassword.isVerifyPassword ? handleVerifyIdentity() : isShow = true" [disabled]="resetPassword.isVerifyPassword ? handleConfirmPWD() : handleCheckPWD()" />
        </ng-container>
        <ng-template #elseChangePasswordMessage>
            <input type="submit" class="btn btn-warning btn-lg form-control form-button" [value]="'Create Password'"
                (click)="handleResetPassword()" [disabled]="handleCheckPWD()" />
        </ng-template>
    </div>
</form>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.3)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
</ngx-spinner>


<div class="modal fade text-wrap" id="exampleModal1" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true" [ngClass]="{ 'show': isShow == true }"
    [style.display]="isShow == true ? 'block' : 'none'">
    <div class="modal-dialog modal-dialog-centered  modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                    Change Password Confirmation
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="isShow = false"></button>
            </div>
            <div class="modal-body">
                Are You sure you want to change your password?
            </div>
            <div class="modal-footer">
                <div class="mr-auto">
                    <button type="button" class="btn btn-warning text-white mr-2 fw-bold" data-dismiss="modal"
                        (click)="handleResetPassword()">
                        YES
                    </button>
                    <button type="button" class="btn btn-danger ml-2 fw-bold" data-bs-dismiss="modal"
                        (click)="isShow = false">
                        NO
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>