import { Component, Input, OnChanges, Output, EventEmitter, OnInit } from '@angular/core';
import { ListingCost, Account, MyListing, Tract, IncomListing, Project } from 'src/components/model/my-listings';
import { AddNewListingService } from '../../../../../services/add-new-listing.service';
import { MyListingsService } from 'src/components/services/my-listings.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/components/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OfferConfirmMessages } from 'src/components/model/login';
import { environment } from 'src/environments/environment';
import { CustomDatePipe } from 'src/common/custom-date.pipe';




@Component({
  selector: 'app-listing-details-tab',
  templateUrl: './listing-details-tab.component.html',
  styleUrls: ['./listing-details-tab.component.css'],

})
export class ListingDetailsTabComponent implements OnInit, OnChanges {

  @Input() accountsOptions!: Account[]
  @Input() createNewListing!: MyListing
  @Input() tracts!: Tract[]
  @Input() isListDraft!: boolean
  @Input() isListEdit!: boolean
  @Input() offerConfirmMessages!: OfferConfirmMessages[]
  @Output() isValidNMA = new EventEmitter()

  projectsOptions: Project[] = []
  incomeListing!: IncomListing
  listingCost!: ListingCost
  nmaErrorMessage = ''
  listingColumns: Array<string> = [
    'Location',
    'Project',
    'County,State',
    'Wells',
    'Royalty',
    'Income',
    'my NMA',
    'Listed NMA',
    '',
    'Listed Inc.'
  ]
  constructor(private addNewListingService: AddNewListingService,
    private myListingsService: MyListingsService, private toastr: ToastrService, private customDatePipe : CustomDatePipe,
    private loginService: LoginService, private spinner: NgxSpinnerService) {
  }
  ngOnInit(): void {
    if (this.createNewListing?.account) {
      this.handleUserProject(this.createNewListing.account.id)
    }
  }
  ngOnChanges(): void {
    if (this.accountsOptions?.length == 1) {
      this.handleUserProject(this.accountsOptions[0].id)
    }

  }

  handleChange(value: string) {
    switch (value) {
      case 'account':
        this.createNewListing.account = this.createNewListing.account;
        this.createNewListing.nma = null
        this.createNewListing.project = null
        this.createNewListing.buyNowPrice = null
        this.createNewListing.minimumAsk = null
        this.handleUserProject(this.createNewListing.account?.id)
        break;
      case 'project':
        this.createNewListing.buyNowPrice = null
        this.createNewListing.minimumAsk = null
        this.handleFindTotalNMA(true)
        break;
      case 'nma':
        this.createNewListing.nma = parseFloat(this.createNewListing.nma);
        if (this.incomeListing) {
          this.isValidNMA.emit(this.checkIsNmaValid())
        }
        break;
      case 'minimumAsk':
        this.createNewListing.minimumAsk = parseFloat(this.createNewListing.minimumAsk);
        break;
      case 'buyNowPrice':
        this.createNewListing.buyNowPrice = parseFloat(this.createNewListing.buyNowPrice);
        break;
      default:
        return
    }
  }

  handleUserProject(id: number) {

    this.myListingsService.handleGetProjects(id).subscribe(
      (response) => {
        if (response.length == 0) {
          this.toastr.info(environment.PROJECT_ASSOCIATION_WITH_ACCOUNT_WARNING)
          this.createNewListing.project = ''
          this.myListingsService.userAccountsAndProjects = []
          this.projectsOptions = []
        }
        else {
          const updatedResponse = response.map((item: any) => {
            return {
              ...item,
              project: {
                ...item.project,
                blockException: item.blockException
              }
            };
          });
          const uniqueProjects = updatedResponse?.reduce((acc: any, current: any) => {
            const x = acc.find((item: any) => item.project.id === current.project.id && item.project.projectId === current.project.projectId);
            if (!x) {
              return [...acc, current];
            } else {
              if (current.blockException) {
                x.project.blockException = true;
              }
              return acc;
            }
          }, []);

          this.myListingsService.userAccountsAndProjects = uniqueProjects
          const formattedProjects = uniqueProjects.map((item: any) => ({
            ...item.project,
            blockedUntil: item.project.blockedUntil ? this.customDatePipe.getFormattedDate(item.project.blockedUntil) : null
          }));

          this.projectsOptions = formattedProjects;
          if (this.projectsOptions.length === 1) {
            this.createNewListing.project = this.projectsOptions[0];
            this.handleFindTotalNMA()
          }
          else {
            this.handleFindTotalNMA()
          }
        }
      },
      (error: any) => {

        console.log("error", error)
      },
      () => console.log("Done getting List Type")
    )

  }

  handleFindTotalNMA(flag = false) {
    if (this.createNewListing.account && this.createNewListing.project) {
      this.handleGetListingCost()
      this.handleGetIncomeListing(flag)
    }
  }

  handleRemoveAndAddClass() {
    this.addNewListingService.handleRemoveAndAddClass()
  }

  handleTractLength() {
    if (this.tracts) {
      return this.tracts.length
    }
    return 0;
  }

  handleProjectLength() {
    if (this.projectsOptions) {
      return this.projectsOptions.length
    }
    return 0;
  }

  handleIsValidNma() {
    let flag = this.incomeListing ? this.checkIsNmaValid() : null;
    return !flag
  }

  handleGetListingCost() {
    this.myListingsService.handleGetListingCost(this.createNewListing.account.id, this.createNewListing.project.id).subscribe(
      (response) => {
        this.listingCost = response
      },
      (error: any) => {

        console.error("Error getting listing cost : ", error);
      },
      () => console.log("Done getting listing cost .")
    )
  }
  checkIsNmaValid() {
    const { availableNma, minimumNma } = this.incomeListing;
    const nma = parseFloat(this.createNewListing.nma);
    this.handleNmaErrorMessage()
    return (nma >= minimumNma) && ((availableNma - nma) >= minimumNma) || (nma === availableNma);
  }
  handleGetIncomeListing(flag = false) {
    this.myListingsService.handleGetIncomeListing(this.createNewListing.account.id, this.createNewListing.project.id).subscribe(
      (response) => {
        this.incomeListing = response
        this.createNewListing.nma = flag ? this.incomeListing.availableNma : (this.createNewListing.nma || this.incomeListing.availableNma);
        if (this.incomeListing) {
          this.isValidNMA.emit(this.checkIsNmaValid())
        }
      },
      (error: any) => {

        console.error("Error getting listing cost : ", error);
      },
      () => console.log("Done getting listing cost .")
    )
  }

  auctionTypeComparator(a: any, b: any) {
    return (a && b) ? a.id === b.id : false;
  }

  handleNmaErrorMessage() {
    const { availableNma, minimumNma } = this.incomeListing;
    const nma = parseFloat(this.createNewListing.nma);
    this.nmaErrorMessage = environment.NMA_BELOW_MINIMUM_ERROR_MESSAGE

    if (minimumNma > nma) {
      this.nmaErrorMessage = environment.NMA_BELOW_MINIMUM_ERROR_MESSAGE

    }
    else if (nma > availableNma) {
      this.nmaErrorMessage = environment.NMA_GREATER_THAN_AVAILABLE_ERROR_MESSAGE

    }
    else if ((minimumNma > (availableNma - nma)) && (nma != availableNma)) {
      this.nmaErrorMessage = environment.REMANING_NMA_BELOW_MINIMUM_ERROR_MESSAGE
    }

  }
}
