import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { EncryptionService } from 'src/components/services/encryption.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private loginService: LoginService, private router: Router, private encryptionService: EncryptionService) {

  }
  canActivate() {
    let isAccessToken = JSON.parse(this.encryptionService.decryptData(localStorage.getItem('accessToken')));
    let isRefreshToken = JSON.parse(this.encryptionService.decryptData(localStorage.getItem('refreshToken')));
    if (!isAccessToken || !isRefreshToken) {
      localStorage.clear();
      this.router.navigate(['']);
      return false
    }
    if (this.loginService?.isAuthanticated) {
      return true;
    }

    this.router.navigate(['']);
    return false;

  }

}
