<div class="row mt-4">
  <div class="table-responsive text-nowrap">
    <table
      class="table table-borderless"
      matSort
      (matSortChange)="onSortChange($event)"
    >
      <thead>
        <tr>
          <ng-container *ngFor="let item of offersTableHead; index as i">
            <th
              class="text-secondary"
              *ngIf="shouldShowColumn(item)"
              mat-sort-header="{{ item }}"
            >
              {{ item }}
            </th>
            <th class="text-secondary" *ngIf="shouldShowActionColumn(item)">
              {{ item }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody id="accordion">
        <ng-container
          *ngFor="
            let offer of offers
              | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
            let i = index
          "
        >
          <tr>
            <td class="text-secondary" id="heading{{ i }}">
              <ng-container *ngIf="!isEckardStaff; else isEckardStaffElseBlock">
                <span
                  data-toggle="collapse"
                  [attr.data-target]="'#collapse' + i"
                  aria-expanded="false"
                  [attr.aria-controls]="'collapse' + i"
                  class="collapsed pe-auto mr-2"
                  title="send email notification"
                  (click)="
                    handleGetListEmailMessages(
                      ListingDetails.id,
                      offer?.contact?.mpName
                    )
                  "
                >
                  <span class="data-details ">
                    {{ offer?.contact?.mpName }}
                  </span>
                  <img
                    [attr.src]="handleIsMessage(offer?.contact?.id, offer?.email_history, ListingDetails.id) ? 'assets/message-circle-lines.svg':'assets/message-circle.svg'"
                    width="24"
                    height="24"
                    class="m-1"
                    alt="message-icon"
                  />
                </span>
              </ng-container>
              <ng-template #isEckardStaffElseBlock>
                <span>
                  {{ offer?.contact?.mpName }}
                </span>
              </ng-template>
            </td>
            <td class="text-secondary">
              {{ offer?.offerAmount | currency : "" : "" : ".2-2" }}
            </td>
            <td>
              <ng-container
                *ngIf="
                  (shouldShowAcceptButton(offer) &&
                    !ListingDetails.is_exceeded) ||
                    isEckardStaff;
                  else expiredOfferExceedBlock
                "
              >
                <span
                  for="accept"
                  data-bs-target="#staticBackdrop"
                  data-bs-toggle="modal"
                  (click)="handleSellMessage(offer)"
                  class="form-label d-inline-block border-bottom border-primary text-primary pe-auto"
                >
                  accept the offer
                </span>
              </ng-container>
              <ng-template #expiredOfferExceedBlock>
                <span class="text-secondary"> 72 hours exceeded </span>
              </ng-template>
              <ng-container *ngIf="shouldShowAcceptedStatus(offer)">
                <span class="text-secondary">Accepted</span>
              </ng-container>
              <ng-container *ngIf="shouldShowNoActionStatus(offer)">
                <span class="text-secondary">No Action</span>
              </ng-container>
            </td>
            <td class="text-secondary">
              <p
                *ngFor="let constraint of offer.constraints"
                [ngClass]="{ 'text-danger': constraint?.block }"
              >
                <span *ngIf="constraint.missing" class="text-danger"
                  >Missing</span
                >
                {{ constraint.buyLabel }}
              </p>
            </td>
            <td class="text-secondary">{{ offer.comments }}</td>
          </tr>
          <tr
            id="collapse{{ i }}"
            class="collapse fade"
            [attr.aria-labelledby]="'heading' + i"
            data-parent="#accordion"
            *ngIf="!isEckardStaff"
          >
            <td colspan="10">
              <ng-container *ngIf="ListingDetails && offer">
                <app-email-notification-message
                  [listDetails]="ListingDetails"
                  [index]="i"
                  status="Sell"
                  [listingId]="ListingDetails.id"
                  [offer]="offer"
                  [userName]="offer.contact!.mpName"
                  [emailMessages]="emailMessages"
                  (messageSent)="
                    handleUpdateEmailMessages($event, offer.contact!.mpName)
                  "
                ></app-email-notification-message>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    <pagination-controls
      previousLabel="Prev"
      nextLabel="Next"
      (pageChange)="onTableDataChange($event)"
    >
    </pagination-controls>
  </div>
  <div
    class="modal fade text-wrap"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            [innerHTML]="sanitizeHTML(offerDisclaimer.value1)"
          ></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="modal-body"
          [innerHTML]="sanitizeHTML(offerDisclaimer.value2)"
        ></div>
        <div class="modal-footer">
          <div class="mr-auto">
            <button
              type="button"
              class="btn btn-warning text-white mr-2 fw-bold"
              data-bs-dismiss="modal"
              (click)="handleSubmitOffer()"
            >
              ACCEPT
            </button>
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-danger ml-2 fw-bold"
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
