import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./../components/auth/login/login.module').then(m => m.loginModule) },
  { path: 'sign-up', loadChildren: () => import('./../components/auth/sign-up/sign-up.module').then(m => m.SignUpModule)},
  { path: 'reset-password',  loadChildren: () => import('./../components/auth/sign-up/sign-up.module').then(m => m.SignUpModule) },
  { path: 'market-place', loadChildren: () => import('./../components/market-place/market-place.module').then(m => m.MarketPlaceModule), },
  { path: 'my-offers', loadChildren: () => import('./../components/pages/buyer/my-bids/my-bids.module').then(m => m.MyBidsModule), },
  { path: 'all-listing', loadChildren: () => import('./../components/pages/buyer/my-bids/all-active-listing/all-active-listings.module').then(m => m.AllActiveListingsModule), },
  { path: 'direct-sale/:id', loadChildren: () => import('./../components/pages/buyer/my-bids/all-active-listing/all-active-listings.module').then(m => m.AllActiveListingsModule), },
  { path: 'buyer-pending-transactions', loadChildren: () => import('src/components/pages/buyer/buyer-transactions/buyer.transactions.module').then(m => m.BuyerTransactionsModule), },
  { path: 'buyer-closed-transactions', loadChildren: () => import('src/components/pages/buyer/buyer-transactions/buyer.transactions.module').then(m => m.BuyerTransactionsModule), },
  { path: 'buyer-notifications', loadChildren: () => import('./../components/pages/buyer/buyer-notifications/buyer-notifications.module').then(m => m.BuyerNotificationsModule), },
  { path: 'my-listing', loadChildren: () => import('./../components/pages/seller/my-listing/my-listing.module').then(m => m.MyListingModule), },
  { path: 'seller-pending-transactions', loadChildren: () => import('./../components/pages/seller/seller-transactions/seller-transactions.module').then(m => m.SellerTransactionsModule), },
  { path: 'seller-closed-transactions', loadChildren: () => import('./../components/pages/seller/seller-transactions/seller-transactions.module').then(m => m.SellerTransactionsModule), },
  { path: 'seller-notifications', loadChildren: () => import('./../components/pages/seller/seller-notifications/seller-notifications.module').then(m => m.SellerNotificationsModule), },
  { path: 'sec-disclosure', loadChildren: () => import('./../components/pages/informations/sec-disclosure/sec-disclosure.module').then(m => m.SECDisclosureModule), },
  { path: 'user-agreement', loadChildren: () => import('./../components/pages/informations/user-agreement/user-agreement.module').then(m => m.UserAgreementModule), },
  { path: 'profile', loadChildren: () => import('src/components/pages/profile/profile.module').then(m => m.ProfileModule), },
  { path: 'eckard-pending-transactions', loadChildren: () => import('./../components/pages/eckard-staff-place/eckard-transactions/eckard-transactions.module').then(m => m.EckardTransactionsModule), },
  { path: 'eckard-completed-transactions', loadChildren: () => import('./../components/pages/eckard-staff-place/eckard-transactions/eckard-transactions.module').then(m => m.EckardTransactionsModule), },
  { path: 'eckard-pending-asset', loadChildren: () => import('./../components/pages/eckard-staff-place/eckard-transactions/eckard-transactions.module').then(m => m.EckardTransactionsModule), },
  { path: 'eckard-transactions-methods', loadChildren: () => import('./../components/pages/eckard-staff-place/eckard-transactions-methods/eckard-transactions-methods.module').then(m => m.EckardTransactionsMethodsModule), },
  { path: 'eckard-pending-listings', loadChildren: () => import('./../components/pages/eckard-staff-place/eckard-expired-listings/eckard-expired-listings.module').then(m => m.EckardExpiredListingsModule), },
  { path: '**', redirectTo: 'market-place', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
