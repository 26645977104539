<div>
  <label class="label">{{ label }}</label>
  <div class="form-check mx-4" *ngFor="let item of furtherInstructionsOptions">
    <input
      class="form-check-input"
      (change)="onOptionChanged(item)"
      type="radio"
      name="furtherInstructions"
      [id]="item"
      [checked]="selectedOption == item"
    />
    <label class="form-check-label" [for]="item">
      {{ item }}
    </label>
  </div>
</div>
