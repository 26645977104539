import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EckardTransactionsMethodsComponent } from './eckard-transactions-methods.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EckardTransactionsMethodsDetailsComponent } from './eckard-transactions-methods-details/eckard-transactions-methods-details.component';
import { BreadcrumbModule } from 'src/components/breadcrumb/breadcrumb-container.module';
import { MaterialModule } from 'src/common/material.module';
import { RouterModule } from '@angular/router';
import { ChildRoutes } from 'src/common/child-routes';
import { FurtherCreditDetailsModule } from '../../further-credit-details/further-credit-details.module';



@NgModule({
  declarations: [EckardTransactionsMethodsComponent, EckardTransactionsMethodsDetailsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    BreadcrumbModule,
    MaterialModule,
    FurtherCreditDetailsModule

  ],
  providers: [],
  exports: [EckardTransactionsMethodsComponent]
})
export class EckardTransactionsMethodsModule { }
