import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SECDisclosureComponent } from './sec-disclosure.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChildRoutes } from 'src/common/child-routes';


@NgModule({
    declarations: [
        SECDisclosureComponent
    ],
    imports: [
        RouterModule.forChild(ChildRoutes),
        FormsModule,
        CommonModule,

    ],
    providers: [],
    exports: [SECDisclosureComponent]
})
export class SECDisclosureModule { }
