import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { LoginForm, User, UserDetails } from 'src/components/model/login';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { ActivityService } from './activity.service';
import { EncryptionService } from 'src/components/services/encryption.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  isAuthanticated: boolean = false;
  user: UserDetails | null = null;
  refreshToken!: string;
  constructor(
    private router: Router,
    private apiService: ApiService,
    private activityService: ActivityService,
    private encryptionService: EncryptionService
  ) {
    this.isAuthanticated =
      localStorage.getItem('isAuthanticated') == 'true' ? true : false;
    let userData = this.encryptionService.decryptData(
      localStorage.getItem('user')
    );
    let isRefreshToken = this.encryptionService.decryptData(
      localStorage.getItem('refreshToken')
    );
    if (userData != null) {
      this.user = JSON.parse(userData);
    }

    if (isRefreshToken != null) {
      this.refreshToken = JSON.parse(isRefreshToken);
    }
  }

  addTransactionMethod(body: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/fund_transfer_method/`,
      'POST',
      body
    );
    return res;
  }

  updateTransactionMethod(body: any) {
    let {id, ...request} = body
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/fund_transfer_method/${id}/`,
      'PATCH',
      request
    );
    return res;
  }

  associateTransferMethod(body: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/transaction_fund/`,
      'POST',
      body
    );
    return res;
  }

  getAccountMethods(id: any) {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/contact/${id}/ftm`,
      'GET'
    );
    return res;
  }

  public login(user: LoginForm): Observable<User> {
    const method = 'POST';
    const body = user;
    const response = this.apiService
      .request<User>(`${environment.API_BASE_URL}/token/`, method, body)
      .pipe(
        tap((response: any) => response.body), // Extract the response body
        tap((users: any) => {
          if (users.data.valid) {
            this.user = users?.data;
            this.refreshToken = users?.refresh;
            localStorage.setItem(
              'refreshToken',
              this.encryptionService.encryptData(JSON.stringify(users.refresh))
            );
            localStorage.setItem(
              'accessToken',
              this.encryptionService.encryptData(JSON.stringify(users.access))
            );
            localStorage.setItem('isAuthanticated', 'true');
            localStorage.setItem(
              'user',
              this.encryptionService.encryptData(JSON.stringify(this.user))
            );
            this.isAuthanticated = true;
            this.activityService.startInactivityTimer();
            this.router.navigate(['/market-place']);
          }
        }) // Apply calculations on the data
      );
    return response;
  }

  signUp(user: any): Observable<any> {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/contact/`,
      'POST',
      user
    );
    return res;
  }

  logout() {
    this.isAuthanticated = false;
    this.user = null;
    localStorage.clear();
    this.router.navigate(['']);
  }

  profileDetails(id: any): Observable<any> {
    const res = this.apiService.request<User>(
      `${environment.API_BASE_URL}/contact/${id}`,
      'GET'
    );
    return res;
  }

  updateProfileDetails(body: any): Observable<any> {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/contact/${body.id}/`,
      'PATCH',
      body
    );
    return res;
  }

  handleVerifySignUp(email: any, flag: boolean) {
    const params = new HttpParams()
      .set('email', email)
      .set('type', flag ? 'reset' : 'signUp');
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/users/findByEmail`,
      'GET',
      null,
      params
    );
    return res;
  }

  eckardDetails(id: any): Observable<any> {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/eckard_users/${id}`,
      'GET'
    );
    return res;
  }

  updateProfileEckardDetails(body: any): Observable<any> {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/eckard_users/${body.id}/`,
      'PATCH',
      body
    );
    return res;
  }

  updateSignUpDetails(body: any): Observable<any> {
    const res = this.apiService.request(
      `${environment.API_BASE_URL}/contact/signup/${body.id}/`,
      'PATCH',
      body
    );
    return res;
  }

  handleCreateAddress(res: any) {
    let address = '';
    if (res.json_fields['Recipient_Address']) {
      address += res.json_fields['Recipient_Address'] + ', ';
    }
    if (res.json_fields['mailto']) {
      address += res.json_fields['mailto'] + ', ';
    }
    if (res.json_fields['Street']) {
      address += res.json_fields['Street'] + ', ';
    }
    if (res.json_fields['City']) {
      address += res.json_fields['City'] + ', ';
    }
    if (res.json_fields['State']) {
      address += res.json_fields['State'] + (res.type == 'Check' ? ' ' : ', ');
    }
    if (res.json_fields['Zip']) {
      address += res.json_fields['Zip'] + ', ';
    }
    if (res.json_fields['country']) {
      address += res.json_fields['country'];
    }
    return address;
  }

  public VerifyPassword(user: LoginForm): Observable<User> {
    const method = 'POST';
    const body = user;
    const response = this.apiService
      .request<User>(`${environment.API_BASE_URL}/token/`, method, body)
      .pipe(
        tap((response: any) => response.body),
        tap((users: any) => {
          if (users.data.valid) {
            this.user = users?.data;
          }
        })
      );
    return response;
  }
}
