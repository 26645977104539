<div class="container card mt-4 pt-4 h-100">
  <app-breadcrumb-container
    [containerClass]="'breadcrumb-container  mb-3'"
    [breadcrumbItems]="[
      { label: 'Home', route: '/home' },
      { label: 'MarketPlace', route: '/market-place' },
      { label: 'My Offers', route: '/my-offers' }
    ]"
  ></app-breadcrumb-container>

  <div class="table-responsive text-nowrap">
    <table class="table mt-4" matSort (matSortChange)="onSortChange($event)">
      <thead>
        <tr>
          <th
            *ngFor="let item of offersColumns; index as i"
            class="text-secondary"
            [ngClass]="{ 'text-end': i > 0 }"
            mat-sort-header="{{ item }}"
          >
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody class="my-listing-table-body" id="accordion">
        <ng-container
          *ngFor="
            let offer of myOffers
              | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
            let i = index
          "
        >
          <tr class="text-end text-secondary">
            <td class="text-start" id="heading{{ offer.listingId }}">
              <span>{{ offer.projectId }}</span>
              <br />
              <span
                data-toggle="collapse"
                [attr.data-target]="'#collapse' + offer.listingId"
                aria-expanded="false"
                [attr.aria-controls]="'collapse' + offer.listingId"
                class=" collapsed pe-auto mr-2"
                title="send email notification"
                (click)="
                  handleListDetails(offer.listingId, offer.offer_id, true)
                "
              >
                <span class="data-details"> {{ offer.accountMpName }} </span>
                <img
                  [attr.src]="offer?.messages? 'assets/message-circle-lines.svg':'assets/message-circle.svg'"
                  width="24"
                  height="24"
                  class="m-1"
                  alt="message-icon"
                />
              </span>
            </td>
            <td class="text-end">
              <span class="mr-3">
                {{ offer.auctionEnd | date : "d MMM, y" }}
              </span>
            </td>
            <td class="text-end">
              <span class="mr-3">
                {{ offer.listedNMA }}
              </span>
            </td>
            <td class="text-end">
              <span class="mr-3">
                {{ offer.minimumAsk | currency : "" : "" : ".0-0" }}
              </span>
            </td>
            <td class="text-end">
              <span class="mr-3">
                {{
                  offer.auctionType != "Fix Price" &&
                  !offer.auctionType.endsWith("Buy Now or Make an Offer")
                    ? "N/A"
                    : (offer.buyNowPrice | currency : "" : "" : ".0-0")
                }}
              </span>
            </td>
            <td class="text-end">
              <span class="mr-3">
                {{
                  offer.auctionType.startsWith("Silent -") ? "" : offer.noOfBids
                }}
              </span>
            </td>
            <td class="text-end">
              <span class="mr-3">
                {{
                  offer.auctionType.startsWith("Silent -")
                    ? "silent"
                    : (offer.highestBid | currency : "" : "" : ".0-0")
                }}
              </span>
            </td>
            <td id="heading{{ i }}" class="text-end">
              <span
                data-toggle="collapse"
                [attr.data-target]="'#collapse' + i"
                aria-expanded="false"
                [attr.aria-controls]="'collapse' + i"
                class="data-details collapsed pe-auto mr-3"
                title="Offer details"
                [ngClass]="{
                  'highest-offer':
                    offer.isHighestOffer &&
                    !offer.auctionType.startsWith('Silent -') &&
                    offer.offerAmount &&
                    !offer.isSameOffer,
                  'not-highest-offer':
                    !offer.isHighestOffer &&
                    !offer.auctionType.startsWith('Silent -') &&
                    offer.offerAmount
                }"
                (click)="
                  handleListDetails(offer.listingId, offer.offer_id, false)
                "
              >
                {{
                  !offer.offerAmount
                    ? "make offer"
                    : (offer.offerAmount | currency : "" : "" : ".0-0")
                }}
              </span>
            </td>
          </tr>
          <tr
            id="collapse{{ i }}"
            class="collapse fade"
            [attr.aria-labelledby]="'heading' + i"
            data-parent="#accordion"
          >
            <td colspan="8">
              <div class="card-body border shadow-lg p-4 mb-5 bg-white rounded">
                <ng-container *ngIf="listDetails && !isSendEmil">
                  <app-listing-details
                    [listDetails]="listDetails"
                    [offer]="offer"
                  >
                  </app-listing-details>
                </ng-container>
                <hr />
                <span *ngIf="listDetails && !isSendEmil">
                  <app-my-offers
                    [constraintOptions]="constraintOptions"
                    [newOffer]="newOffer"
                    [offer]="offer"
                    [listDetails]="listDetails"
                    [statusOptions]="statusOptions"
                    (updateOffers)="handleUpdateOffers()"
                    [index]="i"
                  >
                  </app-my-offers>
                </span>
              </div>
            </td>
          </tr>

          <tr
            id="collapse{{ offer.listingId }}"
            class="collapse fade"
            [attr.aria-labelledby]="'heading' + offer.listingId"
            data-parent="#accordion"
          >
            <td colspan="10">
              <ng-container *ngIf="listDetails && isSendEmil">
                <app-email-notification-message
                  [listDetails]="listDetails"
                  [index]="offer.listingId"
                  status="Buyer"
                  [listingId]="offer.listingId"
                  [offer]="newOffer"
                  [userName]="offer.accountMpName"
                  [emailMessages]="emailMessages"
                  (messageSent)="handleUpdateEmailMessages($event, i)"
                ></app-email-notification-message>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    <pagination-controls
      previousLabel="Prev"
      nextLabel="Next"
      (pageChange)="onTableDataChange($event)"
      *ngIf="handleLength(myOffers)"
    >
    </pagination-controls>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.3)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
>
</ngx-spinner>
