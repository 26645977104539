import { EckardTransactionsMethodsComponent } from './../components/pages/eckard-staff-place/eckard-transactions-methods/eckard-transactions-methods.component';
import { EckardTransactionsComponent } from './../components/pages/eckard-staff-place/eckard-transactions/eckard-transactions.component';
import { UserAgreementComponent } from './../components/pages/informations/user-agreement/user-agreement.component';
import { SECDisclosureComponent } from './../components/pages/informations/sec-disclosure/sec-disclosure.component';
import { SellerNotificationsComponent } from './../components/pages/seller/seller-notifications/seller-notifications.component';
import { SellerTransactionsComponent } from './../components/pages/seller/seller-transactions/seller-transactions.component';
import { MyListingComponent } from './../components/pages/seller/my-listing/my-listing.component';
import { BuyerNotificationsComponent } from './../components/pages/buyer/buyer-notifications/buyer-notifications.component';
import { BuyerTransactionsComponent } from './../components/pages/buyer/buyer-transactions/buyer-transactions.component';
import { AllActiveListingComponent } from './../components/pages/buyer/my-bids/all-active-listing/all-active-listings.component';
import { MyBidsComponent } from './../components/pages/buyer/my-bids/my-bids.component';
import { MarketPlaceComponent } from './../components/market-place/market-place.component';
import { Routes } from '@angular/router';
import { LoginComponent } from 'src/components/auth/login/login.component';
import { SignUpComponent } from 'src/components/auth/sign-up/sign-up.component';
import { ProfileComponent } from 'src/components/pages/profile/profile.component';
import { AuthGuard } from 'src/components/auth/auth.guard';
import { EckardExpiredListingsComponent } from 'src/components/pages/eckard-staff-place/eckard-expired-listings/eckard-expired-listings.component';

export const ChildRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'reset-password', component: SignUpComponent },
  { path: 'market-place', component: MarketPlaceComponent,canActivate: [AuthGuard]  },
  { path: 'my-offers', component: MyBidsComponent,canActivate: [AuthGuard]  },
  { path: 'all-listing', component: AllActiveListingComponent,canActivate: [AuthGuard]  },
  { path: 'direct-sale/:id', component: AllActiveListingComponent,canActivate: [AuthGuard]  },
  { path: 'buyer-pending-transactions', component: BuyerTransactionsComponent,canActivate: [AuthGuard]  },
  { path: 'buyer-closed-transactions', component: BuyerTransactionsComponent,canActivate: [AuthGuard]  },
  { path: 'buyer-notifications', component: BuyerNotificationsComponent,canActivate: [AuthGuard]  },
  { path: 'my-listing', component: MyListingComponent,canActivate: [AuthGuard]  },
  {
    path: 'seller-pending-transactions',
    component: SellerTransactionsComponent,canActivate: [AuthGuard] 
  },
  {
    path: 'seller-closed-transactions',
    component: SellerTransactionsComponent,canActivate: [AuthGuard] 
  },
  { path: 'seller-notifications', component: SellerNotificationsComponent,canActivate: [AuthGuard]  },
  { path: 'sec-disclosure', component: SECDisclosureComponent, canActivate: [AuthGuard]  },
  { path: 'user-agreement', component: UserAgreementComponent, canActivate: [AuthGuard]  },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]  },
  {
    path: 'eckard-pending-transactions',
    component: EckardTransactionsComponent,canActivate: [AuthGuard] 
  },
  {
    path: 'eckard-completed-transactions',
    component: EckardTransactionsComponent,canActivate: [AuthGuard] 
  },
  {
    path: 'eckard-pending-asset',
    component: EckardTransactionsComponent,canActivate: [AuthGuard] 
  },
  {
    path: 'eckard-transactions-methods',
    component: EckardTransactionsMethodsComponent,canActivate: [AuthGuard] 
  },
  {
    path : 'eckard-pending-listings',
    component : EckardExpiredListingsComponent ,canActivate: [AuthGuard]
  }
];
