import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EckardTransactionsComponent } from './eckard-transactions.component';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { ListingDetailsModule } from '../../buyer/my-bids/listing-details/listing-details.module';
import { MyOffersDetailsModule } from '../../buyer/my-bids/my-offers/my-offers.module';
import { BreadcrumbModule } from 'src/components/breadcrumb/breadcrumb-container.module';
import { MaterialModule } from 'src/common/material.module';
import { RouterModule } from '@angular/router';
import { ChildRoutes } from 'src/common/child-routes';


@NgModule({
  declarations: [EckardTransactionsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(ChildRoutes),
    FormsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    ListingDetailsModule,
    MyOffersDetailsModule,
    MaterialModule,
    BreadcrumbModule

  ],
  providers: [],
  exports: [EckardTransactionsComponent]
})
export class EckardTransactionsModule { }
